import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel, GridRowParams } from '@mui/x-data-grid';
import { changePassword, fetchAdminPage, fetchConsignmentsByUser, fetchOrderById, fetchOrdersByUser, fetchUserPage, makeUserAdmin, removeUserAdmin, setUserBanned, setUserRewards } from '../../api/endpoints';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import UserDto from '../../interfaces/UserDto';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import Modal from 'react-modal';
import PurchaseOrder from '../../interfaces/PurchaseOrder';
import ConsignmentDto from '../../interfaces/ConsignmentDto';
import ExpandedOrder from '../../interfaces/ExpandedOrder';
import AdminOrderModal from './AdminOrderModal';
import AdminConsignmentModal from './AdminConsignmentModal';



const AdminUsers: React.FC = () => {
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [loadingConsignments, setLoadingConsignments] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false)
    const [filterQuery, setFilterQuery] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newRewards, setNewRewards] = useState('');
    const [adminFilter, setAdminFilter] = useState(false);
    const [lastSearched, setLastSearched] = useState('');
    const [selectedRow, setSelectedRow] = useState<UserDto | null>(null);
    const [selectedRowOrders, setSelectedRowOrders] = useState<PurchaseOrder[]>([]);
    const [selectedRowConsignments, setSelectedRowConsignments] = useState<ConsignmentDto[]>([]);

    const [selectedOrder, setSelectedOrder] = useState<ExpandedOrder | null>(null);
    const [selectedConsignment, setSelectedConsignment] = useState<ConsignmentDto | null>(null);

    const [rows, setRows] = useState<UserDto[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: "updatedAt",
            sort: "desc"
        }
    ]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0,
        pageSize: 10,
    });

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50, disableColumnMenu: true },
        { field: 'firstName', headerName: 'First Name', width: 120, disableColumnMenu: true },
        { field: 'lastName', headerName: 'Last Name', width: 120, disableColumnMenu: true },
        { field: 'email', headerName: 'Email', width: 200, disableColumnMenu: true },
        { field: 'createdAt', headerName: 'Created At', width: 200, disableColumnMenu: true },
        { field: 'updatedAt', headerName: 'Updated At', width: 200, disableColumnMenu: true },
        { field: 'isAdmin', headerName: 'Admin', width: 100, disableColumnMenu: true, sortable: false }
    ];

    const userColumns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50, disableColumnMenu: true },
        { field: 'userFirstName', headerName: 'First Name', width: 120, disableColumnMenu: true },
        { field: 'userLastName', headerName: 'Last Name', width: 120, disableColumnMenu: true },
        // { field: 'eventName', headerName: 'Event Name', width: 200, disableColumnMenu: true  },
        { field: 'section', headerName: 'Section', width: 100, disableColumnMenu: true },
        { field: 'row', headerName: 'Row', width: 100, disableColumnMenu: true },
        { field: 'quantity', headerName: 'Quantity', width: 100, disableColumnMenu: true },
        { field: 'price', headerName: 'Price', width: 100, disableColumnMenu: true },
        { field: 'orderStatus', headerName: 'Order Status', width: 150, disableColumnMenu: true },
    ];

    const consignmentColumns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 50, disableColumnMenu: true },
        { field: 'userFirstName', headerName: 'First Name', width: 100, disableColumnMenu: true },
        { field: 'userLastName', headerName: 'Last Name', width: 100, disableColumnMenu: true },
        { field: 'eventName', headerName: 'Event', width: 280, disableColumnMenu: true },
        { field: 'section', headerName: 'Section', width: 100, disableColumnMenu: true },
        { field: 'row', headerName: 'Row', width: 100, disableColumnMenu: true },
        { field: 'quantity', headerName: 'Qty', width: 100, disableColumnMenu: true },
        { field: 'status', headerName: 'Status', width: 100, disableColumnMenu: true }
    ];

    const fetchData = async (search: string, paginationModel: GridPaginationModel, sortModel: GridSortModel) => {
        setLoadingUsers(true);
        const page = paginationModel.page;
        const pageSize = paginationModel.pageSize;
        const sortBy = sortModel[0]?.field || "updatedAt";
        const order = sortModel[0]?.sort || "desc";
        const usersData = adminFilter ? await fetchAdminPage(page, pageSize, search, sortBy, order) : await fetchUserPage(page, pageSize, search, sortBy, order);
        const { content, total, pageable } = usersData;

        setRows(content);
        setTotalRows(total);
        setLoadingUsers(false);
    };

    useEffect(() => {
        fetchData(lastSearched, paginationModel, sortModel);
    }, [paginationModel, sortModel]);

    useEffect(() => {
        setPaginationModel({
            page: 0,
            pageSize: 10,
        })
        setSortModel([
            {
                field: "updatedAt",
                sort: "desc"
            }
        ])

        fetchData(lastSearched, paginationModel, sortModel);

    }, [adminFilter]);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        setPaginationModel(model);
    };

    const handleSortModelChange = (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
    };

    const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterQuery(e.target.value);
    };

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
    };

    const handleNewRewardsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewRewards(e.target.value);
    };

    const handleFilterSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetchData(filterQuery, paginationModel, sortModel);
        setLastSearched(filterQuery);
    }

    const handleAdminChange = async (id: number, isAdminAlready: boolean) => {
        setLoadingAction(true);
        if (isAdminAlready) {
            const responseEither = await removeUserAdmin(id);

            if (isRight(responseEither)) {
                const orderResponse = responseEither.right;
                setSelectedRow(orderResponse);
            }
        } else {
            const responseEither = await makeUserAdmin(id);

            if (isRight(responseEither)) {
                const orderResponse = responseEither.right;
                setSelectedRow(orderResponse);
            }
        }
        fetchData(lastSearched, paginationModel, sortModel);
        setLoadingAction(false);
    }

    const handleBanChange = async (id: number, isBannedAlready: boolean) => {
        setLoadingAction(true);
        const responseEither = await setUserBanned({ userId: id, banned: !isBannedAlready })

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            setSelectedRow(orderResponse);
        }
        fetchData(lastSearched, paginationModel, sortModel);
        setLoadingAction(false);
    }

    const handleNewRewardsSubmit = async (id: number) => {
        setLoadingAction(true);
        const responseEither = await setUserRewards({ userId: id, newRewards: parseFloat(newRewards) });

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            setSelectedRow(orderResponse);
        }
        fetchData(lastSearched, paginationModel, sortModel);
        setLoadingAction(false);
    }

    const handleNewPasswordSubmit = async (id: number) => {
        if (newPassword !== '') {
            setLoadingAction(true);
            const response = await changePassword({ userId: id, newPassword: newPassword });
            setLoadingAction(false);
        }
    }

    const refetchOrders = async () => {
        if (selectedRow) {
            setLoadingOrders(true);
            const ordersResponse = await fetchOrdersByUser(selectedRow.id)
            setSelectedRowOrders(ordersResponse)
            setLoadingOrders(false);
        }
    }

    const refetchConsignments = async () => {
        if (selectedRow) {
            setLoadingConsignments(true);
            const consignmentsResponse = await fetchConsignmentsByUser(selectedRow.id)
            setSelectedRowConsignments(consignmentsResponse)
            setLoadingConsignments(false);
        }
    }

    const handleRowClick = async (params: GridRowParams) => {
        const user = params.row as UserDto
        setSelectedRow(user);

        setLoadingOrders(true);
        const ordersResponse = await fetchOrdersByUser(user.id)
        setSelectedRowOrders(ordersResponse)
        setLoadingOrders(false);

        setLoadingConsignments(true);
        const consignmentsResponse = await fetchConsignmentsByUser(user.id)
        setSelectedRowConsignments(consignmentsResponse)
        setLoadingConsignments(false);
    };

    const handleOrderClick = async (params: GridRowParams) => {
        const response: ExpandedOrder = await fetchOrderById(params.row.id)
        setSelectedOrder(response);
    };

    const handleConsignmentClick = async (params: GridRowParams) => {
        setSelectedConsignment(params.row as ConsignmentDto);
    };

    return (
        <div className='card card-top' style={{ minHeight: 500, width: '100%' }} >
            {/* <div> */}
            <div className='text-l'>Users</div>
            <div className='card-item'>
                <form onSubmit={handleFilterSubmit} className='card-item'>
                    <div className="row">
                        <input type="text" value={filterQuery} onChange={handleQueryChange}
                            placeholder="Type to filter by first name, last name, and email"
                            className='admin-search'
                        />
                        <button type="submit" className="admin-action-button">Filter</button>
                        <div className='gap' />
                        <div className='text-xs'>View Admins&nbsp;</div>
                        <input type="checkbox" checked={adminFilter} onChange={() => setAdminFilter(!adminFilter)} className='admin-checkbox' />
                    </div>
                </form>
            </div>

            {loadingUsers ?
                <div className="loading-spinner"></div>
                :
                <DataGrid
                    rows={rows}
                    columns={columns}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    rowCount={totalRows}
                    pageSizeOptions={[5, 10, 20]}
                    // checkboxSelection
                    onRowClick={handleRowClick}
                    className='admin-data-grid'

                />
            }
            <Modal isOpen={selectedOrder !== null} onRequestClose={() => setSelectedOrder(null)} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '1000px',
                    // height: '500px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '40px',
                    padding: '40px',
                    borderRadius: '15px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                <>
                    {selectedOrder && (
                        <AdminOrderModal order={selectedOrder} refetchData={refetchOrders} closeModal={() => setSelectedOrder(null)} />
                    )}
                </>
            </Modal>
            <Modal isOpen={selectedConsignment !== null} onRequestClose={() => setSelectedConsignment(null)} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '1000px',
                    // height: '500px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '40px',
                    padding: '40px',
                    borderRadius: '15px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                <>
                    {selectedConsignment && (
                        <AdminConsignmentModal consignment={selectedConsignment} refetchData={refetchConsignments} closeModal={() => setSelectedConsignment(null)} />
                    )}
                </>
            </Modal>
            <Modal isOpen={selectedRow !== null && selectedOrder == null && selectedConsignment == null} onRequestClose={() => setSelectedRow(null)} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '1000px',
                    // height: '500px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '40px',
                    padding: '40px',
                    borderRadius: '15px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                {selectedRow && (
                    <div>
                        <div className='card-item'>
                            <div className='text-m'>{"User Details (#" + selectedRow.id + "): "}</div>
                        </div>
                        <hr />
                        {loadingAction ?
                            <div className="loading-spinner"></div>
                            :
                            <>
                                <div className='card-item'>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>First Name:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.firstName}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Last Name:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.lastName}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Email:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.email}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Created At:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.createdAt}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Address:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.billAddress}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>City:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.billCity}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>State:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.billState}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Zip:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.billZip}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Country:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.billCountry}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Phone Number:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.billPhoneNumber}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Heard About 714:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.heardAbout}</div>
                                    </div>
                                </div>
                                <hr />
                                <div className='card-item'>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Rewards (${selectedRow.rewards.toFixed(2)}):&nbsp;</div>
                                        <input type="number" className='admin-input' value={newRewards} onChange={handleNewRewardsChange} style={{width: "200px"}}
                                            placeholder="Change in balance (+/-)"
                                        />
                                        <div className='gap-s' />
                                        <button className='admin-action-button' onClick={() => handleNewRewardsSubmit(selectedRow.id)}>Change Rewards</button>
                                    </div>
                                </div>
                                <hr />
                                <div className='card-item'>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Admin:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.isAdmin.toString()}</div>
                                        <div className='gap-s' />
                                        <button className='admin-action-button' onClick={() => handleAdminChange(selectedRow.id, selectedRow.isAdmin)}>{selectedRow.isAdmin ? "Remove Admin" : "Make Admin"}</button>
                                    </div>
                                    <br />
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Banned:&nbsp;</div>
                                        <div className='text-xs-light'>{selectedRow.banned.toString()}</div>
                                        <div className='gap-s' />
                                        <button className={`admin-${selectedRow.banned ? "action" : "delete"}-button`} onClick={() => handleBanChange(selectedRow.id, selectedRow.banned)}>{selectedRow.banned ? "Un-ban User" : "Ban User"}</button>
                                    </div>
                                </div>
                                <hr />
                                <div className='card-item'>
                                    <div className='row'>
                                        <div className='text-xs-bold gap-xl'>Reset Password:&nbsp;</div>
                                        <input type="text" className='admin-input' value={newPassword} onChange={handleNewPasswordChange}
                                            placeholder="New Password..."
                                        />
                                        <div className='gap-s' />
                                        <button className='admin-action-button' onClick={() => handleNewPasswordSubmit(selectedRow.id)}>Change Password</button>
                                    </div>
                                </div>
                                <hr />
                                <div className='card-item'>
                                    <div className='text-xs-bold'>Orders: </div>
                                </div>
                                <div style={{ height: 400, width: '100%' }}>
                                    {loadingOrders ?
                                        <div className="loading-spinner"></div>
                                        :
                                        <DataGrid
                                            rows={selectedRowOrders}
                                            columns={userColumns}
                                            getRowId={(row) => `${row.id}`}
                                            rowCount={selectedRowOrders.length}
                                            pageSizeOptions={[5, 10]}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 5 } },
                                            }}
                                            onRowClick={handleOrderClick}
                                            className='admin-data-grid'
                                        />
                                    }

                                </div>
                                <br />
                                <div className='card-item'>
                                    <div className='text-xs-bold'>Consigments: </div>
                                </div>
                                <div style={{ height: 400, width: '100%' }}>
                                    {loadingOrders ?
                                        <div className="loading-spinner"></div>
                                        :
                                        <DataGrid
                                            rows={selectedRowConsignments}
                                            columns={consignmentColumns}
                                            getRowId={(row) => `${row.id}`}
                                            rowCount={selectedRowConsignments.length}
                                            pageSizeOptions={[5, 10]}
                                            initialState={{
                                                pagination: { paginationModel: { pageSize: 5 } },
                                            }}
                                            onRowClick={handleConsignmentClick}
                                            className='admin-data-grid'
                                        />
                                    }
                                </div>
                            </>
                        }
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default AdminUsers;
