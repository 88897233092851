import React, { useState, useEffect, ReactNode, useCallback } from 'react';
import './EventMap.css';
import Event from '../../interfaces/Event';
import {MdZoomIn, MdZoomOut, MdZoomOutMap } from 'react-icons/md';
import Listing from '../../interfaces/Listing';
import { useScreenSizeContext } from '../../providers/ScreenSizeProvider';

const FillColor = {
    EMPTY: "#9e9e9e",
    FILL: "#3587b7", //"#4b8abb",
    SELECTED: "#19476b"
}

interface EventMapProps {
    event: Event | null;
    sections: Set<string>;
    selectedListing: Listing | null;
    setParentSelectedSections: React.Dispatch<React.SetStateAction<Set<string>>>;
    setMapSections:  React.Dispatch<React.SetStateAction<Set<string>>>;
}

const EventMap: React.FC<EventMapProps> = ({event, sections, selectedListing, setParentSelectedSections, setMapSections}) => {    
    const [mapExists, setMapExists] = useState(false);
    const [loadingMap, setLoadingMap] = useState(true);
    const [selectedSections, setSelectedSections] = useState<Set<string>>(new Set());
    const [sectionComponentMap, setSectionComponentMap] = useState(new Map<string, Set<any>>());
    const [imageComponents, setImageComponents] = useState<any[]>([]);
    const [textComponents, setTextComponents] = useState<any[]>([]);
    const [otherComponents, setOtherComponents] = useState<any[]>([]);
    const [initialDistance, setInitialDistance] = useState(0);

    const { isMobile } = useScreenSizeContext();




    const [scale, setScale] = useState(1);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);

    const disableScroll = () => {
        document.body.style.overflow = 'hidden';
    };

    const enableScroll = () => {
        document.body.style.overflow = '';
    };

    const handleResetZoom = () => {
        setStartX(0);
        setStartY(0);
        setTranslateX(0);
        setTranslateY(0);
        setScale(1);
    }

    const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
        const scaleFactor = e.deltaY > 0 ? 0.9 : 1.1;
        handleZoom(scaleFactor);
    };

    const handleZoom = (scaleFactor: number) => {
        const newScale = Math.min(Math.max(scale * scaleFactor, 1), 5);
        setScale(newScale);
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsDragging(true);
        setStartX(e.clientX);
        setStartY(e.clientY);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isDragging) {
            const dx = (e.clientX - startX);
            const dy = (e.clientY - startY);
            setTranslateX(translateX + dx);
            setTranslateY(translateY + dy);
            setStartX(e.clientX);
            setStartY(e.clientY);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };


    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        disableScroll();
        setIsDragging(true);
        if (e.touches.length === 1) {
            const touch = e.touches[0];
            setStartX(touch.clientX);
            setStartY(touch.clientY);
        } else if (e.touches.length === 2) {
            const distance = getDistance(e.touches);
            setInitialDistance(distance);
        }
    };
    
    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (isDragging && e.touches.length === 1) {
            const touch = e.touches[0];
            const dx = touch.clientX - startX;
            const dy = touch.clientY - startY;
            setTranslateX(translateX + dx);
            setTranslateY(translateY + dy);
            setStartX(touch.clientX);
            setStartY(touch.clientY);
        } else if (e.touches.length === 2) {
            const distance = getDistance(e.touches);
            const scaleFactor = distance / initialDistance;
            handleZoom(scaleFactor);
            setInitialDistance(distance);
        }
    };
    
    const handleTouchEnd = () => {
        enableScroll();
        setIsDragging(false);
    };
    
    // Utility function to calculate the distance between two touch points
    const getDistance = (touches: React.TouchList) => {
        const dx = touches[0].clientX - touches[1].clientX;
        const dy = touches[0].clientY - touches[1].clientY;
        return Math.sqrt(dx * dx + dy * dy);
    };

    // once we receive a non-null event, go fetch the Map if one exists
    useEffect(() => {
        if (event) {
            const venueId = event.venue.tevoVenueId.toString();
            const configId = event.configuration.tevoConfigurationId.toString();
            fetchMap(venueId, configId).then(unused => {
                fillAllSections();
            })
        }
        
    }, [event]);


    // once we confirm a Map exists AND receive non-empty listings, fill in the map
    useEffect(() => {
        setSelectedSections(new Set([...selectedSections].filter(section => sections.has(section))));

        if (mapExists && sections.size > 0) {
            unfillAllSections(); // think about this logic
            fillAllSections();
            highlightAllSections();


        }
    }, [mapExists, sections]);


    // whenever our local selected sections state changes, pass back up to the parent view
    useEffect(() => {
        setParentSelectedSections(selectedSections);
    }, [selectedSections]);


    useEffect(() => {
        unfillAllSections();
        fillAllSections();

        if (selectedListing !== null) {
            const selectedSectionName = selectedListing.tevoSectionName;
            
            highlightSection(selectedSectionName);
        } else {
            highlightAllSections();
        }

    }, [selectedListing]);


    const handleSectionClick = (tevoSectionName: string) => {
        // *** NOTE *** you have have to use this prevState => newState pattern to avoid stale values
        
        setSelectedSections(prevSelectedSections => {
            const updatedSelectedSections = new Set(prevSelectedSections);
    
            if (updatedSelectedSections.has(tevoSectionName)) {
                updatedSelectedSections.delete(tevoSectionName);
                unhighlightSection(tevoSectionName); 
            } else {
                updatedSelectedSections.add(tevoSectionName);
                highlightSection(tevoSectionName);
            }
    
            return updatedSelectedSections;
        });
    }

    const clearSelectedSections = () => {
        if (selectedSections.size > 0) {
            setSelectedSections(new Set());
            unfillAllSections();
            fillAllSections();
        }   
    }


    const unfillAllSections = () => {
        changeSectionFill(FillColor.EMPTY, new Set(sectionComponentMap.keys()), false);
    }


    const fillAllSections = () => {
        changeSectionFill(FillColor.FILL, sections);
    }


    const highlightAllSections = () => {
        changeSectionFill(FillColor.SELECTED, selectedSections)
    }


    const highlightSection = (tevoSectionName: string) => {
        changeSectionFill(FillColor.SELECTED, new Set([tevoSectionName]))
    }


    const unhighlightSection = (tevoSectionName: string) => {
        changeSectionFill(FillColor.FILL, new Set([tevoSectionName]))
    }


    const changeSectionFill = (fill: string, sectionsToFill: Set<string>, clickable: boolean = true) => {
        setSectionComponentMap(prevSectionComponentMap => {
            const updatedSectionComponentMap = new Map(prevSectionComponentMap);
    
            for (const tevoSectionName of sectionsToFill) {
                const componentSet = prevSectionComponentMap.get(tevoSectionName);
                if (componentSet) {
                    const updatedComponentSet = new Set<any>();
                    componentSet.forEach(component => {
                        const updatedComponent = { 
                            ...component, 
                            props: { 
                                ...component.props, 
                                fill, 
                                opacity: 1, 
                                ...(clickable ? { 
                                    cursor: "pointer", 
                                    onClick: () => handleSectionClick(tevoSectionName) 
                                } : {
                                    cursor: "auto",
                                    onClick: null
                                    
                                }) 
                            } 
                        };                        updatedComponentSet.add(updatedComponent);
                    });
                    updatedSectionComponentMap.set(tevoSectionName, updatedComponentSet);
                }
            }
    
            return updatedSectionComponentMap;
        });
    }

   
    const fetchMap = async (venueId: string, configId: string) => {
        const URL = `https://maps.ticketevolution.com/${venueId}/${configId}/map.svg`;
        const response = await fetch(URL);
        if (response.status === 404) {
            setMapExists(false);
            setLoadingMap(false);
            return;
        }
        let svgData = await response.text();

        // ensure there is only one definition of xmlns:xlink
        let foundXmlnsDefinition = false;
        svgData = svgData.replace(/xmlns:xlink="http:\/\/www\.w3\.org\/1999\/xlink"/g, match => {
            if (!foundXmlnsDefinition) {
                foundXmlnsDefinition = true;
                return match;
            } else { return ''; }
        });

        const parser = new DOMParser();
        const doc = parser.parseFromString(svgData, 'image/svg+xml');

        const sectionComponentMap = new Map<string, Set<any>>();
        const imageComponents: any[] = [];
        const textComponents: any[] = [];
        const otherComponents: any[]= [];

        const traverseSVG = (element: Element, groupTevoSectionName?: string, parentKey: string = '') => {
            for (let i = 0; i < element.children.length; i++) {
                const node = element.children[i];
                const elementType = node.tagName.toLowerCase();

                const maybeId = node.attributes.getNamedItem('id');
                const componentKey = maybeId ? maybeId.value : parentKey + '-' + i.toString();
                const maybeTevoSectionNameAttribute = node.attributes.getNamedItem('data-section-id');

                // recurse if group
                if (elementType === "g") {
                    traverseSVG(node, maybeTevoSectionNameAttribute ? maybeTevoSectionNameAttribute.value.toLowerCase() : undefined, componentKey);
                    continue;
                }
                
                const attributes: { [key: string]: string } = {};
                for (const {name, value} of node.attributes) {
                    let attributeName = name;
                    if (name === 'xlink:href') attributeName = 'xlinkHref';
                    else if (name === 'xmlns:xlink') attributeName = 'xmlnsXlink';
                    else if (name === 'class') attributeName = 'className';
                    else attributeName = name.replace(/-(\w)/g, (match, letter) => letter.toUpperCase());

                    if (attributeName != "dataSectionId") attributes[attributeName] = value;
                }

                const SvgComponent = elementType;
                if (maybeTevoSectionNameAttribute || groupTevoSectionName !== undefined) {
                    const tevoSectionName = maybeTevoSectionNameAttribute ? maybeTevoSectionNameAttribute.value.toLowerCase() : groupTevoSectionName as string;
                    const sectionComponent = React.createElement(SvgComponent, {
                        key: componentKey,
                        ...attributes,
                        fill: FillColor.EMPTY,
                        stroke: "white",
                        // strokeWidth: "1"
                    });
                    const componentSet = sectionComponentMap.get(tevoSectionName);
                    if (componentSet) {
                        componentSet.add(sectionComponent)
                    } else {
                        const sectionComponentSet = new Set();
                        sectionComponentSet.add(sectionComponent);
                        sectionComponentMap.set(tevoSectionName, sectionComponentSet);
                    }
                } else if (elementType === "image") {
                    imageComponents.push(<SvgComponent key={componentKey} {...attributes}/>);
                } else if (elementType === "text") {
                    textComponents.push(<text key={componentKey} {...attributes} pointerEvents="none" fontFamily="Avenir Next, sans-serif" strokeWidth="0.1">{node.textContent}</text>); // stroke="black" 
                } else {
                    otherComponents.push(<SvgComponent key={componentKey} {...attributes}/>);
                }          
            }   
        }

        traverseSVG(doc.children[0]);

        setSectionComponentMap(sectionComponentMap);
        setMapSections(new Set(sectionComponentMap.keys()));

        setImageComponents(imageComponents);
        setTextComponents(textComponents);
        setOtherComponents(otherComponents);
        setMapExists(true);
        setLoadingMap(false);
    }


    return (
        <div className='event-map-container'
            onWheel={handleWheel}
            onMouseDown={handleMouseDown}
            onMouseMove={(e) => requestAnimationFrame(() => handleMouseMove(e))}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={(e) => requestAnimationFrame(() => handleTouchMove(e))}
            onTouchEnd={handleTouchEnd}
            style={{ cursor: isDragging ? 'grabbing' : 'grab' , position: 'relative'}}
        >
            <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: isMobile ? '10px' : '30px', right: isMobile ? '10px' : '30px', zIndex: '999'}}>
                <MdZoomIn className='map-button' onClick={() => handleZoom(1.5)}/>
                <MdZoomOut className='map-button' onClick={() => handleZoom(1 / 1.5)}/>
                <MdZoomOutMap className='map-button' onClick={handleResetZoom}/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', position: 'absolute', bottom: isMobile ? '10px' : '30px', right: isMobile ? '10px' : '30px', zIndex: '999'}}>
                <button className='filter-button' onClick={clearSelectedSections}>Clear Sections</button>
            </div>

            {loadingMap ? <div/> :
                !mapExists ? <div>No Map Available</div> :
                    <div className='map-svg'
                    style={{
                        transform: `scale(${scale})`,
                        transition: 'transform 0.5s ease',
                        willChange: 'transform',
                        position: 'absolute',
                        top: `${translateY}px`,
                        left: `${translateX}px`,
                    }}
                    >
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            xmlnsXlink="http://www.w3.org/1999/xlink" 
                            width="90%" 
                            height="90%" 
                            viewBox="0 0 800 600" 
                            id="svgroot" 
                            version="1.1" 
                        >
                            {imageComponents}
                            {Array.from(sectionComponentMap.values()).flatMap(set => [...set])}
                            {otherComponents}
                            {textComponents}
                        </svg>
                    </div>
            }
        </div>
    )
}

export default EventMap;