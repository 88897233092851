import React, { useState, useEffect } from 'react';
import Listing from '../../interfaces/Listing';
import { useParams } from 'react-router-dom';
import { fetchEventById } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import Event from '../../interfaces/Event';
import { formatTimeZonedDateToFullDate, formatDateToWeekDayAndTime } from '../../utils/DateUtils/DateUtils';


interface OrderDetailsProps {
    event: Event;
    listing: Listing;
    selectedQuantity: number
}

const OrderDetailsCard: React.FC<OrderDetailsProps> = ({event, listing, selectedQuantity}) => {

    return (
        <div className='card '>
            <div className='text-l'>Order Details</div>
            <div className='card-item'>
                <div className='text-s'>{event.name}</div>
                <div className='text-xs-light'>{formatTimeZonedDateToFullDate(event.occursAtLocal)}</div>
            </div>
            <div className='card-item'>
                <div className='text-s'>{event.venue.name}</div>
                <div className='text-xs-light'>{event.venue.location}</div>
            </div>
            <div className='card-item'>
                <div className='text-s'>Ticket Details</div>
                <div className='row'>
                    <div className='text-xs  gap-l'>{'Section: '}&nbsp;</div>
                    <div className='text-xs-light'>{listing.section}</div>
                </div>
                <div className='row'>
                    <div className='text-xs  gap-l'>{'Row: '}&nbsp;</div>
                    <div className='text-xs-light'>{listing.row}</div>
                </div>
                <div className='row'>
                    <div className='text-xs gap-l'>{'Quantity: '}&nbsp;</div>
                    <div className='text-xs-light'>{selectedQuantity}</div>
                </div>
                <div className='row'>
                    <div className='text-xs  gap-l'>{'Price: '}&nbsp;</div>
                    <div className='text-xs-light'>${listing.price.toFixed(2)} ea</div>
                </div>
                <div className='row align-start'>
                    <div className='text-xs  gap-l'>{'Notes: '}&nbsp;</div>
                    <div className='text-xs-light'>{listing.publicNotes}</div>
                </div>
            </div>
        </div>  
    )
}

export default OrderDetailsCard;