import React, { createContext, useContext, useState, useEffect } from 'react';
import { BlobServiceClient, AnonymousCredential, ContainerClient, BlobClient } from '@azure/storage-blob';


class ReadBlobService {

    private containerClient: ContainerClient;
    private blobPathSet: Set<string>;

    private constructor(containerClient: ContainerClient) {
        this.containerClient = containerClient;
        this.blobPathSet = new Set<string>(); // Initialize as an empty set initially
    }
    

    // Factory method for async initialization
    static async create(containerName: string, containerSAS: string): Promise<ReadBlobService> {
        // TODO this will need anonymous auth for READ capabilities
        const blobUrl = process.env.REACT_APP_BLOB_URL;
        const blobServiceClient = new BlobServiceClient(blobUrl + "?" + containerSAS);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const instance = new ReadBlobService(containerClient);
        instance.blobPathSet = await instance.createBlobPathSet();
        return instance;
    }

    private async createBlobPathSet(): Promise<Set<string>> {
        let blobNames: Set<string> = new Set();
        for await (const blob of this.containerClient.listBlobsFlat()) {
            blobNames.add(blob.name);
        }
        console.log("HERE BLOBS: ", blobNames)
        return blobNames;
    }

    getImageUrlForCategoryPath(categoryPath: string) {
        console.log("PATHS ", this.blobPathSet);

        const pathSegments = categoryPath.split('/');
        for (let i = pathSegments.length; i > 0; i--) {
            const currentPath = pathSegments.slice(0, i).join('/') + "/img.jpg";
            if (this.blobPathSet.has(currentPath)) {
                const blobClient = this.containerClient.getBlobClient(currentPath); 
                return blobClient.url;
            }
        }

        return ''; // TODO this should default to something guaranteed
    }

    getUrlIfPathExists(path: string) {
        const imagePath = path
        if (this.blobPathSet.has(imagePath)) {
            const blobClient = this.containerClient.getBlobClient(imagePath); 
            return blobClient.url;
        }
        return null
    }

    getExactUrlForPath(path: string) {
        const imagePath = path

        const blobClient = this.containerClient.getBlobClient(imagePath); 
        return blobClient.url;
    }

    getAllPathsFromBase(basePath: string) {
        const filteredPaths = Array.from(this.blobPathSet).filter(str => str.startsWith(basePath))
        return filteredPaths;
    }
}


// Define a type for the context
interface ReadBlobContextType {
    imageReadBlobService: ReadBlobService | null;
    eticketReadBlobService: ReadBlobService | null;
}

// Create context
const ReadBlobContext = createContext<ReadBlobContextType>({
    imageReadBlobService: null,
    eticketReadBlobService: null
});

// Create a custom hook to use the context
export const useReadBlobContext = () => useContext(ReadBlobContext);

interface ReadBlobProviderProps {
    children: React.ReactNode;
}


// TODO NEED TO MAKE SURE blobPaths gets refreshed on a change


// Create a provider
export const ReadBlobProvider: React.FC<ReadBlobProviderProps> = ({ children }) => {
    const [imageReadBlobService, setImageReadBlobService] = useState<ReadBlobService | null>(null);
    const [eticketReadBlobService, setEticketReadBlobService] = useState<ReadBlobService | null>(null);
    
    const imagesContainer = process.env.REACT_APP_IMAGES_CONTAINER as string;
    const imagesKey = process.env.REACT_APP_BLOB_IMAGES_KEY as string;

    const eticketsContainer = process.env.REACT_APP_ETICKETS_CONTAINER as string;
    const eticketsKey = process.env.REACT_APP_BLOB_ETICKETS_KEY as string;

    useEffect(() => {
        const initialize = async () => {
            const imageService = await ReadBlobService.create(imagesContainer, imagesKey);
            setImageReadBlobService(imageService);

            const eticketService = await ReadBlobService.create(eticketsContainer, eticketsKey);
            setEticketReadBlobService(eticketService);
        }

        initialize();
    }, []);


    return (
        <ReadBlobContext.Provider value={{imageReadBlobService, eticketReadBlobService}}>
        {children}
        </ReadBlobContext.Provider>
    );
};

export default ReadBlobProvider;