import React, { useState, useEffect, useCallback, useRef, ReactElement } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './SearchBar.css';
import { RiSearchLine } from 'react-icons/ri';
import { fetchPerformersByQuery, fetchSearchResultByQuery } from '../../api/endpoints';
import Performer from '../../interfaces/Performer';
import SearchResult from '../../interfaces/SearchResult';
import Event from '../../interfaces/Event';
import Venue from '../../interfaces/Venue';
import { formatTimeZonedDateToFullDate } from '../../utils/DateUtils/DateUtils';
import { encodeObject, getEventUrlParams, getPerformerUrlParams, getVenueUrlParams } from '../../utils/GlobalUtils';
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import { useScreenSizeContext } from '../../providers/ScreenSizeProvider';

interface SearchMenuProps {
    results: SearchResult;
}

interface SearchBarPart {
    htmlPart: React.ReactElement;
    actuallyDisplay: boolean;
    score: number;
}

const SearchMenu: React.FC<SearchMenuProps> = ({ results }) => {
    const searchArrayWithScores: SearchBarPart[] = [
        {
            actuallyDisplay: results.featured.length > 0, 
            score: results.featuredScore,  
            htmlPart: <>
                        <div className='menu-title'>Featured</div>
                        {results.featured.map(feature => (
                            <Link
                                key={feature.tevoEventId}
                                to={`/event/${getEventUrlParams(feature)}`}
                                className="menu-item"
                            >
                                <div className='text-xs-bold'>{feature.name}</div>
                                <div className='text-xs-light'>{formatTimeZonedDateToFullDate(feature.occursAtLocal)}</div>
                            </Link>
                        ))}
                    </>
        },
        {
            actuallyDisplay: results.performers.length > 0, 
            score: results.performersScore,  
            htmlPart: <>
                        <div className='menu-title'>Performers</div>
                        {results.performers.map(performer => (
                            <Link
                                key={performer.tevoPerformerId}
                                to={`/performer/${getPerformerUrlParams(performer)}`}
                                className="menu-item"
                            >
                                <div className='text-xs-bold'>{performer.name}</div>
                                <div className='text-xs-light'>{performer.category.name}</div>
                            </Link>))}
                    </>
        },
        {
            actuallyDisplay: results.venues.length > 0, 
            score: results.venuesScore,  
            htmlPart: <>
                        <div className='menu-title'>Venues</div>
                        {results.venues.map(venue => (
                            <Link
                                key={venue.tevoVenueId}
                                to={`/venue/${getVenueUrlParams(venue)}`}
                                className="menu-item"
                            >
                                <div className='text-xs-bold'>{venue.name}</div>
                                <div className='text-xs-light'>{venue.location}</div>
                            </Link>
                        ))}
                    </>
        },
        // {
        //     actuallyDisplay: results.events.length > 0, 
        //     score: results.eventsScore,  
        //     htmlPart: <>
        //                 <div className='menu-title'>Events</div>
        //                 {results.events.map(event => (
        //                     <Link
        //                         key={event.tevoEventId}
        //                         to={`/event/${encodeObject(event)}`}
        //                         className="menu-item"
        //                     >
        //                         <div className='text-xs-bold'>{event.name}</div>
        //                         <div className='text-xs-light'>{formatTimeZonedDateToFullDate(event.occursAtLocal)}</div>
        //                     </Link>
        //                 ))}
        //             </>
        // }
    ]

    const sortedSearchArrayWithScores = searchArrayWithScores.sort((a, b) => b.score - a.score);

    return (
        <div className="search-menu">
            {   
                sortedSearchArrayWithScores.some(item => item.actuallyDisplay) ?

                sortedSearchArrayWithScores
                .filter(item => item.actuallyDisplay)
                .map(item => item.htmlPart)

                :

                <div className='menu-title'>No Search Results</div>
            }
        </div>
    )
};


const SearchBar: React.FC = () => {
    const [query, setQuery] = useState('');
    const lastSearchedRef = useRef('');
    const [isFocused, setIsFocused] = useState(false);
    const [results, setResults] = useState<SearchResult | null>(null);
    const [loading, setLoading] = useState(false);

    const searchRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const {isMobile} = useScreenSizeContext();


    useEffect(() => {
        const handleClickOutsideSearch = (e: MouseEvent) => {
            if (searchRef.current && e.target instanceof Node && !searchRef.current.contains(e.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideSearch);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSearch);
        };
    }, []);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        if (e.target.value.length == 1) {
            fetchResults(e.target.value)
        }
    };


    const handleFocus = () => {
        setIsFocused(true);
    };


    const fetchResults = async (query: string) => {
        if (query === '') {
            setResults(null);
            return;
        }
        setLoading(true);
        try {
            lastSearchedRef.current = query;

            const result = await fetchSearchResultByQuery(query);
            if (lastSearchedRef.current == result.query) {
                setResults(result)
            }

        } catch (error) {
            console.error('Error fetching performers:', error);
        }
        setLoading(false);
    }


    const debounceFetchResults = useCallback(
        (() => {
            let timeoutId: NodeJS.Timeout;
            return (searchQuery: string) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    fetchResults(searchQuery);
                }, 300);
            };
        })(),
        []
    );

    useEffect(() => {
        debounceFetchResults(query);
    }, [query, debounceFetchResults]);


    const handleEnter = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const q = query.trim().toLowerCase();
        if (!q || q == '') {
            return;
        }

        if (results) {
            for (let i = 0; i < results.performers.length; i++) {
                if (results.performers[i].name.toLowerCase() == q) {
                    navigate(`/performer/${getPerformerUrlParams(results.performers[i])}`)
                    return
                }
            }

            for (let i = 0; i < results.events.length; i++) {
                if (results.events[i].name.toLowerCase() == q) {
                    navigate(`/event/${getEventUrlParams(results.events[i])}`)
                    return
                }
            }

            for (let i = 0; i < results.venues.length; i++) {
                if (results.venues[i].name.toLowerCase() == q) {
                    navigate(`/venue/${getVenueUrlParams(results.venues[i])}`)
                    return
                }
            }
        }

        navigate(`/search/${encodeURIComponent(query.trim())}`);
    };

    return (
        <div ref={searchRef}>
            <form className={`search-form ${isFocused ? 'focused-form' : ''}`} onSubmit={handleEnter}>
                {!loading ?
                    <RiSearchLine className={`search-icon ${isFocused ? 'focused-icon' : ''}`} />
                    :
                    <div className={`search-loading-spinner ${isFocused ? 'focused-search-loading-spinner' : ''}`}></div>
                }
                <input
                    type="text"
                    value={query}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    placeholder={isMobile ? "Performers, teams, venues..." : "Search performers, teams, venues, and more..."}
                    className={`search-input ${isFocused ? 'focused-input' : ''}`}
                />
            </form>
            {isFocused && results !== null && <SearchMenu results={results} />}
        </div>
    )
}

export default SearchBar;