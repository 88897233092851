import CheckoutItem from "../interfaces/CheckoutItem";
import Event from "../interfaces/Event";
import ListingSource from "../interfaces/ListingSource";
import Performer from "../interfaces/Performer";
import Venue from "../interfaces/Venue";

export const encodeObject = (obj: any) => {
    return btoa(encodeURIComponent(JSON.stringify(obj)));
}

export const getEventUrlParams = (event: Event) => {
    return event.name.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-") + "/" + (event.listingSource == ListingSource.TEVO ? "0" : "1") + "/" + event.tevoEventId
}

export const getPerformerUrlParams = (performer: Performer) => {
    return performer.name.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-") + "/" + performer.tevoPerformerId
}

export const getVenueUrlParams  = (venue: Venue) => {
    return venue.name.toLowerCase().replaceAll(" ", "-").replaceAll("/", "-") + "/" + venue.tevoVenueId
}

export const getCheckoutItemUrlParams = (checkoutItem: CheckoutItem) => {
    const test =  (checkoutItem.listingSource == ListingSource.TEVO ? "0" : "1") + "/" + checkoutItem.eventId + "/" + checkoutItem.selectedQuantity + "/" +  checkoutItem.listingId 
    console.log(test)
    return test
}

export const canBeParsedToInt = (str: string) => {
    const num = parseInt(str, 10);
    return !isNaN(num) && num.toString() === str.trim();
};

export const decodeObject = (encoding: string) => {
    try {
        return JSON.parse(decodeURIComponent(atob(encoding)));
    } catch {
        return null;
    }
}