import React, { useState, useEffect } from 'react';
import { triggerPasswordResetEmail } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import ForgotPasswordResponseCard from './ForgotPasswordResponseCard';


const ForgotPasswordFormCard: React.FC = () => {

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [forgotPasswordResponse, setForgotPasswordResponse] = useState<any>(null);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        const forgotPasswordResponse = await triggerPasswordResetEmail(email);
        if (isRight(forgotPasswordResponse)) {
            setForgotPasswordResponse(true)
        } else {
            setForgotPasswordResponse(false)
            console.log(forgotPasswordResponse.left)
        }
        setIsLoading(false);
    }

    return (
        isLoading ?
            <div className="loading-spinner"></div>
            :
            forgotPasswordResponse == null ?
                <form className='card' onSubmit={handleSubmit}>
                    <div className='text-l'>Forgot Your Password?</div>
                    <div className='card-item'>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Email"
                            className='input'
                        />
                    </div>
                    <div className='card-item'>
                        <button className='action-button-full' type="submit">Submit</button>
                    </div>
                </form>
                :
                <ForgotPasswordResponseCard success={forgotPasswordResponse} />
    )
}

export default ForgotPasswordFormCard;