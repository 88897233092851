import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate } from "react-router-dom";
import PerformerHeader from '../components/Heros/PerformerHero';
import PerformerEvents from '../components/Events/PerformerEvents';
import Footer from '../components/Footer/Footer';
import { fetchEventsByPerformer, fetchEventsByVenue, fetchVenueById } from '../api/endpoints';
import Event from '../interfaces/Event';
import Navbar from '../components/Navbar/Navbar';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { canBeParsedToInt, decodeObject } from '../utils/GlobalUtils';
import Venue from '../interfaces/Venue';
import VenueHero from '../components/Heros/VenueHero';
import VenueEvents from '../components/Events/VenueEvents';


const VenuePage: React.FC = () => {
    const { venueName, venueId = '' } = useParams<{ venueName: string, venueId: string }>();
    const [venue , setVenue] = useState<Venue | null>(null);
    const [loadingVenue, setLoadingVenue] = useState<boolean>(true);

    const [events, setEvents] = useState<Event[]>([]);
    const [loadingEvents, setLoadingEvents] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchVenue = async () => {
            setLoadingVenue(true);
            const venueDataEither = await fetchVenueById(venueId);
            if (isRight(venueDataEither)) {
                setVenue(venueDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingVenue(false);
        }

        const fetchEvents = async () => { 
            setLoadingEvents(true);
            const eventsDataEither = await fetchEventsByVenue(venueId);
            if (isRight(eventsDataEither)) {
                setEvents(eventsDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingEvents(false);
        };

        if (canBeParsedToInt(venueId)) {
            fetchVenue();
            fetchEvents();
            
        } else {
            navigate(`/not-found`);
        }
    }, []); 


    return (
        <div>
            <Navbar showSearch={true}/>
            <VenueHero venue={venue} loadingVenue={loadingVenue}/>
            <VenueEvents events={events} loadingEvents={loadingEvents} />
            <Footer/>
        </div>
    )
}

export default VenuePage;