import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles


const AdminEmailTemplates: React.FC = () => {

    return (
        <div className='card card-top' >
            <div className='text-l'>Email Templates</div>
            <div className='card-item'>
                <ReactQuill/>
            </div>     
        </div>
    )
}

export default AdminEmailTemplates;