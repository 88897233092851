import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { triggerUserVerification } from "../../api/endpoints"


const VerificationCard: React.FC = () => {
    const { verificationToken } = useParams<{ verificationToken: string }>();

    const [loading, setLoading] = useState(true);
    const [isVerified, setIsVerified] = useState(false);


    useEffect(() => {
        const verifyUser = async () => {
            if (verificationToken !== undefined) {
                const verificationResponse = await triggerUserVerification(verificationToken)
                if (isRight(verificationResponse)) {
                    console.log("VERIFICATION RESET WORKED: ", verificationResponse.right)
                    setIsVerified(true);
                } else {
                    console.log("VERIFICATION FAILED: ", verificationResponse.left)
                    setIsVerified(false);
                }
            }
        }
        verifyUser();
        setLoading(false)
    }, [verificationToken]);
    

    return (
        <div className='card' >
            <div className='text-l'>Welcome to 714 Tickets!</div>
            <div className='card-item'>
                {loading ? <div className="loading-spinner"></div> : 
                    <div className='text-s'>{isVerified ? "Your email address has been verified." : "Failed to verify your email address. Please try again"}</div>
                }
            </div>
        </div>
    )
}

export default VerificationCard;