import React, { useState, useEffect, useRef, useCallback } from 'react';
import SuggestionCategory from '../../interfaces/SuggestionCategory';
import Performer from '../../interfaces/Performer';
import { deleteSuggestionMenu, fetchPerformersByQuery, fetchSuggestionMenu, setSuggestionMenu } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { useWriteBlobContext } from '../../providers/WriteBlobProvider';
import { useReadBlobContext } from '../../providers/ReadBlobProvider';
import Modal from 'react-modal';



const AdminHomeSuggestions: React.FC = () => {
    const { imageWriteBlobService } = useWriteBlobContext();
    const { imageReadBlobService } = useReadBlobContext();

    const [categories, setCategories] = useState<SuggestionCategory[]>([]);

    const [categoriesToDelete, setCategoriesToDelete] = useState<SuggestionCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [loadingCategories, setLoadingCategories] = useState(true);

    const [openDialog, setOpenDialog] = useState<boolean>(false);

    // MOVE TO MODULAR PERFORMER SEARCH
    const [isFocused, setIsFocused] = useState(false);
    const [performerQuery, setPerformerQuery] = useState('');
    const [loadingPerformers, setLoadingPerformers] = useState(false);
    const [performerResults, setPerformerResults] = useState<Performer[] | null>(null);
    const lastSearchedRef = useRef('');
    const searchRef = useRef<HTMLDivElement>(null);

    const [loadedImages, setLoadedImages] = useState<Map<String, String | null>>(new Map());
    const [imagesToUpload, setImagesToUpload] = useState<Map<String, File>>(new Map());

    const [modalPerformer, setModalPerformer] = useState<string | null>(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = (performerSlug: string) => {
        setOpenDialog(true);
        setModalPerformer(performerSlug);
    };

    useEffect(() => {
        const fetchSuggestions = async () => {
            setLoadingCategories(true);
            const suggestionsData = await fetchSuggestionMenu();
            setCategories(suggestionsData);

            const performerImageUrlMap = new Map();
            for (let i = 0; i < suggestionsData.length; i++) {
                for (let j = 0; j < suggestionsData[i].performers.length; j++) {
                    const performerSlug = suggestionsData[i].performers[j].slug
                    const imagePath = imageReadBlobService?.getUrlIfPathExists("suggestions/" + performerSlug + "/img.jpg")
                    performerImageUrlMap.set(performerSlug, imagePath);
                }
            }
            console.log("image loaded: ", performerImageUrlMap)
            setLoadedImages(performerImageUrlMap);

            setLoadingCategories(false);
        };

        const handleClickOutsideSearch = (e: MouseEvent) => {
            if (searchRef.current && e.target instanceof Node && !searchRef.current.contains(e.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideSearch);
        fetchSuggestions();

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSearch);
        };
    }, []);


    const handlePerformerQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPerformerQuery(e.target.value);
    };


    const fetchPerformerResults = async (query: string) => {
        if (query === '') {
            setPerformerResults(null);
            return;
        }
        setLoadingPerformers(true);
        try {
            lastSearchedRef.current = query;

            const result = await fetchPerformersByQuery(query, true);
            // if(lastSearchedRef.current == result.query) {
            setPerformerResults(result)
            // }

        } catch (error) {
            console.error('Error fetching performers:', error);
        }
        setLoadingPerformers(false);
    }


    const debounceFetchResults = useCallback(
        (() => {
            let timeoutId: NodeJS.Timeout;
            return (searchQuery: string) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    fetchPerformerResults(searchQuery);
                }, 300);
            };
        })(),
        []
    );


    const handleFocus = () => {
        setIsFocused(true);
    };


    useEffect(() => {
        debounceFetchResults(performerQuery);
    }, [performerQuery, debounceFetchResults]);


    const handleSelectedPerformer = (categoryIndex: number, performer: Performer) => {
        setIsFocused(false);

        const updatedCategories = [...categories];
        updatedCategories[categoryIndex].performers.push(performer);
        setCategories(updatedCategories);
    }


    const handleCategoryNameChange = (categoryIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCategories = [...categories];
        updatedCategories[categoryIndex].name = e.target.value;
        setCategories(updatedCategories);
    };

    const addCategory = () => {
        const newCategory: SuggestionCategory = { name: '', performers: [], order: categories.length };
        setCategories([...categories, newCategory]);
    };

    const deleteCategory = (categoryIndex: number) => {
        setCategoriesToDelete([...categoriesToDelete, categories[categoryIndex]])

        const updatedCategories = categories.filter((_, index) => index !== categoryIndex);
        setCategories(updatedCategories);
    };

    const moveCategoryUp = (categoryIndex: number) => {
        if (categoryIndex === 0) return;
        const updatedCategories = [...categories];
        [updatedCategories[categoryIndex - 1], updatedCategories[categoryIndex]] = [updatedCategories[categoryIndex], updatedCategories[categoryIndex - 1]];
        setCategories(updatedCategories);
    };

    const moveCategoryDown = (categoryIndex: number) => {
        if (categoryIndex === categories.length - 1) return;
        const updatedCategories = [...categories];
        [updatedCategories[categoryIndex + 1], updatedCategories[categoryIndex]] = [updatedCategories[categoryIndex], updatedCategories[categoryIndex + 1]];
        setCategories(updatedCategories);
    };

    const deletePerformer = (categoryIndex: number, performerIndex: number) => {
        const updatedCategories = [...categories];
        updatedCategories[categoryIndex].performers = updatedCategories[categoryIndex].performers.filter((_, index) => index !== performerIndex);
        setCategories(updatedCategories);
    };

    const movePerformerUp = (categoryIndex: number, performerIndex: number) => {
        if (performerIndex === 0) return;
        const updatedCategories = [...categories];
        [updatedCategories[categoryIndex].performers[performerIndex - 1], updatedCategories[categoryIndex].performers[performerIndex]] = [updatedCategories[categoryIndex].performers[performerIndex], updatedCategories[categoryIndex].performers[performerIndex - 1]];
        setCategories(updatedCategories);
    };

    const movePerformerDown = (categoryIndex: number, performerIndex: number) => {
        if (performerIndex === categories[categoryIndex].performers.length - 1) return;
        const updatedCategories = [...categories];
        [updatedCategories[categoryIndex].performers[performerIndex + 1], updatedCategories[categoryIndex].performers[performerIndex]] = [updatedCategories[categoryIndex].performers[performerIndex], updatedCategories[categoryIndex].performers[performerIndex + 1]];
        setCategories(updatedCategories);
    };

    const handleCategorySelect = (categoryIndex: number) => {
        if (selectedCategory === categoryIndex) {
            setSelectedCategory(-1)
        } else {
            setSelectedCategory(categoryIndex);
        }
    }

    const handlePerformerFileChange = (categoryIndex: number, performerIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const performerSlug = categories[categoryIndex].performers[performerIndex].slug
        const files = e.target.files;

        if (files && files.length > 0) {
            setImagesToUpload(prevMap => {
                const newMap = new Map(prevMap);
                newMap.set(performerSlug, files[0]);
                return newMap;
            });
        }

    };


    const handleSubmitChanges = async () => {
        setLoadingCategories(true);
        if (imageWriteBlobService) {
            for (const [performerSlug, imageFile] of imagesToUpload) {
                imageWriteBlobService.uploadFile("suggestions/" + performerSlug + "/img.jpg", imageFile)
            }
        }

        for (let i = 0; i < categoriesToDelete.length; i++) {
            if (categoriesToDelete[i].id !== undefined) {
                const deleteCategoryResponseEither = await deleteSuggestionMenu(categoriesToDelete[i].id as number);
                if (isRight(deleteCategoryResponseEither)) {
                    console.log("SUCCESS DELETE: ", deleteCategoryResponseEither.right)
                } else {
                    console.log("FAIL DELETE: ", deleteCategoryResponseEither.left)
                }
            }
        }


        for (let i = 0; i < categories.length; i++) {
            if (categories[i].name == '' || categories[i].performers.length == 0) {
                continue;
            }
            categories[i].order = i;
            const setCategoryResponseEither = await setSuggestionMenu(categories[i]);
            if (isRight(setCategoryResponseEither)) {
                console.log("SUCCESS ADD: ", setCategoryResponseEither.right)
                if (i == categories.length - 1) {
                    setCategories(setCategoryResponseEither.right) // TODO maybe do this every time AND for deletes?
                }
            } else {
                console.log("FAIL ADD: ", setCategoryResponseEither.left)
            }
        }
        setLoadingCategories(false);
    }

    return (
        <div className='card card-top' >
            <div className='row'>
                <div className='left'>
                    <div className='text-l'>Home Suggestions</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            {loadingCategories ?
                <div className="loading-spinner" />
                :
                <>
                    <div className='card-item'>
                        <button className='admin-button' onClick={addCategory}>Add Category</button>
                    </div>
                    {categories && categories.map((category, categoryIndex) => (
                        <div className='card-item'>
                            <div className='card-list gray-bg'>
                                <div className='row'>
                                    <div className='left'>
                                        <div className='text-xs'>Category:&nbsp;</div>
                                        <input className='admin-input' type="text" value={category.name} onChange={handleCategoryNameChange(categoryIndex)} placeholder="Type Category Name..." />
                                    </div>
                                    <button className='admin-button' onClick={() => moveCategoryUp(categoryIndex)}>⬆</button>
                                    <button className='admin-button' onClick={() => moveCategoryDown(categoryIndex)}>⬇</button>
                                    <button className='admin-button' onClick={() => handleCategorySelect(categoryIndex)}>{selectedCategory === categoryIndex ? "Collapse" : "Expand"}</button>
                                    <button className='admin-delete-button' onClick={() => deleteCategory(categoryIndex)}>Delete</button>
                                </div>
                                {selectedCategory === categoryIndex &&
                                    <div className='card-item'>
                                        <div ref={searchRef}>
                                            <div className='card-item'>
                                                <input
                                                    type="text"
                                                    value={performerQuery}
                                                    onFocus={handleFocus}
                                                    onChange={handlePerformerQueryChange}
                                                    placeholder="Search for the name of the performer or team to add"
                                                    className='admin-search'
                                                />
                                            </div>
                                            {(performerResults !== null && isFocused) &&
                                                <div className='relative'>
                                                    <div className='input-search-results'>
                                                        {performerResults.map(performer => (
                                                            <div className="menu-item" onClick={() => handleSelectedPerformer(categoryIndex, performer)}>
                                                                <div className='menu-item-title'>{performer.name}</div>
                                                            </div>))}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {category.performers.map((performer, performerIndex) => (
                                            <div className='row'>
                                                <div className='left'>
                                                    <div className='gap-s' />
                                                    <div className='text-xs gap-xl'>{performer.name}</div>
                                                    <input type="file" className='no-display' id={`file-input-${performerIndex}`} onChange={handlePerformerFileChange(categoryIndex, performerIndex)} />
                                                    <label htmlFor={`file-input-${performerIndex}`} className='admin-button'>Upload Image</label>
                                                    {(imagesToUpload.has(performer.slug) || loadedImages.get(performer.slug) != null) &&
                                                        <>
                                                            {imagesToUpload.has(performer.slug) ?
                                                                <button className='admin-button' onClick={() => handleOpenDialog(performer.slug)}>View New Image</button>
                                                                :
                                                                <button className='admin-button' onClick={() => handleOpenDialog(performer.slug)}>View Existing Image</button>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <button className='admin-button' onClick={() => movePerformerUp(categoryIndex, performerIndex)}>⬆</button>
                                                <button className='admin-button' onClick={() => movePerformerDown(categoryIndex, performerIndex)}>⬇</button>
                                                <button className='admin-delete-button' onClick={() => deletePerformer(categoryIndex, performerIndex)}>Delete</button>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </>
            }
            <Modal isOpen={openDialog} onRequestClose={handleCloseDialog} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'auto',
                    maxWidth: '1000px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '125px',
                    padding: '40px',
                    borderRadius: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                {modalPerformer && (imagesToUpload.has(modalPerformer) ?
                    <img
                        src={URL.createObjectURL(imagesToUpload.get(modalPerformer) as File)}
                        alt="Uploaded"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                    :
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${loadedImages.get(modalPerformer)})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    />
                )}
            </Modal>
        </div>
    )
}

export default AdminHomeSuggestions;