import React, { useState, useEffect } from 'react';
import SignUpFormCard from './SignUpFormCard';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuthenticationContext } from '../../providers/AuthenticationProvider';


const SignUp: React.FC = () => {
    const navigate = useNavigate();
    const { isLoggedIn, isAuthLoading } = useAuthenticationContext();

    useEffect(() => {

        if (isLoggedIn) {
            navigate('/account')
        }
        
    }, [isLoggedIn]);

    return (
        <div className='container-info'>
            <div className='content-form'>
                {isAuthLoading ? <div className="loading-spinner"></div>
                : !isLoggedIn ?
                    <SignUpFormCard onClickLogIn={()=> navigate('/login')}/>
                    :
                    <div className='col'>
                        <div className="loading-spinner"></div>
                    </div>
                }
                
            </div>
        </div>
    )
}

export default SignUp;