import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { fetchAccessToken } from '../api/endpoints';
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import UserDto from '../interfaces/UserDto';
import LoginResponse from '../interfaces/LoginResponse';

// Define a type for the context
interface AuthenticationContextType {
    accessToken: string | null;
    expiry: number | null;
    user: UserDto | null;
    isLoggedIn: boolean;
    isAuthLoading: boolean;
    setLogin: (loginResponse: LoginResponse | null) => void;
}

// Create context
const AuthenticationContext = createContext<AuthenticationContextType>({
    accessToken: null,
    expiry: null,
    user: null,
    isLoggedIn: false,
    isAuthLoading: true,
    setLogin: () => { },
});

// Create a custom hook to use the context
export const useAuthenticationContext = () => useContext(AuthenticationContext);

interface AuthenticationProviderProps {
    children: React.ReactNode;
}

// Create a provider
export const AuthenticationProvider: React.FC<AuthenticationProviderProps> = ({ children }) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [expiry, setExpiry] = useState<number | null>(null);
    const [user, setUser] = useState<UserDto | null>(null);
    

    const updateToken = useCallback(async () => {
        setIsAuthLoading(true);
        try {
            const loginResponse = await fetchAccessToken();
            setAccessToken(loginResponse.token);
            setExpiry(loginResponse.expiry);
            setUser(loginResponse.user);

        } catch (error) {
            console.error('Failed to fetch access token', error);
            setIsLoggedIn(false);
        } finally {
            setIsAuthLoading(false);
        }
    }, []);


    useEffect(() => {
        updateToken();

        if (expiry != null) {
            const interval = setInterval(() => {
                updateToken();
            }, expiry - Date.now());
    
            return () => clearInterval(interval);
        }
        
    }, [updateToken]);


    useEffect(() => {
        if (accessToken && expiry) {
            const now = new Date().getTime();
            setIsLoggedIn(expiry > now);
        } else {
            setIsLoggedIn(false);
        }
    }, [accessToken, expiry]);

    const setLogin = (login: LoginResponse | null) => {
        if (login == null) {
            setAccessToken(null);
            setExpiry(null);
            setUser(null);
            
        } else {
            setAccessToken(login.token);
            setExpiry(login.expiry);
            setUser(login.user);
        }
    }

    return (
        <AuthenticationContext.Provider value={{ accessToken, expiry, user, isLoggedIn, isAuthLoading, setLogin}}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;