import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import ResetPassword from '../components/ResetPassword/ResetPassword';


const ResetPasswordPage: React.FC = () => {

    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <ResetPassword/>
            <Footer/>
        </div>
    )
}

export default ResetPasswordPage;