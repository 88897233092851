import React, { useState, useEffect } from 'react';
import SignUp from '../components/SignUp/SignUp';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

const SignupPage: React.FC = () => {

    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <SignUp/>
            <Footer/>
        </div>
    )
}

export default SignupPage;