import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel, GridRowParams } from '@mui/x-data-grid';
import ExpandedOrder from '../../interfaces/ExpandedOrder';
import { useWriteBlobContext } from '../../providers/WriteBlobProvider';
import { useReadBlobContext } from '../../providers/ReadBlobProvider';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { issueRefund, markCancelled, markComplete, markETicketsStatus, markMobileDelivered, markReadyForPickup, markShipped, processOrderPayment, releaseFunds } from '../../api/endpoints';
import { formatDateToSlash } from '../../utils/DateUtils/DateUtils';


interface AdminOrderModalProps {
    order: ExpandedOrder
    refetchData: () => Promise<void>;
    closeModal?: () => void;
}
const AdminOrderModal: React.FC<AdminOrderModalProps> = ({ order, refetchData, closeModal = null }) => {
    const { eticketWriteBlobService } = useWriteBlobContext();
    const { eticketReadBlobService } = useReadBlobContext();

    const [loadingAction, setLoadingAction] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<ExpandedOrder>(order)
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [link, setLink] = useState('');
    const [trackingNumber, setTrackingNumber] = useState('');
    const [trackingUrl, setTrackingUrl] = useState('');
    const [refund, setRefund] = useState('');
    const [refundDescription, setRefundDescription] = useState('');

    const logColumns = [
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'changeMaker', headerName: 'Change Maker', width: 200 },
        { field: 'changeDescription', headerName: 'Change Description', width: 250 },
        { field: 'orderStatus', headerName: 'Order Status', width: 200 },
        { field: 'balance', headerName: 'Balance', width: 100 }
    ];

    const handleFileUpload = () => {
        if (eticketWriteBlobService && uploadedFile) {
            const uuid = crypto.randomUUID();
            const path = uuid + "/" + uploadedFile.name
            eticketWriteBlobService.uploadFile(path, uploadedFile);

            const existingOrder: ExpandedOrder = selectedOrder as ExpandedOrder;
            setSelectedOrder({ ...existingOrder, files: [...existingOrder.files, path] })
        }
    }

    const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLink(e.target.value);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setUploadedFile(files[0]);
        }
    };

    const handleRefundChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRefund(e.target.value);
    };

    const handleRefundDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRefundDescription(e.target.value);
    };

    const handleTrackingNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTrackingNumber(e.target.value);
    };

    const handleTrackingUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTrackingUrl(e.target.value);
    };

    const handleFileDownload = (file: string) => {
        if (eticketReadBlobService) {
            const fileUrl = eticketReadBlobService.getExactUrlForPath(file)
            if (fileUrl == null) return;
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = ''; // Setting download attribute to initiate a download instead of navigation
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const handleFileSubmit = async (orderId: number, files: string[]) => {
        setLoadingAction(true);
        if (files.length > 0) {
            const responseEither = await markETicketsStatus({ id: orderId, files: files });

            if (isRight(responseEither)) {
                const orderResponse = responseEither.right;
                setSelectedOrder(orderResponse);
            } else {
                const orderResponse = responseEither.left;
                console.log(orderResponse);
            }
            refetchData();
        }
        setLoadingAction(false);
    }

    const handleLinkSubmit = async (orderId: number, links: string[]) => {
        setLoadingAction(true);
        const responseEither = await markMobileDelivered({ id: orderId, links: links });

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            setSelectedOrder(orderResponse);
        } else {
            const orderResponse = responseEither.left;
            console.log(orderResponse);
        }
        refetchData();
        setLoadingAction(false);
    }

    const handleRefundSubmit = async (orderId: number) => {
        setLoadingAction(true);
        if (refund !== '') {
            const refundAmount = parseFloat(refund);
            const responseEither = await issueRefund({ orderId: orderId, amount: refundAmount, refundDescription: refundDescription });

            if (isRight(responseEither)) {
                const orderResponse = responseEither.right;
                setSelectedOrder(orderResponse);
            } else {
                const orderResponse = responseEither.left;
                console.log(orderResponse);
            }
        }
        refetchData();
        setLoadingAction(false);
    }

    const handleShippingSubmit = async (orderId: number) => {
        setLoadingAction(true);
        const responseEither = await markShipped({ orderId: orderId, trackingNumber: trackingNumber, trackingUrl: trackingUrl });

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            setSelectedOrder(orderResponse);
        } else {
            const orderResponse = responseEither.left;
            console.log(orderResponse);
        }
        refetchData();
        setLoadingAction(false);
    }

    const handleCompleteSubmit = async (orderId: number) => {
        setLoadingAction(true);
        const responseEither = await markComplete({ id: orderId });

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            setSelectedOrder(orderResponse);
        } else {
            const orderResponse = responseEither.left;
            console.log(orderResponse);
        }
        refetchData();
        setLoadingAction(false);
    }

    const handleCancelSubmit = async (orderId: number) => {
        setLoadingAction(true);
        const responseEither = await markCancelled(orderId);

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            setSelectedOrder(orderResponse);
        } else {
            const orderResponse = responseEither.left;
            console.log(orderResponse);
        }
        refetchData()
        setLoadingAction(false);
    }

    const handleProcessAnywaySubmit = async (orderId: number) => {
        setLoadingAction(true);
        const responseEither = await processOrderPayment(orderId);

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            console.log(orderResponse);
            setSelectedOrder(orderResponse);
        } else {
            const orderResponse = responseEither.left;
            console.log(orderResponse);
        }
        refetchData()
        setLoadingAction(false);
    }

    const handleReleaseFundsSubmit = async (orderId: number) => {
        setLoadingAction(true);
        const responseEither = await releaseFunds(orderId);

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            console.log(orderResponse);
            setSelectedOrder(orderResponse);
        } else {
            const orderResponse = responseEither.left;
            console.log(orderResponse);
        }
        refetchData()
        setLoadingAction(false);
    }

    const handleReadyForPickupSubmit = async (orderId: number) => {
        setLoadingAction(true);
        const responseEither = await markReadyForPickup({ id: orderId });

        if (isRight(responseEither)) {
            const orderResponse = responseEither.right;
            setSelectedOrder(orderResponse);
        } else {
            const orderResponse = responseEither.left;
            console.log(orderResponse);
        }
        refetchData();
        setLoadingAction(false);
    }

    return (
        <div>
            <div className='card-item'>
                <div className='row'>
                    {closeModal &&
                        <button className='back-button' onClick={closeModal}>⬅</button>
                    }
                    <div className='text-m'>{"Order Details (#" + selectedOrder.id + "): "}</div>
                </div>
            </div>
            <hr />
            {loadingAction ?
                <div className="loading-spinner"></div>
                :
                <>
                    <div className='card-item'>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Event Name:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.eventName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Event Venue:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.eventVenue}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Event Date:&nbsp;</div>
                            <div className='text-xs-light'>{formatDateToSlash(selectedOrder.eventDate)}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Section:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.section}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Row:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.row}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>User First Name:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.userFirstName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>User Last Name:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.userLastName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Billing First Name:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.billFirstName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Billing Last Name:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.billLastName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Address:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.billAddress}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>City:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.billCity}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>State/Province:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.billState}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Postal Code:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.billZip}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Phone Number:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.billingPhoneNumber}</div>
                        </div>
                    </div>
                    <hr />
                    <div className='card-item'>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Upgrade:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.upgrade ? 'Yes' : 'No'}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Order Date:&nbsp;</div>
                            <div className='text-xs-light'>{formatDateToSlash(selectedOrder.purchaseDate)}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Order Status:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.orderStatus}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Riskified Status:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.riskifiedStatus}</div>
                            <div className='gap' /><div className='gap' />
                            <button className='admin-delete-button' onClick={() => handleProcessAnywaySubmit(selectedOrder.id)}>Process Payment Anyway</button>
                            <div className='gap-s' />
                            <button className='admin-action-button' onClick={() => handleReleaseFundsSubmit(selectedOrder.id)}>Release Funds</button>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Shipping Method:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.shippingMethod}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Coupon:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.couponName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Quantity:&nbsp;</div>
                            <div className='text-xs-light'>{selectedOrder.quantity}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Price Per Ticket:&nbsp;</div>
                            <div className='text-xs-light'>${selectedOrder.perTicketPrice}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Shipping Fee:&nbsp;</div>
                            <div className='text-xs-light'>${selectedOrder.shippingFee.toFixed(2)}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Service Fee:&nbsp;</div>
                            <div className='text-xs-light'>${selectedOrder.serviceFee.toFixed(2)}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Taxes:&nbsp;</div>
                            <div className='text-xs-light'>${selectedOrder.taxes.toFixed(2)}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Discount:&nbsp;</div>
                            <div className='text-xs-light'>-${selectedOrder.discount.toFixed(2)}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Total Price:&nbsp;</div>
                            <div className='text-xs-light'>${selectedOrder.totalPrice.toFixed(2)}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Refunded Amount:&nbsp;</div>
                            <div className='text-xs-light'>${selectedOrder.refundAmount.toFixed(2)}</div>
                        </div>
                    </div>
                    <hr />
                    <div className='card-item'>
                        <div className='text-xs-bold'>Links: </div>
                    </div>
                    <div className='row'>
                        <div className='left'>
                            <input type="text" className='admin-input' value={link} onChange={handleLinkChange} placeholder="Type link..." />
                            <button className='admin-button' onClick={() => setSelectedOrder({ ...selectedOrder, links: [...selectedOrder.links, link] })}>Upload Link</button>
                        </div>
                        <button className='admin-action-button' onClick={() => handleLinkSubmit(selectedOrder.id, selectedOrder.links)}>Save & Mark as "Mobile Transferred"</button>
                    </div>
                    <div className='card-item'>
                        {selectedOrder.links.map((link, index) => (
                            <div className='row'>
                                <div className='gap' />
                                <div className='text-xs-light'>•&nbsp;&nbsp;{link}</div>
                                <div className='gap-s' />
                                <button className='admin-delete-small-button' onClick={() => setSelectedOrder({ ...selectedOrder, links: selectedOrder.links.filter((_, i) => i !== index) })}>Delete</button>
                            </div>
                        ))}
                    </div>
                    <hr />
                    <div className='card-item'>
                        <div className='text-xs-bold'>Files: </div>
                    </div>
                    <div className='row'>
                        <div className='left'>
                            <input type="file" className='no-display' id="file-input" onChange={handleFileChange} />
                            <label htmlFor='file-input' className='admin-button'>Choose File</label>
                            {uploadedFile &&
                                <>
                                    <button className='admin-button' onClick={() => handleFileUpload()}>Upload File</button>
                                    <div className='gap-s' />
                                    <div className='text-xs-light file-name-small'>{uploadedFile.name}</div>
                                </>
                            }
                        </div>
                        <button className='admin-action-button' onClick={() => handleFileSubmit(selectedOrder.id, selectedOrder.files)}>Save & Mark as "E-Tickets Ready"</button>
                    </div>
                    <div className='card-item'>
                        {selectedOrder.files.map((file, index) => {
                            const parts = file.split('/', 2);
                            const name = parts.length > 1 ? file.substring(parts[0].length + 1) : file
                            return (
                                <div className='row'>
                                    <div className='gap' />
                                    <div className='text-xs-light file-name'>•&nbsp;&nbsp;{name}</div>
                                    <div className='gap-s' />
                                    <button className='admin-small-button' onClick={() => handleFileDownload(file)}>View</button>
                                    <div className='gap-s' />
                                    <button className='admin-delete-small-button' onClick={() => setSelectedOrder({ ...selectedOrder, files: selectedOrder.files.filter((_, i) => i !== index) })}>Delete</button>
                                </div>
                            )
                        })}
                    </div>
                    <hr />
                    <div className='card-item'>
                        <div className='text-xs-bold'>Shipping: </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <input
                                type="number"
                                value={trackingNumber}
                                onChange={handleTrackingNumberChange}
                                placeholder="Tracking Number"
                                min="0"
                                step="0.01"
                                className='admin-input'
                            />
                            <div className='gap-s' />
                            <input
                                type="text"
                                value={trackingUrl}
                                onChange={handleTrackingUrlChange}
                                placeholder="Tracking Url"
                                className='admin-input'
                                style={{ width: "300px" }}

                            />
                            <div className='gap-s' />
                            <button className='admin-action-button' onClick={() => handleShippingSubmit(selectedOrder.id)}>Mark as "Shipped"</button>
                        </div>
                    </div>
                    <hr />
                    <div className='card-item'>
                        <div className='text-xs-bold'>Change Status: </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <button className='admin-action-button' onClick={() => handleReadyForPickupSubmit(selectedOrder.id)}>Mark as "Ready For Pickup"</button>
                            <div className='gap-s' />
                            <button className='admin-action-button' onClick={() => handleCompleteSubmit(selectedOrder.id)}>Mark as "Complete"</button>
                            <div className='gap-s' />
                            <button className='admin-delete-button' onClick={() => handleCancelSubmit(selectedOrder.id)}>Mark as "Cancelled"</button>
                        </div>
                    </div>
                    <hr />
                    <div className='card-item'>
                        <div className='text-xs-bold'>Refund: </div>
                    </div>
                    <div className='card-item'>
                        <div className='row'>
                            <input
                                type="number"
                                value={refund}
                                onChange={handleRefundChange}
                                placeholder="Refund Amount"
                                min="0"
                                step="0.01"
                                className='admin-input'
                            />
                            <div className='gap-s' />
                            <input
                                type="text"
                                value={refundDescription}
                                onChange={handleRefundDescriptionChange}
                                placeholder="Refund Description"
                                className='admin-input'
                                style={{ width: "300px" }}

                            />
                            <div className='gap-s' />
                            <button className='admin-action-button' onClick={() => handleRefundSubmit(selectedOrder.id)}>Submit Refund</button>
                        </div>
                    </div>
                    <hr />
                    <div className='card-item'>
                        <div className='text-xs-bold'>Log Entries: </div>
                    </div>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={selectedOrder.logEntries}
                            columns={logColumns}
                            getRowId={(row) => `${row.date}-${row.changeMaker}`}
                            rowCount={selectedOrder.logEntries.length}
                            pageSizeOptions={[5, 10, 20]}
                            className='admin-data-grid'
                        />
                    </div>
                </>
            }

        </div>
    )
}
export default AdminOrderModal;