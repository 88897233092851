import './DropdownUtils.css';
import DatePicker from 'react-datepicker';
import Slider from '@mui/material/Slider';
import React, { useState, useEffect, useRef } from 'react';


interface DateRangeMenuProps {
    startDate: Date | null;
    endDate: Date | null;
    onChange: (dates: [Date | null, Date | null]) => void;
    rightAligned?: boolean
}

export const DateRangeMenu: React.FC<DateRangeMenuProps> = ({ startDate, endDate, onChange, rightAligned = true }) => {
    return (
        <div className={`dropdown ${rightAligned && 'right-aligned'}`}>
            <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                calendarClassName="datepicker"
            />
        </div>
    );
};


interface MultiSelectMenuProps {
    options: string[],
    selectedOptions: Set<string>;
    setSelectedOptions: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const MultiSelectMenu: React.FC<MultiSelectMenuProps> = ({ options, selectedOptions, setSelectedOptions }) => {

    const handleCheckboxChange = (option: string) => {

        setSelectedOptions(prevSelectedOptions => {
            const newSelectedOptions = new Set(prevSelectedOptions);
            if (newSelectedOptions.has(option)) {
                newSelectedOptions.delete(option);
            } else {
                newSelectedOptions.add(option);
            }
            return newSelectedOptions;
        })
    }

    return (
        <div className="dropdown-menu right-aligned">
            {options.map(option => (
                <div key={option} className="select-item" onClick={() => handleCheckboxChange(option)}>
                    <input
                        type="checkbox"
                        id={option}
                        checked={selectedOptions.has(option)}
                        onChange={() => { }}
                        className='checkbox'
                    />
                    {option}
                </div>
            )
            )}
        </div>
    );
};


interface SingleSelectMenuProps {
    options: string[],
    selectedOption: string;
    setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
    closeMenu: () => void;
    mustSelect: boolean;
    rightAligned?: boolean
}

export const SingleSelectMenu: React.FC<SingleSelectMenuProps> = ({ options, selectedOption, setSelectedOption, closeMenu, mustSelect, rightAligned = false }) => {

    const handleSelect = (option: string) => {
        closeMenu();
        setSelectedOption(prevSelectedOption => {
            if (!mustSelect && prevSelectedOption == option) {
                return ''
            } else {
                return option
            }
        })
    }

    return (
        <div className={`dropdown-menu ${rightAligned && 'right-aligned'}`}>
            {options.map(option => (
                <div key={option} className={`select-item ${option == selectedOption && "selected-item"}`} onClick={() => handleSelect(option)}>
                    {/* <input
                        type="checkbox"
                        id={option}
                        checked={option == selectedOption}
                        onChange={() => {}}
                        className='checkbox'
                    /> */}
                    {option}
                </div>
            )
            )}
        </div>
    );
};


interface SliderMenuProps {
    rangeOptions: [number, number],
    selectedOptions: [number, number]
    setSelectedOptions: React.Dispatch<React.SetStateAction<[number, number]>>;
}

export const SliderMenu: React.FC<SliderMenuProps> = ({ rangeOptions, selectedOptions, setSelectedOptions }) => {

    const [localSelectedOptions, setLocalSelectedOptions] = useState<[number, number]>(selectedOptions);

    const handleChange = (event: globalThis.Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setLocalSelectedOptions([newValue[0], newValue[1]]);
        }
    };

    const handleChangeCommitted = (event: React.SyntheticEvent | globalThis.Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            setSelectedOptions([newValue[0], newValue[1]]);
        }
    };

    const handleInputChange = (index: number, value: string) => {
        if (value == '') {
            setLocalSelectedOptions(prevOptions => {
                const newOptions: [number, number] = [...prevOptions];
                newOptions[index] = 0;
                return newOptions;
            });
            setSelectedOptions(prevOptions => {
                const newOptions: [number, number] = [...prevOptions];
                newOptions[index] = 0;
                return newOptions;
            });
        }

        let newValue = parseInt(value);
        if (!isNaN(newValue)) {
            setLocalSelectedOptions(prevOptions => {
                const newOptions: [number, number] = [...prevOptions];
                newOptions[index] = newValue;
                return newOptions;
            });
            setSelectedOptions(prevOptions => {
                const newOptions: [number, number] = [...prevOptions];
                newOptions[index] = newValue;
                return newOptions;
            });
        }
    };

    return (
        <div className="dropdown-menu">
            <div className='row space-around'>
            <div className='text-xs-bold'>Min: </div>
            <div className='text-xs-bold'>Max: </div>

            </div>
            <div className='row'>
                {/* <div className='slider-value'>${localSelectedOptions[0]}</div>
                <div className='slider-value'>${localSelectedOptions[1]}</div> */}
                
                <input
                    type="text"
                    className='slider-value'
                    value={localSelectedOptions[0] > 0 ? `$${new Intl.NumberFormat().format(localSelectedOptions[0])}` : ''}
                    onChange={(e) => handleInputChange(0, e.target.value.replace('$', ''))}
                />
                <input
                    type="text"
                    className='slider-value'
                    value={localSelectedOptions[1] > 0 ? `$${new Intl.NumberFormat().format(localSelectedOptions[1])}` : ''}
                    onChange={(e) => handleInputChange(1, e.target.value.replace('$', ''))}
                />
            </div>
            <div className='row' style={{padding: "0px 10px", boxSizing: 'border-box'}}>
            <Slider
                value={localSelectedOptions}
                min={rangeOptions[0]}
                max={rangeOptions[1]}
                onChange={handleChange}
                onChangeCommitted={handleChangeCommitted}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `$${new Intl.NumberFormat().format(value)}`}
            />
            </div>
        </div>
    );
};