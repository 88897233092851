import React, { createContext, useContext, useState, useEffect } from 'react';
import { BlobServiceClient, AnonymousCredential, ContainerClient } from '@azure/storage-blob';


class WriteBlobService {

    private containerClient: ContainerClient;

    private constructor(containerClient: ContainerClient) {
        this.containerClient = containerClient;
    }
    
    // Factory method for async initialization
    static async create(containerName: string, containerSAS: string): Promise<WriteBlobService> {
        // TODO this will need real auth for WRITE capabilities
        const blobUrl = process.env.REACT_APP_BLOB_URL;
        const blobServiceClient = new BlobServiceClient(blobUrl + "?" + containerSAS);
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const instance = new WriteBlobService(containerClient);
        return instance;
    }


    async uploadFile(filePath: string, file: File) {
        const blockBlobClient = this.containerClient.getBlockBlobClient(filePath);
        await blockBlobClient.uploadData(file);
    }
}


// Define a type for the context
interface WriteBlobContextType {
    imageWriteBlobService: WriteBlobService | null;
    eticketWriteBlobService: WriteBlobService | null;
}

// Create context
const WriteBlobContext = createContext<WriteBlobContextType>({
    imageWriteBlobService: null,
    eticketWriteBlobService: null
});

// Create a custom hook to use the context
export const useWriteBlobContext = () => useContext(WriteBlobContext);

interface WriteBlobProviderProps {
    children: React.ReactNode;
}


// TODO NEED TO MAKE SURE blobPaths gets refreshed on a change


// Create a provider
export const WriteBlobProvider: React.FC<WriteBlobProviderProps> = ({ children }) => {
    const [imageWriteBlobService, setImageWriteBlobService] = useState<WriteBlobService | null>(null);
    const [eticketWriteBlobService, setEticketWriteBlobService] = useState<WriteBlobService | null>(null);

    const imagesContainer = process.env.REACT_APP_IMAGES_CONTAINER as string;
    const imagesKey = process.env.REACT_APP_BLOB_IMAGES_KEY as string;

    const eticketsContainer = process.env.REACT_APP_ETICKETS_CONTAINER as string;
    const eticketsKey = process.env.REACT_APP_BLOB_ETICKETS_KEY as string;

    useEffect(() => {
        const initialize = async () => {
            const imageService = await WriteBlobService.create(imagesContainer, imagesKey);
            setImageWriteBlobService(imageService);

            const eticketService = await WriteBlobService.create(eticketsContainer, eticketsKey);
            setEticketWriteBlobService(eticketService);
        }

        initialize();
    }, []);


    return (
        <WriteBlobContext.Provider value={{imageWriteBlobService, eticketWriteBlobService}}>
        {children}
        </WriteBlobContext.Provider>
    );
};

export default WriteBlobProvider;