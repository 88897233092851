import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Account from '../components/Account/Account';
import Footer from '../components/Footer/Footer';


const AccountPage: React.FC = () => {
    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <Account/>
            <Footer/>
        </div>
    )
}

export default AccountPage;