import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import { DataGrid, GridColDef, GridPaginationModel, GridSortModel, GridRowParams } from '@mui/x-data-grid';
import ExpandedOrder from '../../interfaces/ExpandedOrder';
import { useWriteBlobContext } from '../../providers/WriteBlobProvider';
import { useReadBlobContext } from '../../providers/ReadBlobProvider';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { issueRefund, markCancelled, markComplete, markConsignmentReturned, markConsignmentUnsold, markConsignmentCompleted, markETicketsStatus, markMobileDelivered, markReadyForPickup } from '../../api/endpoints';
import ConsignmentDto from '../../interfaces/ConsignmentDto';



interface AdminConsignmentModalProps {
    consignment: ConsignmentDto
    refetchData: () => Promise<void>;
    closeModal?: () => void;
}
const AdminConsignmentModal: React.FC<AdminConsignmentModalProps> = ({ consignment, refetchData, closeModal = null }) => {

    const [loadingAction, setLoadingAction] = useState(false);
    const [selectedConsignment, setSelectedConsignment] = useState<ConsignmentDto>(consignment)
    const [checkNumber, setCheckNumber] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [paymentAmount, setPaymentAmount] = useState('')

    const handleCheckNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckNumber(e.target.value);
    };

    const handlePaymentDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentDate(e.target.value);
    };


    const handlePaymentAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentAmount(e.target.value);
    };

    const handleCompleteSubmit = async (consignmentId: number) => {
        setLoadingAction(true);
        const responseEither = await markConsignmentCompleted({ consignmentId: consignmentId, checkNumber: checkNumber, paymentDate: new Date(paymentDate), paymentAmount: parseFloat(paymentAmount) });

        if (isRight(responseEither)) {
            const consignmentResponse = responseEither.right;
            setSelectedConsignment(consignmentResponse);
        } else {
            const consignmentResponse = responseEither.left;
            console.log(consignmentResponse);
        }
        refetchData()
        setLoadingAction(false);
    }

    const handleReturnedSubmit = async (consignmentId: number) => {
        setLoadingAction(true);
        const responseEither = await markConsignmentReturned(consignmentId);

        if (isRight(responseEither)) {
            const consignmentResponse = responseEither.right;
            setSelectedConsignment(consignmentResponse);
        } else {
            const consignmentResponse = responseEither.left;
            console.log(consignmentResponse);
        }
        refetchData()
        setLoadingAction(false);
    }

    const handleUnsoldSubmit = async (consignmentId: number) => {
        setLoadingAction(true);
        const responseEither = await markConsignmentUnsold(consignmentId);

        if (isRight(responseEither)) {
            const consignmentResponse = responseEither.right;
            setSelectedConsignment(consignmentResponse);
        } else {
            const consignmentResponse = responseEither.left;
            console.log(consignmentResponse);
        }
        refetchData()
        setLoadingAction(false);
    }

    return (
        <div>
            <div className='card-item'>
                <div className='row'>
                    {closeModal &&
                        <button className='back-button' onClick={closeModal}>⬅</button>
                    }
                    <div className='text-m'>{"Consignment Details (#" + selectedConsignment.id + "): "}</div>
                </div>
            </div>
            <hr />
            {loadingAction ?
                <div className="loading-spinner" />
                :
                <>
                    <div className='card-item'>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Event Name:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.eventName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Event Venue:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.eventVenue}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Event Date:&nbsp;</div>
                            <div className='text-xs-light'>{new Date(selectedConsignment.eventDate).toLocaleDateString()}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Section:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.section}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Row:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.row}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Quantity:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.quantity}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>First Name:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.userFirstName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Last Name:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.userLastName}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Address:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.customerAddress}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Phone Number:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.customerPhone}</div>
                        </div>
                    </div>

                    <hr />
                    <div className='card-item'>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Created At:&nbsp;</div>
                            <div className='text-xs-light'>{new Date(selectedConsignment.createdAt).toLocaleDateString()}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Updated At:&nbsp;</div>
                            <div className='text-xs-light'>{new Date(selectedConsignment.updatedAt).toLocaleDateString()}</div>
                        </div>
                        <div className='row'>
                            <div className='text-xs-bold gap-xl'>Status:&nbsp;</div>
                            <div className='text-xs-light'>{selectedConsignment.status}</div>
                        </div>
                        {selectedConsignment.paymentAmount &&
                            <>
                                <div className='row'>
                                    <div className='text-xs-bold gap-xl'>Payment Date:&nbsp;</div>
                                    <div className='text-xs-light'>{new Date(selectedConsignment.paymentDate).toLocaleDateString()}</div>
                                </div>
                                <div className='row'>
                                    <div className='text-xs-bold gap-xl'>Payment Amount:&nbsp;</div>
                                    <div className='text-xs-light'>${selectedConsignment.paymentAmount.toFixed(2)}</div>
                                </div>
                                <div className='row'>
                                    <div className='text-xs-bold gap-xl'>Check Number:&nbsp;</div>
                                    <div className='text-xs-light'>{selectedConsignment.checkNumber}</div>
                                </div>
                            </>
                        }
                    </div>
                    <hr />
                    <div>
                        <div className='card-item'>
                            <div className='row'>
                                <input type="text" className="admin-input" value={checkNumber} onChange={handleCheckNumberChange} placeholder="Check Number" required />
                                <div className='gap-s' />
                                <input type="number" className="admin-input" min="0" step="0.01" value={paymentAmount} onChange={handlePaymentAmountChange} placeholder="Payment Amount" required />
                            </div>
                        </div>

                        <div className='card-item'>
                            <div className='row'>
                                <input type="date" className="admin-input" value={paymentDate} onChange={handlePaymentDateChange} placeholder="Payment Date" required />
                                <div className='gap-s' />
                                <button className='admin-action-button' onClick={() => handleCompleteSubmit(selectedConsignment.id)}>Mark Consignment as "Complete"</button>
                            </div>
                        </div>

                        <hr />

                        <div className='card-item'>
                            <div className='row'>
                                <button className='admin-delete-button' onClick={() => handleReturnedSubmit(selectedConsignment.id)}>Mark as Returned</button>
                                <div className='gap-s'/>
                                <button className='admin-delete-button' onClick={() => handleUnsoldSubmit(selectedConsignment.id)}>Mark As Unsold</button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default AdminConsignmentModal;