import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { pass } from 'fp-ts/lib/Writer';
import { submitConsignments, tryLogIn } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { useAuthenticationContext } from '../../providers/AuthenticationProvider';
import logo from '../../assets/714-logo-hover.svg';



interface ConsignmentResponseCardProps {
    success: boolean;
    submittedConsignments: number[];
}

const ConsignmentResponseCard: React.FC<ConsignmentResponseCardProps> = ({success, submittedConsignments}) => {

    return (
        <div className='card'>
            <div className='text-l'>{success ? "Consignment Successfully Submitted!" : "Issue Proccessing Consignment"}</div>
            <div className='card-item'>
                <div className='text-s-light'>
                    {success ? "Please check your account page for details or updates on the following consignments:\n#" + submittedConsignments.join(", #") : "Please try again..."}
                </div>
            </div>
            <br/>
            <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />
        </div>
    )
}

export default ConsignmentResponseCard;