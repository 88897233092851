import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Checkout from '../components/Checkout/Checkout';
import Footer from '../components/Footer/Footer';
import { Link, useParams, Navigate, useNavigate } from "react-router-dom";
import CheckoutItem from '../interfaces/CheckoutItem';
import { canBeParsedToInt, decodeObject } from '../utils/GlobalUtils';
import Listing from '../interfaces/Listing';
import { fetchEventById, fetchListingById, fetchUserRewards, getEnabledShipping } from '../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import Event from '../interfaces/Event';
import ListingSource from '../interfaces/ListingSource';


const CheckoutPage: React.FC = () => {

    const { selectedQuantity = '', isCustom = '', eventId = '', listingId = ''} = useParams<{ selectedQuantity: string, isCustom: string, eventId: string, listingId: string }>();
    const [event, setEvent] = useState<Event | null>(null)
    const [loadingEvent, setLoadingEvent] = useState<boolean>(true);

    const [listing, setListing] = useState<Listing | null>(null);
    const [loadingListing, setLoadingListing] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvent = async () => {
            setLoadingEvent(true);
            const eventDataEither = await fetchEventById(parseInt(eventId), (isCustom == "0" ? ListingSource.TEVO : ListingSource.CUSTOM));
            if (isRight(eventDataEither)) {
                setEvent(eventDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingEvent(false);
        }

        const fetchListing = async () => {
            setLoadingListing(true);
            const listingEither = await fetchListingById(listingId, parseInt(eventId), isCustom == "0" ? ListingSource.TEVO : ListingSource.CUSTOM);

            if (isRight(listingEither)) {
                setListing(listingEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingListing(false);
        };

        if (canBeParsedToInt(eventId) && canBeParsedToInt(listingId) && (isCustom === "0" || isCustom === "1")) {
            fetchEvent();
            fetchListing();
            
        } else {
            navigate(`/not-found`);
        }

    }, []);


    if (!canBeParsedToInt(selectedQuantity)) {
        return <Navigate to="/not-found" />
    }


    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <Checkout event={event} loadingEvent={loadingEvent} listing={listing} loadingListing={loadingListing} selectedQuantity={parseInt(selectedQuantity)}/>
            <Footer/>
        </div>
    )
}

export default CheckoutPage;