import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { Link } from 'react-router-dom';


const NotFoundPage: React.FC = () => {
    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <div className='container-info'>
                <div className='content-form'>
                    <div className='col'>
                        <div className='text-xl'>Oops... This page does not exist</div>
                        <Link to="/" className='action-button'>
                            Go Home
                        </Link>
                        <br/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default NotFoundPage;