import React, { useState, useEffect, useRef, useCallback } from 'react';
import Venue from '../../interfaces/Venue';
import { useReadBlobContext } from '../../providers/ReadBlobProvider';
import { useWriteBlobContext } from '../../providers/WriteBlobProvider';
import { fetchVenuesByQuery } from '../../api/endpoints';
import Modal from 'react-modal';


const AdminVenueBanners: React.FC = () => {
    const searchRef = useRef<HTMLDivElement>(null);
    const lastSearchedRef = useRef('');
    const { imageReadBlobService } = useReadBlobContext();
    const {imageWriteBlobService} = useWriteBlobContext();

    const [isFocused, setIsFocused] = useState(false);
    const [venueQuery, setVenueQuery] = useState('');
    const [loadingVenues, setLoadingVenues] = useState(false);
    const [venueResults, setVenuResults] = useState<Venue[] | null>(null);
    const [imagesToUpload, setImagesToUpload] = useState<Map<string, File>>(new Map());

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [modalPath, setModalPath] = useState<string | null>(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = (path: string) => {
        setOpenDialog(true);
        setModalPath(path);
    };


    const [venues, setVenues] = useState<string[]>(imageReadBlobService ? imageReadBlobService.getAllPathsFromBase('venues/specific/').map(path => (path.split('/')[2])) : []);

    const handleFileChange = (path: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            setImagesToUpload(prevMap => {
                const newMap = new Map(prevMap);
                newMap.set(path, files[0]);
                return newMap;
            });
        }
    };

    const handleVenueQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVenueQuery(e.target.value);
    };


    const fetchVenueResults = async (query: string) => {
        if (query === '') {
            setVenuResults(null);
            return;
        }
        setLoadingVenues(true);
        try {
            lastSearchedRef.current = query;

            const result = await fetchVenuesByQuery(query);
            // if(lastSearchedRef.current == result.query) {
                setVenuResults(result)
            // }

        } catch (error) {
            console.error('Error fetching performers:', error);
        }
        setLoadingVenues(false);
    }


    const debounceFetchResults = useCallback(
        (() => {
          let timeoutId: NodeJS.Timeout;
          return (searchQuery: string) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                fetchVenueResults(searchQuery);
            }, 300);
          };
        })(),
        []
    );


    const handleFocus = () => {
        setIsFocused(true);
    };


    useEffect(() => {
        debounceFetchResults(venueQuery);
    }, [venueQuery, debounceFetchResults]);


    const handleSelectedVenue = (venue: Venue) => {
        setIsFocused(false);

        const updatedPerformers = [...venues];
        updatedPerformers.push(venue.name);
        setVenues(updatedPerformers);
    }


    const handleSubmitChanges = async () => {
        // TODO handle deletes
        if (imageWriteBlobService) {
            for (const [path, imageFile] of imagesToUpload) {
                imageWriteBlobService.uploadFile("venues/" + path + "/img.jpg", imageFile)
            }
        }
    }

    return (
        <div className='card card-top' >
             <div className='row'>
                <div className='left'>
                    <div className='text-l'>Venue Banners</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            {/* <div className='card-item'>
                <div className='text-s-bold'>
                    By Venue
                </div>
            </div> */}
            <div ref={searchRef}>
                <div className='card-item'>
                    <input
                        type="text"
                        value={venueQuery}
                        onFocus={handleFocus}
                        onChange={handleVenueQueryChange}
                        placeholder="Search for the name of the venue to add"
                        className='admin-search'
                    />
                </div>
                {(venueResults !== null && isFocused) && 
                    <div className='relative'>
                        <div className='input-search-results'>
                            {venueResults.map(venue => (
                                <div className="menu-item" onClick={() => handleSelectedVenue(venue)}>
                                    <div className='menu-item-title'>{venue.name}</div>
                                </div>))}
                        </div>
                    </div>          
                }
                <div className='card-item'>
                    {venues.map(performer => {
                        const path = "specific/" + performer;
                        const imageUrl = imageReadBlobService?.getExactUrlForPath("venues/" + path + "/img.jpg");
                        return (
                        <div className='card-item'>
                        <div className='card-list'>
                            <div className='row'>
                                <div className='text-xs gap-xl'>{performer}</div>
                                <input type="file" className='no-display' id={`file-input-${path}`} onChange={handleFileChange(path)} />
                                    <label htmlFor={`file-input-${path}`} className='admin-button'>Upload Image</label>
                                    {(imagesToUpload.has(path) || imageUrl != null) &&
                                        <>
                                            {imagesToUpload.has(path) ?
                                                <button className='admin-button' onClick={() => handleOpenDialog(path)}>View New Image</button>
                                                :
                                                <button className='admin-button' onClick={() => handleOpenDialog(imageUrl as string)}>View Existing Image</button>
                                            }
                                        </>
                                    } 
                            </div>
                            </div>
                            </div>
                        )
                    })}
                </div>
            </div>    
            <Modal isOpen={openDialog} onRequestClose={handleCloseDialog} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'auto',
                    maxWidth: '1000px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '125px',
                    padding: '40px',
                    borderRadius: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)', 
                }}}
                appElement={document.getElementById('root') || undefined}
            >
                {modalPath && (imagesToUpload.has(modalPath)) ?
                    <img
                        src={URL.createObjectURL(imagesToUpload.get(modalPath) as File)}
                        alt="Uploaded"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                    :
                    (
                        modalPath &&
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${modalPath})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }}
                        />
                )
                }
            </Modal>  
        </div>
    )
}

export default AdminVenueBanners;