import React, { useState, useEffect } from 'react';

const AdminMarkdownDocuments: React.FC = () => {

    return (
        <div className='card card-top' >
            <div className='text-l'>Markdown Documents</div>
            <div className='card-item'>
                <div className='text-m'>Test</div>
            </div>     
        </div>
    )
}

export default AdminMarkdownDocuments;