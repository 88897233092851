import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Event from '../../interfaces/Event';
import './Events.css';
import { CalendarWidgetSmall, formatDateToWeekDayAndTime } from '../../utils/DateUtils/DateUtils'
import 'react-datepicker/dist/react-datepicker.css';
import { DateRangeMenu, MultiSelectMenu, SingleSelectMenu } from '../../utils/DropwdownUtils/DropdownUtils';
import Performer from '../../interfaces/Performer';
import { formatDateObjToMonthAndDay } from '../../utils/DateUtils/DateUtils';
import { useScreenSizeContext } from '../../providers/ScreenSizeProvider';
import { FaTimes } from 'react-icons/fa';
import { MdZoomIn, MdZoomOut, MdZoomOutMap, MdClose } from 'react-icons/md';
import EventBar from './EventBar';




interface PerformerEventsProps {
    performer: Performer | null;
    loadingPerformer: boolean;
    events: Event[];
    loadingEvents: boolean;
}

const PerformerEvents: React.FC<PerformerEventsProps> = ({ performer, loadingPerformer, events, loadingEvents }) => {
    const datePickerRef = useRef<HTMLDivElement>(null);
    const homeAwayPickerRef = useRef<HTMLDivElement>(null);
    const cityPickerRef = useRef<HTMLDivElement>(null);
    const citiesSet = new Set(events.map(event => event.venue.location))

    const [eventsToShow, setEventsToShow] = useState<Event[]>(events);
    const [numEventsToShow, setNumEventsToShow] = useState<number>(10);
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [selectedCities, setSelectedCities] = useState<Set<string>>(new Set());
    const [selectedHomeAway, setSelectedHomeAway] = useState('');
    const [activeDatePicker, setActiveDatePicker] = useState(false);
    const [activeCityPicker, setActiveCityPicker] = useState(false);
    const [activeHomeAwayPicker, setActiveHomeAwayPicker] = useState(false);

    const { isMobile } = useScreenSizeContext();


    useEffect(() => {

        const firstDate: Date | null = dateRange[0]
        const secondDate: Date | null = dateRange[1]
        if (secondDate) {
            secondDate.setDate(secondDate.getDate() + 1);
        }

        const filteredEvents = events.filter(event => {
            const eventDate = new Date(event.occursAtLocal);
            const withinDateRange = (firstDate !== null && secondDate !== null) ? eventDate >= firstDate && eventDate <= secondDate : true;
            const withinSelectedCities = selectedCities.size == 0 || selectedCities.has(event.venue.location);
            const withinHomeAway = selectedHomeAway == '' || (selectedHomeAway == 'Home Games' && event.venue.tevoVenueId == performer?.venue?.tevoVenueId) || (selectedHomeAway == 'Away Games' && event.venue.tevoVenueId !== performer?.venue?.tevoVenueId)
            return withinDateRange && withinSelectedCities && withinHomeAway
        })

        setEventsToShow(filteredEvents);
        setNumEventsToShow(10);

    }, [events, selectedCities, dateRange, selectedHomeAway, performer])


    useEffect(() => {
        const handleClickOutsideDatePicker = (e: MouseEvent) => {
            if (datePickerRef.current && e.target instanceof Node && !datePickerRef.current.contains(e.target)) {
                setActiveDatePicker(false);
            }
        };

        const handleClickOutsideCityPicker = (e: MouseEvent) => {
            if (cityPickerRef.current && e.target instanceof Node && !cityPickerRef.current.contains(e.target)) {
                setActiveCityPicker(false);
            }
        };

        const handleClickOutsideHomeAwayPicker = (e: MouseEvent) => {
            if (homeAwayPickerRef.current && e.target instanceof Node && !homeAwayPickerRef.current.contains(e.target)) {
                setActiveHomeAwayPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideDatePicker);
        document.addEventListener('mousedown', handleClickOutsideCityPicker);
        document.addEventListener('mousedown', handleClickOutsideHomeAwayPicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDatePicker);
            document.removeEventListener('mousedown', handleClickOutsideCityPicker);
            document.removeEventListener('mousedown', handleClickOutsideHomeAwayPicker);
        };
    }, []);


    const showMoreEvents = () => {
        setNumEventsToShow(prev => prev + 10);
    };

    const handleResetFilters = () => {
        setDateRange([null, null]);
        setSelectedHomeAway('');
        setSelectedCities(new Set());
    }

    const dateFiltered = dateRange[1] !== null;
    const homeAwayFiltered = selectedHomeAway !== '';
    const citiesFiltered = selectedCities.size !== 0;
    const dateRangeString = (dateRange[0] && dateRange[1]) ? formatDateObjToMonthAndDay(dateRange[0]) + " - " + formatDateObjToMonthAndDay(dateRange[1]) : '';

    return (
        <div className='container-main'>
            <div className='content-main'>
                <div className={`${isMobile ? "col" : "row"} `}>
                    <div className='row'>
                        <div className='left text-xl'>Upcoming Events</div>
                    </div>
                    {events.length > 0 &&
                        <div className={`${isMobile ? "row" : 'row justify-end'} `}>
                            <div className='relative' ref={datePickerRef}>
                                <button className={`filter-button ${dateFiltered && 'filtered'}`} onClick={(() => setActiveDatePicker(!activeDatePicker))}>{dateFiltered ? dateRangeString : "Date"}</button>
                                {activeDatePicker &&
                                    // <div className='dropdown-menu right-aligned' >
                                    <DateRangeMenu
                                        startDate={dateRange[0]}
                                        endDate={dateRange[1]}
                                        onChange={(dates) => setDateRange(dates)}
                                        rightAligned={!isMobile}
                                    />
                                    // </div>
                                }
                            </div>
                            {(performer !== null && performer.venue !== undefined) &&
                                <>
                                    <div className='gap-s' />
                                    <div className='relative' ref={homeAwayPickerRef}>
                                        {performer.category.path.split('/')[1] == "sports" && <button className={`filter-button ${homeAwayFiltered && 'filtered'}`} onClick={(() => setActiveHomeAwayPicker(!activeHomeAwayPicker))}>{homeAwayFiltered ? selectedHomeAway : "Home / Away"}</button>}
                                        {activeHomeAwayPicker &&
                                            <SingleSelectMenu
                                                options={['Home Games', 'Away Games']}
                                                selectedOption={selectedHomeAway}
                                                setSelectedOption={setSelectedHomeAway}
                                                closeMenu={() => setActiveHomeAwayPicker(false)}
                                                mustSelect={false}
                                                rightAligned={!isMobile}
                                            />
                                        }
                                    </div>
                                </>
                            }
                            <div className='gap-s' />
                            <div className='relative' ref={cityPickerRef}>
                                <button className={`filter-button ${citiesFiltered && 'filtered'}`} onClick={(() => setActiveCityPicker(!activeCityPicker))}>City</button>
                                {activeCityPicker &&
                                    <MultiSelectMenu
                                        options={Array.from(citiesSet)}
                                        selectedOptions={selectedCities}
                                        setSelectedOptions={setSelectedCities}
                                    />
                                }
                            </div>
                        </div>
                    }

                    {(dateFiltered || homeAwayFiltered || citiesFiltered) &&
                        <>
                            <br />
                            <div className='gap-s' />
                            <button className='filter-button' style={isMobile ? { width: '100%', margin: '0px 20px' } : {}} onClick={handleResetFilters}>
                                {isMobile ?
                                    <>Clear Filters</>
                                    :
                                    <div className='col'>
                                        <FaTimes />
                                    </div>
                                }

                            </button>
                        </>}
                    <br />
                    <br />

                </div>
                {loadingEvents ? (
                    <div className="loading-spinner"></div>
                ) : (
                    <div className='col'>

                        {eventsToShow.length > 0 ?
                            <>
                                {eventsToShow.slice(0, numEventsToShow).map(event => (
                                    <React.Fragment key={event.tevoEventId}>
                                        <EventBar event={event} isMobile={isMobile} />
                                        {isMobile && <div className='divider-margin' />}
                                    </React.Fragment>
                                ))}
                                {eventsToShow.length > numEventsToShow && (
                                    <div>
                                        <br />
                                        <br />
                                        <button className='filter-button no-margin' onClick={showMoreEvents}>Show More</button>
                                    </div>
                                )}
                            </>
                            :
                            <>
                                <br />
                                <br />
                                <div className='text-s'>No Upcoming Events</div>
                                <br />
                            </>
                        }
                        <br />
                    </div>
                )}
            </div>
        </div>
    )
}

export default PerformerEvents;