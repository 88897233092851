import React, { useState, useEffect } from 'react';
import './Banner.css'

interface Variation {
  title: string;
  description: string;
  button: string;
}

const Banner: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const variations: Variation[] = [
    { title: '714 REWARDS', description: 'Earn 5% back on every online purchase -- no minimum, no limits!',  button: 'GO' },
    { title: 'SELLING TICKETS?', description: 'Have tickets you can’t use? List them on our consignment program!', button: 'GOGO' },
    // { title: '714 NEWSLETTER', description: 'Subscribe to our newsletter to stay up to date with our best offers!', button: 'GOGOGO' },
    { title: 'SATISFACTION GUARANTEED', description: 'No services fees, secure and safe transactions, full-service customer care.', button: 'GOGOGOGO' }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % variations.length);
    }, 5000);
    
    return () => clearInterval(interval);
  }, [variations.length]);

  const { title, description, button } = variations[currentIndex];

  return (
    <div className='banner'>
      <div key={`title-${currentIndex}`} className='banner-title slide-in'>{title}</div>
      <div key={`description-${currentIndex}`} className='banner-description slide-in'>{description}</div>
      {/* <button key={`button-${currentIndex}`} className='banner-button slide-in'>{button}</button> */}
    </div>
  );
};

export default Banner;
