import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import './Account.css';
import { useAuthenticationContext } from '../../providers/AuthenticationProvider';
import SignUpFormCard from '../SignUp/SignUpFormCard';
import OrderDetailsCard from '../Checkout/OrderDetailsCard';
import { changeUserInfo, changeUserPassword, fetchAccountConsignments, fetchAccountOrders, fetchOrderStatuses, fetchUserInfo, fetchUserRewards, logOut } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import PurchaseOrder from '../../interfaces/PurchaseOrder';
import ShortUserInfo from '../../interfaces/ShortUserInfo';
import ConsignmentDto from '../../interfaces/ConsignmentDto';
import { CalendarWidgetSmall, formatDateToFullDate, formatDateToMonthAndDay, formatDateToSlash, formatDateToWeekDayAndTime, formatTimeZonedDateToWeekDayAndTime } from '../../utils/DateUtils/DateUtils';
import { useReadBlobContext } from '../../providers/ReadBlobProvider';
import { chainNullableK } from 'fp-ts/lib/EitherT';
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray';
import LogInFormCard from '../LogIn/LogInFormCard';
import UserDto from '../../interfaces/UserDto';
import { stat } from 'fs';
import ResponseMessage from '../../interfaces/ResponseMessage';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useScreenSizeContext } from '../../providers/ScreenSizeProvider';
import logo from '../../assets/714-logo-hover.svg';




interface OrderBarProps {
    order: PurchaseOrder;
    isMobile: boolean;
}

const OrderBar: React.FC<OrderBarProps> = ({ order, isMobile }) => {

    console.log(order);

    const { eticketReadBlobService } = useReadBlobContext();

    const handleTicketClick = () => {
        if (order.links.length > 0) {
            order.links.forEach((url) => {
                if (!url.startsWith('http://') && !url.startsWith('https://')) {
                    url = 'https://' + url; // Add 'https://' if the URL doesn't already start with it
                }
                window.open(url, '_blank');
                console.log("LINK: ", url);
            });
        }

        if (order.files.length > 0 && eticketReadBlobService) {
            console.log(order.files)
            order.files.forEach((filePath, index) => {
                const fileUrl = eticketReadBlobService.getUrlIfPathExists(filePath)
                console.log("URL: ", fileUrl);
                if (fileUrl == null) return;

                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = ''; // Setting download attribute to initiate a download instead of navigation
                document.body.appendChild(link);
                setTimeout(() => {
                    link.click();
                    // Remove link from the document body
                    document.body.removeChild(link);
                    console.log(filePath);
                }, index * 100); // delay to allow multiple file downloads


            });
        }
    };

    const viewableTickets = order.links.length > 0 || order.files.length > 0;
    const status = order.orderStatus == "Risk Pending" ? "Pending" : order.orderStatus;

    let statusClass = 'success'
    if (status == "Pending" || status == "Waiting For Tickets") {
        statusClass = 'pending'
    } else if (status == "Cancelled" || status == "Refunded") {
        statusClass = 'error'
    }

    return (
        <div className={`${isMobile ? "col event-bar" : "row event-bar"} ${!viewableTickets && "no-click"}`} onClick={(viewableTickets ? handleTicketClick : () => null)}>
            <div className={`${isMobile ? "row" : "left"} `}>
                {!isMobile && <CalendarWidgetSmall dateTime={order.eventDate} />}
                <div className='col align-start'>
                    <div className='item-title'>{order.eventName + " (Order #" + order.id + ")"}</div>
                    <div className='text-xs-light'>{order.eventVenue} - {formatTimeZonedDateToWeekDayAndTime(order.eventDate)}</div>
                    <div className='text-xs-light'>{order.quantity + (order.quantity == 1 ? " Ticket" : " Tickets")}: Section {order.section}, Row {order.row}</div>

                </div>

            </div>

            <div className='gap-s'></div>
            <br />
            {/* {isMobile && <div className='divider-margin'/>} */}

            <div className={`${isMobile ? "row" : "col"} `}>
                {isMobile && <CalendarWidgetSmall dateTime={order.eventDate} />}
                <div className={`${isMobile ? 'col align-start' : 'col align-end'} `}>
                    <div className='item-title'>Total: ${order.price.toFixed(2) + (order.refundAmount > 0 ? (" (Refunded $" + order.refundAmount.toFixed(2)) + ")" : "")}</div>
                    <div className='text-xs-light'>Ordered: {formatDateToSlash(order.purchaseDate)}</div>
                    {viewableTickets ?
                        <div className={`text-xs-${statusClass}`}>{status + " (Click to View Tickets)"}</div>
                        :
                        <div className={`text-xs-${statusClass}`}>{status}</div>
                    }
                </div>
            </div>
        </div>
    )
};

interface ConsignmentBarProps {
    consignment: ConsignmentDto;
    isMobile: boolean;
}

const ConsignmentBar: React.FC<ConsignmentBarProps> = ({ consignment, isMobile }) => {
    console.log(consignment);
    let statusClass = 'success'
    if (consignment.status == 'Submitted') {
        statusClass = 'pending'
    } else if (consignment.status == 'Unsold' || consignment.status == "Returned" || consignment.status == "Canceled") {
        statusClass = 'error'
    }

    return (
        <div className={`${isMobile ? "col event-bar" : "row event-bar"} no-click`}>
            <div className={`${isMobile ? "row" : "left"} `}>
                {!isMobile && <CalendarWidgetSmall dateTime={consignment.eventDate} />}
                <div className='col align-start'>
                    <div className='item-title'>{consignment.eventName + " (Consignment #" + consignment.id + ")"}</div>
                    <div className='text-xs-light'>{consignment.eventVenue} - {formatTimeZonedDateToWeekDayAndTime(consignment.eventDate)}</div>
                    <div className='text-xs-light'>{consignment.quantity + (consignment.quantity == 1 ? " Ticket" : " Tickets")}: Section {consignment.section}, Row {consignment.row}</div>

                </div>

            </div>

            <div className='gap-s'></div>
            <br />
            {/* {isMobile && <div className='divider-margin'/>} */}

            <div className={`${isMobile ? "row" : "col"} `}>
                {isMobile && <CalendarWidgetSmall dateTime={consignment.eventDate} />}
                <div className={`${isMobile ? 'col align-start' : 'col align-end'} `}>
                    <div className='item-title'>Total: {!!!consignment.paymentAmount ? "Pending" : "$" + consignment.paymentAmount.toFixed(2)}</div>
                    <div className='text-xs-light'>Created: {formatDateToSlash(consignment.createdAt)}</div>
                    {consignment.status == 'Completed' ?
                        <div className={`text-xs-${statusClass}`}>{consignment.status + " " + formatDateToWeekDayAndTime(consignment.paymentDate) + " (Check #" + consignment.checkNumber + ")"}</div>
                        :
                        <div className={`text-xs-${statusClass}`}>{consignment.status}</div>
                    }
                </div>
            </div>

        </div>
    )
};

enum AccountInfoState {
    MAIN = "MAIN",
    EDIT = "EDIT",
    PASSWORD = "PASSWORD"
}

const Account: React.FC = () => {
    const { isLoggedIn, isAuthLoading, setLogin } = useAuthenticationContext();
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<PurchaseOrder[]>([]);
    const [consignments, setConsignments] = useState<ConsignmentDto[]>([]);

    const [userInfo, setUserInfo] = useState<UserDto | null>(null);
    const [userInfoEdits, setUserInfoEdits] = useState<UserDto | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [statusOptions, setStatusOptions] = useState<string[]>([]);
    const [userRewards, setUserRewards] = useState(0);

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const [responseMessage, setResponseMessage] = useState<ResponseMessage | null>(null)
    const [showPassword, setShowPassword] = useState(false);

    const [accountInfoState, setAccountInfoState] = useState(AccountInfoState.MAIN);

    const { activeTab = 'account-info' } = useParams<{ activeTab: string }>();
    const navigate = useNavigate();
    const location = useLocation();

    const { isMobile } = useScreenSizeContext();

    const handleTabChange = (newTab: string) => {
        navigate(`/account/${newTab}`);
    }

    useEffect(() => {
        const fetchStatusOptions = async () => {
            const statusOptions = await fetchOrderStatuses();
            setStatusOptions(statusOptions);
        }

        fetchStatusOptions();

    }, []);

    useEffect(() => {
        if (!isLoggedIn && !location.pathname.includes('/login')) {
            navigate(location.pathname + '/login',  { replace: true })
        }
        if (isLoggedIn && location.pathname.includes('/login')) {
            navigate((location.pathname).replace('/login', ''),  { replace: true })
        }

    }, [isLoggedIn])


    useEffect(() => {
        const fetchAll = async () => {
            setLoading(true);

            if (isAuthLoading == false && isLoggedIn == true) {

                const [
                    ordersDataResponseEither,
                    consignmentsDataResponseEither,
                    userDataResponseEither,
                    userRewardsResponseEither,
                ] = await Promise.all([
                    fetchAccountOrders(),
                    fetchAccountConsignments(),
                    fetchUserInfo(),
                    fetchUserRewards()
                ]);


                if (isRight(ordersDataResponseEither)) {
                    const ordersResponse = ordersDataResponseEither.right;
                    const sortedOrders = [...ordersResponse].sort((a, b) => {
                        return new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime();
                    });
                    setOrders(sortedOrders);
                } else {
                    // TODO display error message loginResponse.message
                    const ordersResponse = ordersDataResponseEither.left;
                    console.log(ordersResponse);
                }

                if (isRight(consignmentsDataResponseEither)) {
                    const consignmentsResponse = consignmentsDataResponseEither.right;
                    const sortedConsignments = [...consignmentsResponse].sort((a, b) => {
                        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                    });
                    setConsignments(sortedConsignments);
                } else {
                    // TODO display error message loginResponse.message
                    const consignmentsResponse = consignmentsDataResponseEither.left;
                    console.log(consignmentsResponse);
                }

                if (isRight(userDataResponseEither)) {
                    setUserInfo(userDataResponseEither.right);
                    setUserInfoEdits(userDataResponseEither.right)
                }

                if (isRight(userRewardsResponseEither)) {
                    setUserRewards(userRewardsResponseEither.right);
                }
            }

            setLoading(false);
        };

        fetchAll();
    }, [isAuthLoading, isLoggedIn]);

    const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(e.target.value)
    };

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value)
    };

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (userInfoEdits) {
            const updatedUserInfo = { ...userInfoEdits };
            updatedUserInfo.firstName = e.target.value;
            setUserInfoEdits(updatedUserInfo);
        }
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (userInfoEdits) {
            const updatedUserInfo = { ...userInfoEdits };
            updatedUserInfo.lastName = e.target.value;
            setUserInfoEdits(updatedUserInfo);
        }
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (userInfoEdits) {
            const updatedUserInfo = { ...userInfoEdits };

            const value = e.target.value;
            if (/^\d*$/.test(value)) {
                updatedUserInfo.billPhoneNumber = value
            }
            setUserInfoEdits(updatedUserInfo);
        }
    };

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (userInfoEdits) {
            const updatedUserInfo = { ...userInfoEdits };
            updatedUserInfo.billAddress = e.target.value;
            setUserInfoEdits(updatedUserInfo);
        }
    };

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (userInfoEdits) {
            const updatedUserInfo = { ...userInfoEdits };
            updatedUserInfo.billCity = e.target.value;
            setUserInfoEdits(updatedUserInfo);
        }
    };

    const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (userInfoEdits) {
            const updatedUserInfo = { ...userInfoEdits };
            updatedUserInfo.billZip = e.target.value;
            setUserInfoEdits(updatedUserInfo);
        }
    };

    const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (userInfoEdits) {
            const updatedUserInfo = { ...userInfoEdits };
            updatedUserInfo.billCountry = e.target.value;
            setUserInfoEdits(updatedUserInfo);
        }
    };

    const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (userInfoEdits) {
            const updatedUserInfo = { ...userInfoEdits };
            updatedUserInfo.billCity = e.target.value;
            setUserInfoEdits(updatedUserInfo);
        }
    };

    const handleSaveInfoSubmit = async () => {
        if (userInfoEdits && oldPassword.length > 0) {
            const setInfoResponseEither = await changeUserInfo({ userDto: userInfoEdits, password: oldPassword });
            if (isRight(setInfoResponseEither)) {
                console.log("SUCCESS SAVE INFO: ", setInfoResponseEither.right)
                setResponseMessage({ message: "Successully saved new account information", success: true })
            } else {
                console.log("FAIL SAVE INFO: ", setInfoResponseEither.left)
                setResponseMessage({ message: setInfoResponseEither.left, success: false })
            }
        } else {
            setResponseMessage({ message: "Please enter your old password to save changes", success: false })
        }
    }

    const handleSavePasswordSubmit = async () => {
        if (newPassword.length > 0 && oldPassword.length > 0) {
            const setPasswordResponseEither = await changeUserPassword({ oldPassword: oldPassword, newPassword: newPassword });
            if (isRight(setPasswordResponseEither)) {
                console.log("SUCCESS CHNAGE PASSWORD: ", setPasswordResponseEither.right)
                setResponseMessage({ message: "Successully changed account password", success: true })
            } else {
                console.log("FAIL CHANGE PASSWORD: ", setPasswordResponseEither.left)
                setResponseMessage({ message: setPasswordResponseEither.left, success: false })
            }
        } else {
            setResponseMessage({ message: "Please enter your old password and a new password", success: false })
        }
    }

    const handleLogOut = async () => {
        try {
            const loginResponseEither = await logOut();
            setLogin(null);

        } catch (error) {
            console.error("Failed to log out:", error);
        }
    };



    return (
        <div className='container-info'>

            {isAuthLoading ? <div className="loading-spinner"></div>
                :
                !isLoggedIn ?
                    <div className='content-form'>
                        <div className='card-top'>
                            <LogInFormCard onClickSignUp={() => navigate('/signup')} />
                        </div>
                    </div>
                    :
                    loading ?
                        <div className="loading-spinner"></div>
                        :

                        <div className='content-main'>
                            <div className='card-row card-top'>
                                <div className={`menu-item center-text ${activeTab == 'account-info' && "dropdown-selected"}`} onClick={() => handleTabChange('account-info')}>
                                    <div className='text-s' >Account Info</div>
                                </div>
                                <div className={`menu-item center-text ${activeTab == 'order-history' && "dropdown-selected"}`} onClick={() => handleTabChange('order-history')}>
                                    <div className='text-s' >Order History</div>
                                </div>
                                <div className={`menu-item center-text ${activeTab == 'consignments' && "dropdown-selected"}`} onClick={() => handleTabChange('consignments')}>
                                    <div className='text-s' >Consignments</div>
                                </div>

                            </div>
                            {activeTab === 'account-info' &&
                                <div className='card' >

                                    <div className='card-item'>
                                        <div className='row'>
                                            <div className='left'>
                                                <div className='text-l'>Account Info</div>
                                            </div>
                                            <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />
                                        </div>
                                    </div>
                                    <div className={`${isMobile ? "col align-start" : "row"}`}>
                                        <div className='card-item align-start'>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>Email Address:</div>
                                                <div className='text-xs'>{userInfo?.email ?? ''}</div>
                                                <br />
                                            </div>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>Rewards Available:</div>
                                                <div className='text-xs'>${userRewards.toFixed(2)}</div>
                                                <br />
                                            </div>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>First Name:</div>
                                                {accountInfoState == AccountInfoState.EDIT ?
                                                    <input type="text" className='admin-input' value={userInfoEdits?.firstName} onChange={handleFirstNameChange} placeholder="Type First Name..." />
                                                    :

                                                    <div className='text-xs'>{userInfo?.firstName ?? ''}</div>
                                                }
                                                <br />
                                            </div>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>Last Name:</div>
                                                {accountInfoState == AccountInfoState.EDIT ?
                                                    <input type="text" className='admin-input' value={userInfoEdits?.lastName} onChange={handleLastNameChange} placeholder="Type Last Name..." />

                                                    :
                                                    <div className='text-xs'>{userInfo?.lastName ?? ''}</div>
                                                }
                                                <br />
                                            </div>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>Phone Number:</div>
                                                {accountInfoState == AccountInfoState.EDIT ?
                                                    <input
                                                        type="text"
                                                        value={userInfoEdits?.billPhoneNumber}
                                                        onChange={handlePhoneNumberChange}
                                                        placeholder="Type Phone Number..."
                                                        className='admin-input'
                                                        required
                                                        pattern="\d*"
                                                    />
                                                    :
                                                    <div className='text-xs'>{userInfo?.billPhoneNumber ?? ''}</div>
                                                }
                                                <br />
                                            </div>
                                        </div>
                                        <div className='gap-xl' />
                                        <div className='card-item'>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>Address:</div>
                                                {accountInfoState == AccountInfoState.EDIT ?
                                                    <input type="text" className='admin-input' value={userInfoEdits?.billAddress} onChange={handleAddressChange} placeholder="Type Address..." />
                                                    :
                                                    <div className='text-xs'>{userInfo?.billAddress ?? ''}</div>
                                                }
                                                <br />
                                            </div>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>City:</div>
                                                {accountInfoState == AccountInfoState.EDIT ?
                                                    <input type="text" className='admin-input' value={userInfoEdits?.billCity} onChange={handleCityChange} placeholder="Type City..." />
                                                    :
                                                    <div className='text-xs'>{userInfo?.billCity ?? ''}</div>
                                                }
                                                <br />
                                            </div>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>Zip:</div>
                                                {accountInfoState == AccountInfoState.EDIT ?
                                                    <input type="number" className='admin-input' value={userInfoEdits?.billZip} onChange={handleZipChange} placeholder="Type Zip..." />
                                                    :
                                                    <div className='text-xs'>{userInfo?.billZip ?? ''}</div>
                                                }
                                                <br />
                                            </div>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>State:</div>
                                                {accountInfoState == AccountInfoState.EDIT ?

                                                    <select
                                                        value={userInfoEdits?.billState}
                                                        onChange={handleStateChange}
                                                        className='admin-input'
                                                        required
                                                    >
                                                        <option value="" disabled>Select State/Province</option>
                                                        {userInfoEdits?.billCountry === "US" && (
                                                            <>
                                                                <option value="AL">Alabama</option>
                                                                <option value="AK">Alaska</option>
                                                                <option value="AZ">Arizona</option>
                                                                <option value="AR">Arkansas</option>
                                                                <option value="CA">California</option>
                                                                <option value="CO">Colorado</option>
                                                                <option value="CT">Connecticut</option>
                                                                <option value="DE">Delaware</option>
                                                                <option value="FL">Florida</option>
                                                                <option value="GA">Georgia</option>
                                                                <option value="HI">Hawaii</option>
                                                                <option value="ID">Idaho</option>
                                                                <option value="IL">Illinois</option>
                                                                <option value="IN">Indiana</option>
                                                                <option value="IA">Iowa</option>
                                                                <option value="KS">Kansas</option>
                                                                <option value="KY">Kentucky</option>
                                                                <option value="LA">Louisiana</option>
                                                                <option value="ME">Maine</option>
                                                                <option value="MD">Maryland</option>
                                                                <option value="MA">Massachusetts</option>
                                                                <option value="MI">Michigan</option>
                                                                <option value="MN">Minnesota</option>
                                                                <option value="MS">Mississippi</option>
                                                                <option value="MO">Missouri</option>
                                                                <option value="MT">Montana</option>
                                                                <option value="NE">Nebraska</option>
                                                                <option value="NV">Nevada</option>
                                                                <option value="NH">New Hampshire</option>
                                                                <option value="NJ">New Jersey</option>
                                                                <option value="NM">New Mexico</option>
                                                                <option value="NY">New York</option>
                                                                <option value="NC">North Carolina</option>
                                                                <option value="ND">North Dakota</option>
                                                                <option value="OH">Ohio</option>
                                                                <option value="OK">Oklahoma</option>
                                                                <option value="OR">Oregon</option>
                                                                <option value="PA">Pennsylvania</option>
                                                                <option value="RI">Rhode Island</option>
                                                                <option value="SC">South Carolina</option>
                                                                <option value="SD">South Dakota</option>
                                                                <option value="TN">Tennessee</option>
                                                                <option value="TX">Texas</option>
                                                                <option value="UT">Utah</option>
                                                                <option value="VT">Vermont</option>
                                                                <option value="VA">Virginia</option>
                                                                <option value="WA">Washington</option>
                                                                <option value="WV">West Virginia</option>
                                                                <option value="WI">Wisconsin</option>
                                                                <option value="WY">Wyoming</option>
                                                            </>
                                                        )}
                                                        {userInfoEdits?.billCountry === "CA" && (
                                                            <>
                                                                <option value="AB">Alberta</option>
                                                                <option value="BC">British Columbia</option>
                                                                <option value="MB">Manitoba</option>
                                                                <option value="NB">New Brunswick</option>
                                                                <option value="NL">Newfoundland and Labrador</option>
                                                                <option value="NT">Northwest Territories</option>
                                                                <option value="NS">Nova Scotia</option>
                                                                <option value="NU">Nunavut</option>
                                                                <option value="ON">Ontario</option>
                                                                <option value="PE">Prince Edward Island</option>
                                                                <option value="QC">Quebec</option>
                                                                <option value="SK">Saskatchewan</option>
                                                                <option value="YT">Yukon</option>
                                                            </>
                                                        )}
                                                    </select>
                                                    :
                                                    <div className='text-xs'>{userInfo?.billState ?? ''}</div>
                                                }
                                                <br />
                                            </div>
                                            <div className="col align-start">
                                                <div className='item-title gap-xl'>Country:</div>
                                                {accountInfoState == AccountInfoState.EDIT ?
                                                    <select
                                                        value={userInfoEdits?.billCountry}
                                                        onChange={handleCountryChange}
                                                        className='admin-input'
                                                        required
                                                    >
                                                        <option value="" disabled>Select Country</option>
                                                        <option value="US">United States</option>
                                                        <option value="CA">Canada</option>
                                                    </select>
                                                    :
                                                    <div className='text-xs'>{userInfo?.billCountry ?? ''}</div>
                                                }
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-item'>
                                        {accountInfoState == AccountInfoState.MAIN &&
                                            <div className={`${isMobile ? "col align-start" : "row"}`}>
                                                <button className='filter-button' style={isMobile ? { width: '100%' } : {}} onClick={() => setAccountInfoState(AccountInfoState.EDIT)}>Edit Info</button>
                                                <div className='gap-s' />
                                                <br />
                                                <button className='action-button' style={isMobile ? { width: '100%' } : {}} onClick={() => setAccountInfoState(AccountInfoState.PASSWORD)}>Change Password</button>
                                                <div className='gap-s' />
                                                <br />
                                                <button className='logout-button' style={isMobile ? { width: '100%' } : {}} onClick={handleLogOut}>Log Out</button>
                                            </div>
                                        }
                                        {accountInfoState == AccountInfoState.EDIT &&
                                            <>
                                                <div className='row'>
                                                    <div className='col relative' style={isMobile ? { width: '100%' } : {}} >
                                                        <input type={showPassword ? "text" : "password"} className='input' style={{ width: '100%', margin: '0px 15px', boxSizing: 'border-box', minWidth: '400px' }} value={oldPassword} onChange={handleOldPasswordChange} placeholder="Save with Password..." />
                                                        <div className='absolute hide-password' onClick={() => setShowPassword(!showPassword)}>
                                                            {oldPassword.length > 0 && (showPassword ?
                                                                <FaEyeSlash />
                                                                :
                                                                <FaEye />
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className={`${isMobile ? "col align-start" : "row"}`}>
                                                    <button className='action-button' style={isMobile ? { width: '100%' } : {}} onClick={handleSaveInfoSubmit}>Save Changes</button>
                                                    <div className='gap-s' />
                                                    <br />
                                                    <button className='filter-button' style={isMobile ? { width: '100%' } : {}} onClick={() => setAccountInfoState(AccountInfoState.MAIN)}>Cancel</button>
                                                </div>
                                            </>
                                        }


                                        {accountInfoState == AccountInfoState.PASSWORD &&
                                            <>
                                                <div className='row'>
                                                    <div className='col relative' style={isMobile ? { width: '100%' } : {}} >
                                                        <input type={showPassword ? "text" : "password"} style={{ width: '100%', margin: '0px 15px', boxSizing: 'border-box', minWidth: '400px' }} className='input' value={oldPassword} onChange={handleOldPasswordChange} placeholder="Type Old Password..." />
                                                        <div className='absolute hide-password' onClick={() => setShowPassword(!showPassword)}>
                                                            {oldPassword.length > 0 && (showPassword ?
                                                                <FaEyeSlash />
                                                                :
                                                                <FaEye />
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                
                                                <div className='row'>
                                                    <div className='col relative' style={isMobile ? { width: '100%' } : {}}>
                                                        <input type={showPassword ? "text" : "password"} style={{ width: '100%', margin: '0px 15px', boxSizing: 'border-box', minWidth: '400px' }} className='input' value={newPassword} onChange={handleNewPasswordChange} placeholder="Type New Password..." />
                                                        <div className='absolute hide-password' onClick={() => setShowPassword(!showPassword)}>
                                                            {newPassword.length > 0 && (showPassword ?
                                                                <FaEyeSlash />
                                                                :
                                                                <FaEye />
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />

                                                <div className='row'>
                                                    <div className={`${(newPassword.length > 0 && newPassword.length < 8) ? "text-xs-error" : "text-xs"}`}>&nbsp;Password must contain at least 8 characters</div>
                                                </div>
                                                <br/>
                                                <div className={`${isMobile ? "col align-start" : "row"}`}>
                                                    <button className='action-button' style={isMobile ? { width: '100%' } : {}} onClick={handleSavePasswordSubmit}>Save Password</button>
                                                    <div className='gap-s' />
                                                    <br />
                                                    <button className='filter-button' style={isMobile ? { width: '100%' } : {}} onClick={() => setAccountInfoState(AccountInfoState.MAIN)}>Cancel</button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {responseMessage !== null &&
                                        <div className='card-item'>
                                            <div className='card-list'>
                                                <div className={`text-xs-${responseMessage.success ? "success" : "error"}`}>
                                                    {responseMessage.message}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {activeTab === 'order-history' &&
                                <div className='card'>
                                    <div className='card-item'>
                                        <div className='row'>
                                            <div className='left'>
                                                <div className='text-l'>Order History</div>
                                            </div>
                                            <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />
                                        </div>
                                    </div>
                                    <div className='card-item'>
                                        {orders.length > 0 ?
                                            <>
                                                {orders.map(order => (
                                                    <>
                                                        <OrderBar order={order} isMobile={isMobile} />
                                                        <div className='divider-margin' />
                                                    </>
                                                ))}
                                            </>
                                            :
                                            <div className='col'>
                                                {/* <div className='text-s'>No Orders Yet</div>
                                            <br/> */}
                                                <Link to="/" className='action-button'>
                                                    Browse Events
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            {activeTab === 'consignments' &&
                                <div className='card' style={{ minHeight: 50, width: '100%' }}>
                                    <div className='card-item'>
                                        <div className='row'>
                                            <div className='left'>
                                                <div className='text-l'>Consignments</div>
                                            </div>
                                            <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />
                                        </div>
                                    </div>
                                    <div className='card-item'>
                                        {consignments.length > 0 ?
                                            <>
                                                {consignments.map(consignment => (
                                                    <>
                                                        <ConsignmentBar consignment={consignment} isMobile={isMobile} />
                                                        <div className='divider-margin' />
                                                    </>
                                                ))}
                                            </>
                                            :
                                            <div className='col'>
                                                {/* <div className='text-s'>No Consignments Yet</div>
                                                <br/> */}
                                                <Link to="/consignment" className='action-button'>
                                                    Join Consignment Program
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
            }
        </div>
        // </div>
    )
}

export default Account;