import React, { useState, useEffect, useRef, useCallback } from 'react';
import { deleteCoupon, fetchAllCategories, fetchAllCoupons, fetchCouponCategoryTypes, fetchCouponDiscountTypes, fetchCouponStats, fetchEventsByQuery, fetchPerformersByQuery, fetchVenuesByQuery, makeCoupon, modifyCoupon } from '../../api/endpoints';
import CouponDto from '../../interfaces/CouponDto';
import { DateRangeMenu, SingleSelectMenu } from '../../utils/DropwdownUtils/DropdownUtils';
import Category from '../../interfaces/Category';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import CouponStatsDto from '../../interfaces/CouponStatsDto';
import { DataGrid } from '@mui/x-data-grid';


const AdminCoupons: React.FC = () => {
    const discountTypeRef = useRef<HTMLDivElement>(null);
    const categoryTypeRef = useRef<HTMLDivElement>(null);
    const datePickerRef = useRef<HTMLDivElement>(null);

    const [activeDiscountTypePicker, setActiveDiscountTypePicker] = useState(false);
    const [activeCategoryTypePicker, setActiveCategoryTypePicker] = useState(false);
    const [activeDatePicker, setActiveDatePicker] = useState(false);
    const [discountTypes, setDiscountTypes] = useState<string[]>([]);
    const [categoryTypes, setCategoryTypes] = useState<string[]>([]);
    const [allCategories, setAllCategories] = useState<Category[]>([]);
    const [loadingCoupons, setLoadingCoupons] = useState(true);
    const [coupons, setCoupons] = useState<CouponDto[]>([]);
    const [couponsToDelete, setCouponsToDelete] = useState<CouponDto[]>([]);

    const [selectedCoupon, setSelectedCoupon] = useState(-1);
    const [isFocused, setIsFocused] = useState(false);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState<any[] | null>(null);
    const [loadingResults, setLoadingResults] = useState(false);
    const [loadingStats, setLoadingStats] = useState(false);
    const [couponStats, setCouponStats] = useState<null | CouponStatsDto>(null);
    const lastSearchedRef = useRef('');
    const searchRef = useRef<HTMLDivElement>(null);

    const ordersColumns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'eventName', headerName: 'Event', width: 300 },
        { field: 'billFirstName', headerName: 'First', width: 150 },
        { field: 'billLastName', headerName: 'Last', width: 150 },
        { field: 'discount', headerName: 'Amount Saved', width: 200 }
    ];

    useEffect(() => {
        const fetchCouponInfo = async () => {
            setLoadingCoupons(true);
            const [
                couponDiscountTypes,
                couponCategoryTypes,
                existingCoupons,
                categories
            ] = await Promise.all([
                fetchCouponDiscountTypes(),
                fetchCouponCategoryTypes(),
                fetchAllCoupons(),
                fetchAllCategories() // TODO this endpoint doesn't really change and is bottlenecking this call
            ]);

            setCategoryTypes(couponCategoryTypes);
            setDiscountTypes(couponDiscountTypes);
            setCoupons(existingCoupons);
            setAllCategories(categories);
            setLoadingCoupons(false);
        }

        const handleClickOutsideDiscountTypePicker = (e: MouseEvent) => {
            if (discountTypeRef.current && e.target instanceof Node && !discountTypeRef.current.contains(e.target)) {
                setActiveDiscountTypePicker(false);
            }
        };

        const handleClickOutsideCategoryTypePicker = (e: MouseEvent) => {
            if (categoryTypeRef.current && e.target instanceof Node && !categoryTypeRef.current.contains(e.target)) {
                setActiveCategoryTypePicker(false);
            }
        };

        const handleClickOutsideDatePicker = (e: MouseEvent) => {
            if (datePickerRef.current && e.target instanceof Node && !datePickerRef.current.contains(e.target)) {
                setActiveDatePicker(false);
            }
        };

        const handleClickOutsideSearch = (e: MouseEvent) => {
            if (searchRef.current && e.target instanceof Node && !searchRef.current.contains(e.target)) {
                setIsFocused(false);
            }
        };



        fetchCouponInfo();

        document.addEventListener('mousedown', handleClickOutsideDiscountTypePicker);
        document.addEventListener('mousedown', handleClickOutsideCategoryTypePicker);
        document.addEventListener('mousedown', handleClickOutsideDatePicker);
        document.addEventListener('mousedown', handleClickOutsideSearch);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDiscountTypePicker);
            document.removeEventListener('mousedown', handleClickOutsideCategoryTypePicker);
            document.removeEventListener('mousedown', handleClickOutsideDatePicker);
            document.removeEventListener('mousedown', handleClickOutsideSearch);
        };
    }, []);

    const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };


    const fetchResults = async (query: string) => {
        if (query === '' || !!!coupons[selectedCoupon].categoryType) {
            setResults(null);
            return;
        }
        setLoadingResults(true);
        try {
            lastSearchedRef.current = query;

            // const result = await fetchPerformersByQuery(query);
            // if(lastSearchedRef.current == result.query) {
            // setResults(result)
            // }

            if (coupons[selectedCoupon].categoryType == "Performer") {
                const result = await fetchPerformersByQuery(query, true)
                setResults(result);
            }

            if (coupons[selectedCoupon].categoryType == "Event") {
                const result = await fetchEventsByQuery(query, true)
                setResults(result);
            }

            if (coupons[selectedCoupon].categoryType == "Venue") {
                const result = await fetchVenuesByQuery(query)
                setResults(result);
            }

            if (coupons[selectedCoupon].categoryType == "Category") {
                const result = allCategories.filter(category => category.name.toLowerCase().startsWith(query.toLowerCase()))
                setResults(result);
            }

        } catch (error) {
            console.error('Error fetching performers:', error);
        }
        setLoadingResults(false);
    }


    const debounceFetchResults = useCallback(
        (() => {
            let timeoutId: NodeJS.Timeout;
            return (searchQuery: string) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    fetchResults(searchQuery);
                }, 300);
            };
        })(),
        [coupons, selectedCoupon]
    );


    const handleFocus = () => {
        setIsFocused(true);
    };


    useEffect(() => {
        debounceFetchResults(query);
    }, [query, debounceFetchResults]);





    const handleCouponNameChange = (couponIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].name = e.target.value;
        setCoupons(updatedCoupons);
    };


    const handleCouponDescriptionChange = (couponIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].description = e.target.value;
        setCoupons(updatedCoupons);
    };


    const handleCouponAmountChange = (couponIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].amount = parseFloat(e.target.value);
        setCoupons(updatedCoupons);
    };


    const handleCouponMinPriceChange = (couponIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].minPrice = parseFloat(e.target.value);
        setCoupons(updatedCoupons);
    };


    const handleCouponMaxUsageChange = (couponIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].maxUsage = parseFloat(e.target.value);
        setCoupons(updatedCoupons);
    };


    const handleCouponExclusiveChange = (couponIndex: number, exclusive: boolean) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].exclusive = exclusive;
        setCoupons(updatedCoupons);
    }


    const handleCouponEnabledChange = (couponIndex: number, enabled: boolean) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].enabled = enabled;
        setCoupons(updatedCoupons);
    }


    const setSelectedDiscountType = (couponIndex: number) => (selectedOption: string) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].discountType = selectedOption;
        setCoupons(updatedCoupons);
    };


    const createCouponDiscountTypeChangeHandler = (couponIndex: number): React.Dispatch<React.SetStateAction<string>> => {
        return (newSelectedOption: string | ((prevState: string) => string)) => {
            if (typeof newSelectedOption === 'function') {
                newSelectedOption = newSelectedOption(coupons[couponIndex].discountType);
            }
            setSelectedDiscountType(couponIndex)(newSelectedOption);
        };
    };


    const setSelectedCategoryType = (couponIndex: number) => (selectedOption: string) => {
        if (coupons[couponIndex].categoryType !== selectedOption) {
            const updatedCoupons = [...coupons];
            updatedCoupons[couponIndex].categoryType = selectedOption;
            updatedCoupons[couponIndex].tevoIds = [];
            setCoupons(updatedCoupons);
        }
    };


    const createCouponCategoryTypeChangeHandler = (couponIndex: number): React.Dispatch<React.SetStateAction<string>> => {
        return (newSelectedOption: string | ((prevState: string) => string)) => {
            if (typeof newSelectedOption === 'function') {
                newSelectedOption = newSelectedOption(coupons[couponIndex].categoryType);
            }
            setSelectedCategoryType(couponIndex)(newSelectedOption);
        };
    };

    const handleSelectedTevoId = (couponIndex: number, result: any) => {
        setIsFocused(false);
        setQuery('');

        const updatedCoupons = [...coupons];
        if ('tevoPerformerId' in result) {
            updatedCoupons[couponIndex].tevoIds.push({ id: result.tevoPerformerId, name: result.name });
        } else if ('tevoVenueId' in result) {
            updatedCoupons[couponIndex].tevoIds.push({ id: result.tevoVenueId, name: result.name });
        } else if ('tevoEventId' in result) {
            updatedCoupons[couponIndex].tevoIds.push({ id: result.tevoEventId, name: result.name });
        } else if ('tevoCategoryId' in result) {
            updatedCoupons[couponIndex].tevoIds.push({ id: result.tevoCategoryId, name: result.name });
        }
        setCoupons(updatedCoupons);
    }

    const deleteTevoId = (couponIndex: number, tevoIdIndex: number) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].tevoIds = updatedCoupons[couponIndex].tevoIds.filter((_, index) => index !== tevoIdIndex);
        setCoupons(updatedCoupons);
    };

    const handleCouponStartTimeChange = (couponIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].startTime = new Date(e.target.value).toISOString();
        setCoupons(updatedCoupons);
    };

    const handleCouponEndTimeChange = (couponIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[couponIndex].endTime = new Date(e.target.value).toISOString();
        setCoupons(updatedCoupons);
    };

    const formatLocalDateTime = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };


    const handleCouponSelect = (couponIndex: number) => {
        if (selectedCoupon === couponIndex) {
            setSelectedCoupon(-1)
            setCouponStats(null);
        } else {
            setSelectedCoupon(couponIndex);
            setQuery('')
        }
    }

    const fetchUsageStats = async (couponIndex: number) => {
        const couponId = coupons[couponIndex].id
        if (couponId) {
            setLoadingStats(true);
            const couponStatsData = await fetchCouponStats(couponId);
            if (isRight(couponStatsData)) {
                setCouponStats(couponStatsData.right);
                console.log("SUCCESS FETCH: ", couponStatsData.right)
            } else {
                console.log("FAIL ADD: ", couponStatsData.left)
            }
            setLoadingStats(false);
        }
    }

    const addCoupon = () => {
        const newCoupon: CouponDto = {
            name: '', description: '', categoryType: categoryTypes[0], discountType: discountTypes[0],
            amount: 0, enabled: true, startTime: new Date().toISOString(), endTime: new Date().toISOString(), tevoIds: [], exclusive: false, minPrice: 0
        };
        setSelectedCoupon(coupons.length)
        setCoupons([...coupons, newCoupon]);
    };

    const removeCoupon = (couponIndex: number) => {
        setCouponsToDelete([...couponsToDelete, coupons[couponIndex]])

        const updatedCoupons = coupons.filter((_, index) => index !== couponIndex);
        setCoupons(updatedCoupons);
        setSelectedCoupon(-1)
    };

    const handleSubmitChanges = async () => {
        setLoadingCoupons(true);
        for (let i = 0; i < couponsToDelete.length; i++) {
            if (couponsToDelete[i].id !== undefined) {
                const deleteCouponResopnseEither = await deleteCoupon(couponsToDelete[i].id as number);
                if (isRight(deleteCouponResopnseEither)) {
                    console.log("SUCCESS DELETE: ", deleteCouponResopnseEither.right)
                } else {
                    console.log("FAIL DELETE: ", deleteCouponResopnseEither.left)
                }
            }
        }

        for (let i = 0; i < coupons.length; i++) {
            if (coupons[i].name == '' || coupons[i].amount == 0) {
                continue
            }

            const couponId = coupons[i].id
            if (couponId !== undefined) {
                const setCouponResponseEither = await modifyCoupon(couponId, coupons[i]);
                if (isRight(setCouponResponseEither)) {
                    console.log("SUCCESS MODIFY: ", setCouponResponseEither.right)
                } else {
                    console.log("FAIL MODIFY: ", setCouponResponseEither.left)
                }
            } else {
                const addCouponResponseEither = await makeCoupon(coupons[i]);
                if (isRight(addCouponResponseEither)) {
                    console.log("SUCCESS ADD: ", addCouponResponseEither.right)
                } else {
                    console.log("FAIL ADD: ", addCouponResponseEither.left)
                }
            }

            setLoadingCoupons(false);
        }
    }


    return (
        <div className='card card-top' >
            <div className='row'>
                <div className='left'>
                    <div className='text-l'>Coupons</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            {loadingCoupons ?
                <div className="loading-spinner" />
                :
                <>
                    <div className='card-item'>
                        <button className='admin-button' onClick={addCoupon}>Add Coupon</button>
                    </div>
                    <div className='card-item'>
                        {coupons.map((coupon, couponIndex) => (
                            <div className='card-item'>
                                <div className='card-list gray-bg'>
                                    <div className='row'>
                                        <div className='left'>
                                            <div className='text-xs'>Name:&nbsp;</div>
                                            <input type="text" className='admin-input' value={coupon.name} onChange={handleCouponNameChange(couponIndex)} placeholder="Type Coupon Name..." />
                                            <div className='gap-s' />
                                            <div className='text-xs'>Amount:&nbsp;</div>
                                            <input
                                                type="number"
                                                value={coupon.amount}
                                                onChange={handleCouponAmountChange(couponIndex)}
                                                placeholder="Coupon Amount"
                                                min="0"
                                                step="0.01"
                                                className='admin-input'
                                            />
                                        </div>

                                        <div className='text-xs'>Enabled:&nbsp;</div>
                                        <input type="checkbox" checked={coupon.enabled} onChange={() => handleCouponEnabledChange(couponIndex, !coupon.enabled)} className='admin-checkbox' />
                                        <button className='admin-button' onClick={() => handleCouponSelect(couponIndex)}>{selectedCoupon === couponIndex ? "Collapse" : "Expand"}</button>
                                        <button className='admin-delete-button' onClick={() => removeCoupon(couponIndex)}>Delete</button>

                                    </div>
                                    {selectedCoupon === couponIndex &&
                                        <>
                                            <div className='card-item'>
                                                <div className='row align-start'>
                                                    <div className='admin-column justify-start'>

                                                        <div className='row'>
                                                            <div className='text-xs gap-xl'>Discount Type:&nbsp;</div>
                                                            <div className='relative' ref={discountTypeRef}>
                                                                <button className='admin-button' onClick={(() => setActiveDiscountTypePicker(!activeDiscountTypePicker))}>{coupon.discountType}</button>
                                                                {activeDiscountTypePicker &&
                                                                    <SingleSelectMenu
                                                                        options={discountTypes}
                                                                        selectedOption={coupon.discountType}
                                                                        setSelectedOption={createCouponDiscountTypeChangeHandler(couponIndex)}
                                                                        closeMenu={() => setActiveDiscountTypePicker(false)}
                                                                        mustSelect={true}
                                                                        rightAligned={false}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='text-xs gap-xl'>Min Price:&nbsp;</div>
                                                            <input
                                                                type="number"
                                                                value={coupon.minPrice}
                                                                onChange={handleCouponMinPriceChange(couponIndex)}
                                                                placeholder="Min Price"
                                                                min="0"
                                                                step="0.01"
                                                                className='admin-input'
                                                            />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='text-xs gap-xl'>Max Usages:&nbsp;</div>
                                                            <input
                                                                type="number"
                                                                value={coupon.maxUsage}
                                                                onChange={handleCouponMaxUsageChange(couponIndex)}
                                                                placeholder="Max Usages"
                                                                min="0"
                                                                step="1"
                                                                className='admin-input'
                                                            />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='text-xs gap-xl'>Description:&nbsp;</div>
                                                            <input type="text" className='admin-input' value={coupon.description} onChange={handleCouponDescriptionChange(couponIndex)} placeholder="Type Description..." />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='text-xs gap-xl'>Start Time:&nbsp;</div>
                                                            <input
                                                                type="datetime-local"
                                                                value={formatLocalDateTime(new Date(coupon.startTime))}
                                                                onChange={handleCouponStartTimeChange(couponIndex)}
                                                                className='admin-input'
                                                            />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='text-xs gap-xl'>End Time:&nbsp;</div>
                                                            <input
                                                                type="datetime-local"
                                                                value={formatLocalDateTime(new Date(coupon.endTime))}
                                                                onChange={handleCouponEndTimeChange(couponIndex)}
                                                                className='admin-input'
                                                            />
                                                        </div>
                                                        <div className='row'>
                                                            <div className='text-xs gap-xl'>Usage Stats:&nbsp;</div>
                                                            <button className='admin-action-button' onClick={() => fetchUsageStats(couponIndex)}>Fetch Usage Stats</button>
                                                        </div>
                                                    </div>
                                                    <div className='gap'></div>
                                                    <div className='admin-column justify-start'>
                                                        <div className='row'>
                                                            <div className='left'>
                                                                <div className='text-xs'>Category Type:&nbsp;</div>
                                                                <div className='relative' ref={categoryTypeRef}>

                                                                    <button className='admin-button' onClick={(() => setActiveCategoryTypePicker(!activeCategoryTypePicker))}>{coupon.categoryType}</button>
                                                                    {activeCategoryTypePicker &&
                                                                        <SingleSelectMenu
                                                                            options={categoryTypes}
                                                                            selectedOption={coupon.categoryType}
                                                                            setSelectedOption={createCouponCategoryTypeChangeHandler(couponIndex)}
                                                                            closeMenu={() => setActiveCategoryTypePicker(false)}
                                                                            mustSelect={true}
                                                                            rightAligned={false}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='text-xs'>Exclusive:&nbsp;</div>
                                                            <input type="checkbox" checked={coupon.exclusive} onChange={() => handleCouponExclusiveChange(couponIndex, !coupon.exclusive)} className='admin-checkbox' />
                                                        </div>
                                                        {coupon.categoryType !== "All" &&
                                                            <>
                                                                <div ref={searchRef}>
                                                                    <div className='card-item'>
                                                                        <input
                                                                            type="text"
                                                                            value={query}
                                                                            onFocus={handleFocus}
                                                                            onChange={handleQueryChange}
                                                                            placeholder={`Search for the name of a ${coupon.categoryType.toLowerCase()} to add`} //TODO change based on category
                                                                            className='admin-search'
                                                                        />
                                                                    </div>
                                                                    {(results !== null && isFocused) &&
                                                                        <div className='relative'>
                                                                            <div className='input-search-results'>
                                                                                {results.map(result => (
                                                                                    <div className="menu-item" onClick={() => handleSelectedTevoId(couponIndex, result)}>
                                                                                        <div className='menu-item-title'>{result.name}</div>
                                                                                    </div>))}
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {coupon.tevoIds.map((tevoId, tevoIdIndex) => (
                                                                    <div className='row'>
                                                                        <div className='text-xs'>{tevoId.name + " (" + tevoId.id + ")"}</div>
                                                                        <div className='gap-s' />
                                                                        <button className='admin-delete-small-button' onClick={() => deleteTevoId(couponIndex, tevoIdIndex)}>Delete</button>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            {loadingStats ?
                                                        <div className="loading-spinner"></div>
                                                        :
                                                        (couponStats &&
                                                            <div className='card-item'>
                                                                <div className='row'>
                                                                    <div className='left'>
                                                                    <div className='text-xs gap-xl'>Number of Usages:&nbsp;</div>
                                                                    <div className='text-xs gap-xl'>{couponStats.totalUsages}</div>
                                                                    </div>
                                                                    <div className='text-xs gap-xl'>Total Amount Saved:&nbsp;</div>
                                                                    <div className='text-xs gap-xl'>${couponStats.totalAmountSaved.toFixed(2)}</div>
                                                                </div>
                                                                {/* <div className='row'> */}
                                                                <DataGrid
                                                                    rows={couponStats.ordersWithCoupon}
                                                                    columns={ordersColumns}
                                                                    getRowId={(row) => `${row.id}`}
                                                                    pageSizeOptions={[5, 10, 20]}
                                                                    className='admin-data-grid'
                                                                />
                                                                {/* </div> */}
                                                            </div>
                                                        )
                                                    }
                                            
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div>
    )
}

export default AdminCoupons;