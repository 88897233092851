import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { pass } from 'fp-ts/lib/Writer';
import { tryLogIn } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { useAuthenticationContext } from '../../providers/AuthenticationProvider';
import logo from '../../assets/714-logo-hover.svg';


interface TransactionResponseCardProps {
    success: boolean;
    submittedTransaction: number
    transactionError: string
}

const TransactionResponseCard: React.FC<TransactionResponseCardProps> = ({ success, submittedTransaction, transactionError }) => {


    return (
        <div className='card'>
                <div className='row'>
                    <div className='left'>
                        <div className='text-l'>{success ? "Order Requested!" : "Issue Proccessing Order"}</div>
                    </div>
                </div>
            
            <div className='card-item'>
                <div className='text-s-light'>
                    {success ? "Confirmation details have been sent to your email address." : transactionError + ". Please try again..."}
                </div>
            </div>
            { success &&
            <div className='card-item'>
                <div className='text-s-light'>
                    {"714Tickets is working on your order! Please check your account page for updates"}
                </div>
            </div>
}
            <br/>
            <img src={logo} alt="714 Logo" style={{ width: '140px', height: 'auto', marginBottom: "4px" }} />

        </div>
    )
}

export default TransactionResponseCard;