import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import { Link } from "react-router-dom";
import logoImage from '../../assets/714-logo.svg'
import { FaUser, FaBars } from 'react-icons/fa';
import { LoginMenu, LoggedInMenu } from '../LogIn/LogInMenus';
import { useAuthenticationContext } from '../../providers/AuthenticationProvider';



interface AdminDropdownMenu {
    name: string;
    items: string[];
}

interface NavbarAdminDropdownProps {
    menu: AdminDropdownMenu;
    setActiveTab: (newTab: string) => void;
}

const dropdown: AdminDropdownMenu[] = [
    { name: "Data", items: ["Orders", "Users", "Consignments", "Custom Productions"] },
    { name: "Content", items: ["Dropdown Menus", "Home Suggestions", "Performer Banners", "Venue Banners"] },//, "Email Templates", "Markdown Documents"]},
    { name: "Rules", items: ["General Settings", "Delivery Methods", "Price Adjustments", "Coupons"] }
]

const dropdownItemMap: Map<string, string> = new Map(Object.entries({
    "Orders": "orders",
    "Users": "users",
    "Consignments": "consignments",
    "Custom Productions": "custom-productions",
    "Dropdown Menus": "dropdown-menus",
    "Home Banner": "home-banner",
    "Home Suggestions": "home-suggestions",
    "Performer Banners": "performer-banners",
    "Venue Banners": "venue-banners",
    "General Settings": "general-settings",
    "Delivery Methods": "delivery-methods",
    "Price Adjustments": "price-adjustments",
    "Coupons": "coupons"
}));

const NavbarAdminDropdown: React.FC<NavbarAdminDropdownProps> = ({ menu, setActiveTab }) => {

    return (
        <div className="dropdown-menu">
            {menu.items.map(item => (
                <div className='dropdown-item' onClick={() => setActiveTab(dropdownItemMap.get(item) as string)}>
                    {item}
                </div>
            )
            )}
        </div>
    );
};

interface NavbarAdminProps {
    setActiveTab: (newTab: string) => void;
}

const NavbarAdmin: React.FC<NavbarAdminProps> = ({ setActiveTab }) => {
    const { isLoggedIn } = useAuthenticationContext();
    const loginRef = useRef<HTMLDivElement>(null);

    const [activeDropdown, setActiveDropdown] = useState('');
    const [activeLogin, setActiveLogin] = useState(false);


    useEffect(() => {

        const handleClickOutsideLogin = (e: MouseEvent) => {
            if (loginRef.current && e.target instanceof Node && !loginRef.current.contains(e.target)) {
                setActiveLogin(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideLogin);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideLogin);
        };
    }, []);

    const renderDropdown = () => {
        if (dropdown == null) {
            return null;
        } else {
            return dropdown.map(menu => (
                <div key={menu.name} className='navbar-item' onMouseEnter={() => setActiveDropdown(menu.name)} onMouseLeave={() => setActiveDropdown('')}>
                    {menu.name}
                    {activeDropdown === menu.name && <NavbarAdminDropdown menu={menu} setActiveTab={setActiveTab} />}
                </div>
            ));
        }
    };


    return (
        <div className={`navbar-container navbar-solid`}>
            <div className={`content-main`}>
                <div className='row'>
                    <div className='left'>
                        <Link to="/">
                            <img src={logoImage} alt="logo" className='navbar-logo' />
                        </Link>
                        {renderDropdown()}
                    </div>
                    <div ref={loginRef} className='relative'>
                        <div className='row navbar-item' style={{ padding: "0px 20px" }} onClick={() => setActiveLogin(!activeLogin)}>
                            <FaUser />
                            <div className='gap-s' />

                            {isLoggedIn ? "My Account" : "Log In"}
                        </div>
                        {activeLogin &&
                            (isLoggedIn ?
                                <LoggedInMenu />
                                :
                                <LoginMenu />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavbarAdmin;