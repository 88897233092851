import React, { useState, useEffect } from 'react';
import './Footer.css';
import logoImage from '../../assets/714-logo.svg'
import natb from '../../assets/natb.png'
import facebook from '../../assets/facebook.png'
import instagram from '../../assets/instagram.png'
import x from '../../assets/X.png'
import { Link } from 'react-router-dom';
import { useScreenSizeContext } from '../../providers/ScreenSizeProvider';




const Footer: React.FC = () => {

    const { isMobile } = useScreenSizeContext();


    return (
        <div className='footer-container' style={{ position: 'relative' }}>
            <div className='content-main'>
                <br/>
                <div className={`${isMobile ? "col" : "row align-start"} `}>
                    <div className='footer-top text-align-'>
                        714Tickets is a licensed ticket seller, available 24/7 for your ticketing needs! We buy and sell all concerts, sports, and theatre events and pride ourselves in 34 years of service and counting with no service fees.
                    </div>
                </div>
                <hr className='footer-divider'></hr>
                <div className={`${isMobile ? "col align-start" : "row space-between"} `}>
                    {/* <div className={`${isMobile ? 'col' : 'left'} `}> */}
                    <div className='footer-column'>
                        <div className='footer-title bold'>OFFICE HOURS</div>
                        <div className='footer-text'>
                            {<br />}
                            Mon - Fri: 9am - 6pm {<br />}
                            Saturday: 9am - 5:30pm {<br />}
                            Sunday: 9am - 5pm {<br />}
                            Open 30 minutes past any event at Honda Center or Angel Stadium
                        </div>
                    </div>
                    <div className={`footer-column ${!isMobile && "text-align-center"}`}>
                        <div className='footer-title bold'>CONTACT US</div>
                        <div className='footer-text'>
                            {<br />}
                            Call:  714-TICKETS (842-5387){<br />}
                            <a className='mail-to' href="mailto:orders@714tickets.com">orders@714tickets.com</a><br />
                            {<br />}
                            2424 E Katella Ave.{<br />}
                            Anaheim, CA 92806
                        </div>
                    </div>,
                    {/* </div> */}
                    <div className={`footer-column ${!isMobile && "text-align-right"}`}>
                        <Link to="/about-us" className='footer-link'>ABOUT US</Link>
                        <Link to="/consignment" className='footer-link'>CONSIGNMENT</Link>
                        <Link to="/rewards" className='footer-link'>REWARDS</Link>
                        <Link to="/signup" className='footer-link'>SIGN UP</Link>
                        {/* <br /> */}
                        <div className={`row ${!isMobile && "justify-end"}`}>
                            <a href="https://www.facebook.com/714Tickets/" target="_blank" rel="noopener noreferrer">
                                <img src={facebook} alt="Logo" className='social-logo' />
                            </a>
                            <div className='gap-m' />
                            <a href="https://twitter.com/714Tickets" target="_blank" rel="noopener noreferrer">
                                <img src={x} alt="Logo" className='social-logo' />
                            </a>
                            <div className='gap-m' />
                            <a href="https://www.instagram.com/714tickets/" target="_blank" rel="noopener noreferrer">
                                <img src={instagram} alt="Logo" className='social-logo' />
                            </a>
                        </div>
                        {/* <img src={natb} alt="NATB"  style={{width: '100px', position: 'absolute', bottom: '30px', right: '40px'}}/> */}
                        {/* <img src={natb} alt="NATB"  style={{width: '100px', bottom: '30px', right: ''}}/> */}
                    </div>
                </div>
                <div className={`${isMobile ? "col align-start" : "row"} `} style={{ padding: "0px 20px", marginTop: "40px", boxSizing: "border-box" }}>
                    <div className={`${isMobile ? "col align-start" : "left"} `}>
                        <div className='footer-text-xs'>2024 © 714 Tickets. ALL Rights Reserved.</div>
                        {!isMobile &&
                            <>
                                <div className='gap-s' />
                                <div className='footer-text-xs'>|</div>
                                <div className='gap-s' />
                            </>
                        }

                        <Link to="/terms-and-conditions" className='footer-text-xs-link'>Terms and Conditions</Link>
                        {!isMobile &&
                            <>
                                <div className='gap-s' />
                                <div className='footer-text-xs'>|</div>
                                <div className='gap-s' />
                            </>
                        }
                        <Link to="/privacy-policy" className='footer-text-xs-link'>Privacy Policy</Link>
                    </div>
                    <img src={natb} alt="NATB" style={{ width: '100px', marginBottom: '20px' }} />
                </div>
            </div>

        </div>
    )
}

export default Footer;