import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from "react-router-dom";
import PerformerHeader from '../components/Heros/PerformerHero';
import PerformerEvents from '../components/Events/PerformerEvents';
import Footer from '../components/Footer/Footer';
import { fetchEventsByPerformer, fetchEventsByQuery } from '../api/endpoints';
import Event from '../interfaces/Event';
import Navbar from '../components/Navbar/Navbar';
import Performer from '../interfaces/Performer';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { decodeObject } from '../utils/GlobalUtils';
import VenueEvents from '../components/Events/VenueEvents';
import SearchResultsHeader from '../components/HomeSearch/SearchResultsHeader';


const SearchResultsPage: React.FC = () => {
    const { encodedQuery = '' } = useParams<{ encodedQuery: string }>();
    const query: string = React.useMemo(() => decodeURIComponent(encodedQuery), [encodedQuery]);


    const [events, setEvents] = useState<Event[]>([]);
    const [loadingEvents, setLoadingEvents] = useState<boolean>(true);
    
    useEffect(() => {
        // TODO error handling from endpoint?
        const fetchEvents = async () => {
            if (query !== '') {
                setLoadingEvents(true);
                const eventsData = await fetchEventsByQuery(query, false);
                setEvents(eventsData);
                setLoadingEvents(false);
            }
        };

        fetchEvents(); 
    }, [query]); 

    
    if (query == '') {
        return <Navigate to="/not-found" />
    }

    return (
        <div>
            <Navbar key={query} showSearch={true}/>
            <SearchResultsHeader query={query}/>
            <VenueEvents events={events} loadingEvents={loadingEvents} />
            <Footer/>
        </div>
    )
}

export default SearchResultsPage;