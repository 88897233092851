import axios from "./axios";
import { AxiosError, AxiosResponse } from 'axios';
import Performer from "../interfaces/Performer";
import Event from "../interfaces/Event";
import Listing from "../interfaces/Listing";
import LoginResponse from "../interfaces/LoginResponse";
import ErrorResponse from "../interfaces/ErrorResponse";
import ResponseSuccess from "../interfaces/ResponseSuccess";
import { either, Either, left, right } from 'fp-ts/lib/Either';
import Venue from "../interfaces/Venue";
import Transaction from "../interfaces/Transaction";
import SearchResult from "../interfaces/SearchResult";
import PurchaseOrder from "../interfaces/PurchaseOrder";
import Coupon from "../interfaces/CouponDto";
import DropdownMenu from "../interfaces/DropdownMenu";
import SuggestionCategory from "../interfaces/SuggestionCategory";
import ShortUserInfo from "../interfaces/ShortUserInfo";
import ConsignmentSubmission from "../interfaces/ConsignmentSubmission";
import ConsignmentDto from "../interfaces/ConsignmentDto";
import Page from "../interfaces/Page";
import ExpandedOrder from "../interfaces/ExpandedOrder";
import ConsignmentsComplete from "../interfaces/ConsignmentsComplete";
import UserDto from "../interfaces/UserDto";
import MarkupGeneral from "../interfaces/MarkupGeneral";
import MarkupVenue from "../interfaces/MarkupVenue";
import MarkupEvent from "../interfaces/MarkupEvent";
import ShippingPricing from "../interfaces/ShippingPricing";
import Category from "../interfaces/Category";
import CouponVerificationDto from "../interfaces/CouponVerificationDto";
import CouponDto from "../interfaces/CouponDto";
import ListingSource from "../interfaces/ListingSource";
import CustomEventDto from "../interfaces/CustomEventDto";
import GeneralSettingBoolean from "../interfaces/GeneralSettingBoolean";
import GeneralSettingNumber from "../interfaces/GeneralSettingNumber";
import CouponStatsDto from "../interfaces/CouponStatsDto";



export const fetchSearchResultByQuery = async (query: string): Promise<SearchResult> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { query: encodeURIComponent(query) }
        };
        const response: AxiosResponse<SearchResult> = await axios.get("/api/search/query", config);
        return response.data;
    } catch (err) {
        console.log("Error:", err);
        return { query: '', performers: [], events: [], venues: [], featured: [], performersScore: 0, venuesScore: 0, eventsScore: 0, featuredScore: 0 };
    }
}


export const fetchPerformersByCategory = async (categoryId: string): Promise<Performer[]> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { categoryId: categoryId }
        };
        const response: AxiosResponse<Performer[]> = await axios.get("/api/performers/category", config);
        return response.data;
    } catch (err) {
        console.log("Error:", err);
        return [];
    }
}


export const fetchPerformersByQuery = async (query: string, includeCustom: boolean): Promise<Performer[]> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { query: encodeURIComponent(query), includeCustom: includeCustom }
        };
        const response: AxiosResponse<Performer[]> = await axios.get("/api/search/performers/query", config);
        return response.data;
    } catch (err) {
        console.log("Error:", err);
        return [];
    }
}


export const fetchPerformerById = async (performerId: string): Promise<Either<null, Performer>> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { performerId: encodeURIComponent(performerId) }
        };
        const response: AxiosResponse<Performer> = await axios.get("/api/performers/id", config);
        return right(response.data);
    } catch (err) {
        console.log("Error:", err);
        return left(null); // TODO replace null with backend Left format once created
    }
}


export const fetchEventsByPerformer = async (performerId: string): Promise<Either<null, Event[]>> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { performerId: performerId }
        };
        const response: AxiosResponse<Event[]> = await axios.get("/api/events/performer", config);
        return right(response.data);
    } catch (err) {
        console.log("Error:", err);
        return left(null)
    }
}


export const fetchEventsByVenue = async (venueId: string): Promise<Either<null, Event[]>> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { venueId: venueId }
        };
        const response: AxiosResponse<Event[]> = await axios.get("/api/events/venue", config);
        return right(response.data);
    } catch (err) {
        console.log("Error:", err);
        return left(null)
    }
}


export const fetchEventsByQuery = async (query: string, includeCustom: boolean): Promise<Event[]> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { query: encodeURIComponent(query), includeCustom: includeCustom }
        };
        const response: AxiosResponse<Event[]> = await axios.get("/api/search/events/query", config);
        return response.data;
    } catch (err) {
        console.log("Error:", err);
        return [];
    }
}


export const fetchEventById = async (eventId: number, listingSource: ListingSource): Promise<Either<null, Event>> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { eventId: encodeURIComponent(eventId), listingSource: listingSource }
        };
        const response: AxiosResponse<Event> = await axios.get("/api/events/id", config);
        return right(response.data);
    } catch (err) {
        console.log("Error:", err);
        return left(null); // TODO replace null with backend Left format once created
    }
}


export const fetchListingsByEvent = async (eventId: number, listingSource: ListingSource): Promise<Either<string, Listing[]>> => {
    const config = {
        headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
        params: { eventId: eventId, listingSource: listingSource }
    };
    const responsePromise = axios.get("/api/listings/event", config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        }
        return left("Unknown error");
    })
}


export const fetchListingById = async (ticketGroupId: string, eventId: number, listingSource: ListingSource): Promise<Either<string, Listing>> => {
    const config = {
        headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
        params: { ticketGroupId: encodeURIComponent(ticketGroupId), eventId: eventId, listingSource: listingSource }
    };
    const responsePromise = axios.get("/api/listings/id", config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        }
        return left("Unknown error");
    })
}


export const fetchVenuesByQuery = async (query: string): Promise<Venue[]> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { query: encodeURIComponent(query) }
        };
        const response: AxiosResponse<Venue[]> = await axios.get("/api/search/venues/query", config);
        return response.data;
    } catch (err) {
        console.log("Error:", err);
        return [];
    }
}


export const fetchVenueById = async (venueId: string): Promise<Either<null, Venue>> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
            params: { venueId: encodeURIComponent(venueId) }
        };
        const response: AxiosResponse<Venue> = await axios.get("/api/venues/id", config);
        return right(response.data);
    } catch (err) {
        console.log("Error:", err);
        return left(null); // TODO replace null with backend Left format once created
    }
}


export const fetchAllCategories = async (): Promise<Category[]> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
        };
        const response: AxiosResponse<Category[]> = await axios.get("/api/category/all", config);
        return response.data;
    } catch (err) {
        console.log("Error:", err);
        return [];
    }
}


export const fetchUserInfo = async (): Promise<Either<null, UserDto>> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
        };
        const response = await axios.get("/user/info", config);
        return right(response.data);
    } catch (err) {
        console.log("Error:", err);
        return left(null);
    }
}

export const fetchUserRewards = async (): Promise<Either<null, number>> => {
    try {
        const config = {
            headers: { mode: "no-cors", 'Access-Control-Allow-Origin': "*" } as const,
        };
        const response = await axios.get("/user/rewards", config);
        return right(response.data);
    } catch (err) {
        console.log("Error:", err);
        return left(null);
    }
}

export const changeUserInfo = async (userInfoChangeDto: { userDto: UserDto, password: string }): Promise<Either<string, UserDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/user/info-change", userInfoChangeDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const changeUserPassword = async (passwordChangeDto: { oldPassword: string, newPassword: string }): Promise<Either<string, UserDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/user/password-change", passwordChangeDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const tryLogIn = async (email: string, password: string, rememberMe: boolean): Promise<Either<ErrorResponse, LoginResponse>> => {
    const userData = {
        email: email,
        password: password,
        rememberMe: rememberMe
    };
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "*" } as const,
        params: {}
    };
    const responsePromise = axios.post("/auth/login", userData, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 401) {
            return left(error.response.data);
        } else if (error.response && error.response.status === 403) {
            return left({ message: "Wrong username or password" })
        } else {
            console.error("ERROR: ", error);
            return left({ message: "Unexpected failure, error: " + error })
        }
    });
}


export const logOut = async (): Promise<String> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "*" } as const,
        params: {}
    };
    const responsePromise = axios.post("auth/logout", {}, config)
    return responsePromise.then(unusedResponse => {
        return "Done";
    });
}


export const trySignUp = async (email: string, password: string, firstName: string, lastName: string, heardAbout: string): Promise<Either<ErrorResponse, ResponseSuccess>> => {
    const userData = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        heardAbout: heardAbout
    };
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "*" } as const,
        params: {}
    };
    const responsePromise = axios.post("/auth/signup", userData, config);
    return responsePromise.then(response => {
        return right({ success: true });
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            console.error("ERROR: ", error);
            return left({ message: "Unexpected failure, error: " + error })
        }
    });
}


export const fetchAccessToken = async (): Promise<LoginResponse> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
        params: {}
    };
    const responsePromise = axios.post("/auth/refresh-token", config);
    return responsePromise.then(response => {
        return response.data;
    }).catch(error => {
        if (error.response && error.response.status === 401) {
            console.log('Not logged in:', error.response.data);
        } else {
            console.error('Error:', error.message);
        }
        return { token: null, expiresIn: null }
    })
}


export const triggerPasswordResetEmail = async (userEmail: string): Promise<Either<ErrorResponse, LoginResponse>> => {
    const requestData = { email: userEmail };
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/auth/trigger-reset", requestData, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 401) {
            console.log('Not logged in:', error.response.data);
            return left(error.response.data);
        } else {
            console.error('Error:', error.message);
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}


export const actuallyResetPassword = async (newPassword: string, resetToken: string): Promise<Either<ErrorResponse, LoginResponse>> => {
    const requestData = { newPassword: newPassword, jwtToken: resetToken };
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/auth/actually-reset", requestData, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 401) {
            console.log('Not logged in:', error.response.data);
            return left(error.response.data);
        } else {
            console.error('Error:', error.message);
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}


export const triggerUserVerification = async (verificationToken: string): Promise<Either<ErrorResponse, boolean>> => {
    const requestData = { confirmationToken: verificationToken };
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/auth/verify", requestData, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 401) {
            console.log('Not logged in:', error.response.data);
            return left(error.response.data);
        } else {
            console.error('Error:', error.message);
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}


export const applyCoupon = async (coupon: CouponVerificationDto): Promise<Either<string, string>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/coupon/discount", coupon, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 406) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}


export const processTransaction = async (transaction: Transaction): Promise<Either<string, PurchaseOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/process-transaction", transaction, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            console.log('Not logged in:', error.response.data);
            return left(error.response.data);
        } else {
            console.error('Error:', error.message);
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}


export const fetchAccountOrders = async (): Promise<Either<string, PurchaseOrder[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.get("/user/orders", config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 409) {
            console.log('Not logged in:', error.response.data);
            return left(error.response.data);
        } else {
            console.error('Error:', error.message);
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}


export const fetchUserIP = async (): Promise<string> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('https://cors-anywhere.herokuapp.com/http://api.ipify.org?format=json', config);
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        throw error;
    }
};


export const fetchDropdownMenu = async (): Promise<DropdownMenu[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/dropdown/get', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const fetchAllDropdownMenus = async (): Promise<DropdownMenu[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/dropdown/all', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const setDropdownMenu = async (dropdown: DropdownMenu): Promise<Either<string, DropdownMenu[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/dropdown/set", dropdown, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const deleteDropdownMenu = async (id: number): Promise<Either<string, DropdownMenu[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/dropdown/remove?id=" + id, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};


export const fetchSuggestionMenu = async (): Promise<SuggestionCategory[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/suggestions/get', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};


export const setSuggestionMenu = async (category: SuggestionCategory): Promise<Either<string, SuggestionCategory[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/suggestions/set", category, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};


export const deleteSuggestionMenu = async (id: number): Promise<Either<string, SuggestionCategory[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/suggestions/remove?id=" + id, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};


export const fetchUserPage = async (page: number, size: number, search?: string, sortBy?: string, order?: string): Promise<Page> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(search && { search }),
        ...(sortBy && { sortBy }),
        ...(order && { order })
    });
    try {
        const response = await axios.get(`/admin/user/page?${params.toString()}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching user page:', error);
        throw error;
    }
};

export const fetchOrdersByUser = async (userId: number): Promise<PurchaseOrder[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    try {
        const response = await axios.get("/admin/user-orders?userId=" + userId, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching user page:', error);
        throw error;
    }
};

export const fetchConsignmentsByUser = async (userId: number): Promise<ConsignmentDto[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    try {
        const response = await axios.get("/admin/user-consignments?userId=" + userId, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching user page:', error);
        throw error;
    }
};

export const fetchAdminPage = async (page: number, size: number, search?: string, sortBy?: string, order?: string): Promise<Page> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(search && { search }),
        ...(sortBy && { sortBy }),
        ...(order && { order })
    });
    try {
        const response = await axios.get(`/admin/user-admins/page?${params.toString()}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching user page:', error);
        throw error;
    }
};

export const makeUserAdmin = async (userId: number): Promise<Either<string, UserDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/make-admin?userId=" + userId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const setUserRewards = async (userRewardsRequestDto: {userId: number, newRewards: number}): Promise<Either<string, UserDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/set-rewards", userRewardsRequestDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};


export const setUserBanned = async (banRequestDto: {userId: number, banned: boolean}): Promise<Either<string, UserDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/ban", banRequestDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const removeUserAdmin = async (userId: number): Promise<Either<string, UserDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/remove-admin?userId=" + userId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const changePassword = async (adminPasswordChangeDto: { userId: number, newPassword: string }): Promise<Either<string, UserDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/change-password", adminPasswordChangeDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const fetchOrdersPage = async (page: number, size: number, search?: string, orderStatus?: string, sortBy?: string, order?: string): Promise<Page> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(search && { search }),
        ...(orderStatus && { orderStatus }),
        ...(sortBy && { sortBy }),
        ...(order && { order })
    });
    try {
        const response = await axios.get(`/admin/orders/page?${params.toString()}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching orders page:', error);
        throw error;
    }
};

export const fetchOrderById = async (id: number): Promise<ExpandedOrder> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    try {
        const response = await axios.get(`/admin/orders/get?id=${id}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching order:', error);
        throw error;
    }
};

export const fetchOrderStatuses = async (): Promise<string[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    try {
        const response = await axios.get(`/orders/statuses`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching order statuses:', error);
        throw error;
    }
};

export const markETicketsStatus = async (OrderETicketsDeliveredDto: { id: number, files: string[] }): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/mark/e-tickets", OrderETicketsDeliveredDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const markMobileDelivered = async (OrderMobileDeliveredDto: { id: number, links: string[] }): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/mark/mobile-delivered", OrderMobileDeliveredDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const markComplete = async (OrderCompleteDto: { id: number }): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/mark/complete", OrderCompleteDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const markShipped = async (orderShippedDto: { orderId: number, trackingNumber: string, trackingUrl: string }): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/mark/shipped", orderShippedDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const markCancelled = async (orderId: number ): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/mark/cancelled?orderId=" + orderId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const processOrderPayment = async (orderId: number ): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/process-order?orderId=" + orderId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const releaseFunds = async (orderId: number ): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/release-funds?orderId=" + orderId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};


export const markReadyForPickup = async (OrderReadyForPickupDto: { id: number }): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/mark/ready-for-pickup", OrderReadyForPickupDto, config);
    return responsePromise.then(response => {
        console.log(response.data)
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};


export const issueRefund = async (orderRefundDto: { orderId: number, amount: number, refundDescription: string }): Promise<Either<string, ExpandedOrder>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/issue-refund", orderRefundDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 400) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const submitConsignments = async (consignmentSubmissionDto: ConsignmentSubmission): Promise<Either<string, ConsignmentDto[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/consignments/add", consignmentSubmissionDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            console.log('Issue submitting consignments:', error.response.data);
            return left(error.response.data);
        } else {
            console.error('Error:', error.message);
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}


export const fetchAccountConsignments = async (): Promise<Either<string, ConsignmentDto[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.get("/consignments/user/get", config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.status === 409) {
            console.log('Not logged in:', error.response.data);
            return left(error.response.data);
        } else {
            console.error('Error:', error.message);
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const fetchConsignmentsPage = async (page: number, size: number, search?: string, consignmentStatus?: string, sortBy?: string, order?: string): Promise<Page> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const params = new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(search && { search }),
        ...(consignmentStatus && { consignmentStatus }),
        ...(sortBy && { sortBy }),
        ...(order && { order })
    });
    try {
        const response = await axios.get(`/admin/consignments/page?${params.toString()}`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching orders page:', error);
        throw error;
    }
};

export const fetchConsignmentStatuses = async (): Promise<string[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    try {
        const response = await axios.get(`/admin/consignments/statuses`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching consignments statuses:', error);
        throw error;
    }
};

export const markConsignmentReturned = async (consignmentId: number): Promise<Either<string, ConsignmentDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/consignments/return?consignmentId=" + consignmentId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const markConsignmentUnsold = async (consignmentId: number): Promise<Either<string, ConsignmentDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/consignments/unsold?consignmentId=" + consignmentId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const markConsignmentCompleted = async (consignmentComplete: ConsignmentsComplete): Promise<Either<string, ConsignmentDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/consignments/complete", consignmentComplete, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const fetchEventMarkups = async (): Promise<MarkupEvent[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/all-event-markups', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const fetchVenueMarkups = async (): Promise<MarkupVenue[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/all-venue-markups', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const fetchGeneralMarkups = async (): Promise<MarkupGeneral[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/all-general-markups', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const fetchMarkupTypes = async (): Promise<string[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/markup-types', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const fetchMarkupSources = async (): Promise<string[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/markup-sources', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const addGeneralMarkup = async (markupGeneral: MarkupGeneral): Promise<Either<string, MarkupGeneral>> => {
    console.log("SENDING: ", markupGeneral);
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/add-general-markup", markupGeneral, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const addVenueMarkup = async (markupVenue: MarkupVenue): Promise<Either<string, MarkupVenue>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/add-venue-markup", markupVenue, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const addEventMarkup = async (MarkupEvent: MarkupEvent): Promise<Either<string, MarkupEvent>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/add-event-markup", MarkupEvent, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const removeMarkup = async (markupId: number): Promise<Either<string, boolean>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/remove-markup?id=" + markupId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const fetchGeneralBooleanSettings = async (): Promise<GeneralSettingBoolean[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/general-rules/get-boolean', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const fetchCouponAndRewardsBoolean = async (): Promise<boolean> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/general-rules/coupon-rewards-boolean', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const fetchGeneralNumberSettings = async (): Promise<GeneralSettingNumber[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/general-rules/get-double', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const setGeneralBooleanSetting = async (settingBooleanDto: GeneralSettingBoolean): Promise<Either<string, GeneralSettingBoolean>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post('/admin/general-rules/set-boolean', settingBooleanDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const setGeneralNumberSetting = async (settingDoubleDto: GeneralSettingNumber): Promise<Either<string, GeneralSettingNumber>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post('/admin/general-rules/set-double', settingDoubleDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}



export const getEnabledShipping = async (): Promise<ShippingPricing[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get(`/shipping/all-enabled`, config);
        return response.data;
    } catch (error) {
        console.error('Error getting enabled shipping:', error);
        throw error;
    }
}


export const fetchCouponDiscountTypes = async (): Promise<string[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get(`/admin/coupon/discount-types`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching coupon discount types:', error);
        throw error;
    }
};


export const fetchCouponCategoryTypes = async (): Promise<string[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    try {
        const response = await axios.get(`/admin/coupon/category-types`, config);
        return response.data;
    } catch (error) {
        console.error('Error fetching coupon category types:', error);
        throw error;
    }
};


export const getAllShipping = async (): Promise<ShippingPricing[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get(`/admin/shipping/all`, config);
        return response.data;
    } catch (error) {
        console.error('Error getting all shipping:', error);
        throw error;
    }
}


export const addShippingMethod = async (shippingPricing: ShippingPricing): Promise<Either<string, ShippingPricing>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/shipping/add", shippingPricing, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const fetchAllCoupons = async (): Promise<CouponDto[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/coupon/all', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};


export const removeShippingMethod = async (id: number): Promise<Either<string, boolean>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/shipping/remove?id=" + id, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}


export const makeCoupon = async (coupon: CouponDto): Promise<Either<string, CouponDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/coupon/add", coupon, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};


export const deleteCoupon = async (couponId: number): Promise<Either<string, string>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/coupon/remove?couponId=" + couponId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};


export const modifyCoupon = async (couponId: number, couponDto: CouponDto): Promise<Either<string, CouponDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.post("/admin/coupon/modify?couponId=" + couponId, couponDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const fetchCouponStats = async (couponId: number): Promise<Either<string, CouponStatsDto>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };

    const responsePromise = axios.get("/admin/coupon/stats?couponId=" + couponId, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const fetchAllCustomEvents = async (): Promise<CustomEventDto[]> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    try {
        const response = await axios.get('/admin/custom-events/all', config);
        return response.data;
    } catch (error) {
        console.error('Error fetching dropdown menu:', error);
        throw error;
    }
};

export const makeCustomEvent = async (customEvent: CustomEventDto): Promise<Either<string, CustomEventDto>> => {
    console.log("SENDING CUSOTM EVENT: ", customEvent);
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/custom-events/add", customEvent, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const removeCustomEvent = async (id: number): Promise<Either<string, boolean>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/custom-events/remove?id=" + id, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
}

export const addCustomListing = async (addCustomListingDto: { listing: Listing, eventId: number }): Promise<Either<string, Listing[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/custom-events/add-listing", addCustomListingDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const addListingCSV = async (listingsCsv: File, eventId: number): Promise<Either<string, Listing[]>> => {
    const formData = new FormData();
    formData.append('listingsCsv', listingsCsv);
    formData.append('eventId', eventId.toString());
    const config = {
        headers: {'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/custom-events/add-listing-csv", formData, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const removeCustomListing = async (removeCustomListingDto: { listingId: number, eventId: number }): Promise<Either<string, Listing[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.post("/admin/custom-events/remove-listing", removeCustomListingDto, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};

export const fetchAllCustomListings = async (id: number): Promise<Either<string, Listing[]>> => {
    const config = {
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Credentials': true, 'Access-Control-Allow-Origin': "http://localhost", 'Access-Control-Allow-Headers': true } as const,
    };
    const responsePromise = axios.get("/admin/custom-events/listings?eventId=" + id, config);
    return responsePromise.then(response => {
        return right(response.data);
    }).catch(error => {
        if (error.response && error.response.data) {
            return left(error.response.data);
        } else {
            return left({ message: "Unexpected failure, error: " + error })
        }
    })
};