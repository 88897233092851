import React, { useState, useEffect, useRef } from 'react';
import './EventListings.css';
import { Link } from 'react-router-dom';
import Listing from '../../interfaces/Listing';
import Event from '../../interfaces/Event';
import { CalendarWidgetFull, CalendarWidgetSmall, formatTimeZonedDateToFullDate, formatTimeZonedDateToWeekDayAndTime } from '../../utils/DateUtils/DateUtils'
import { SingleSelectMenu, SliderMenu } from '../../utils/DropwdownUtils/DropdownUtils';
import { quantityMap } from './EventView';
import { encodeObject, getCheckoutItemUrlParams, getEventUrlParams } from '../../utils/GlobalUtils';
import ListingSource from '../../interfaces/ListingSource';
import { useScreenSizeContext } from '../../providers/ScreenSizeProvider';
import { right } from 'fp-ts/lib/EitherT';
import { FaChevronLeft, FaChevronDown, FaTimes } from 'react-icons/fa';



interface EventListingsProps {
    loadingEvent: boolean;
    loadingListings: boolean;
    event: Event | null;
    listings: Listing[];
    priceRange: [number, number];
    quantityOptions: string[];
    selectedListing: Listing | null;
    setSelectedListing: React.Dispatch<React.SetStateAction<Listing | null>>;
    selectedQuantity: string;
    setSelectedQuantity: React.Dispatch<React.SetStateAction<string>>;
    selectedPrices: [number, number];
    setSelectedPrices: React.Dispatch<React.SetStateAction<[number, number]>>;
    parkingFiltered: boolean;
    setParkingFiltered: React.Dispatch<React.SetStateAction<boolean>>;
}

const EventListings: React.FC<EventListingsProps> = ({ loadingEvent, loadingListings, event, listings, priceRange, quantityOptions, selectedListing, setSelectedListing, selectedQuantity, setSelectedQuantity, selectedPrices, setSelectedPrices, parkingFiltered, setParkingFiltered }) => {
    const quantityPickerRef = useRef<HTMLDivElement>(null);
    const pricePickerRef = useRef<HTMLDivElement>(null);


    const [selectedListingQuantity, setSelectedListingQuantity] = useState('');
    const [activeQuantityPicker, setActiveQuantityPicker] = useState(false);
    const [activePricePicker, setActivePricePicker] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const quantityFiltered = selectedQuantity !== '';
    const priceFiltered = selectedPrices[0] !== priceRange[0] || selectedPrices[1] !== priceRange[1];

    const { isMobile, isMapMobile } = useScreenSizeContext();


    const extractQuantityNumber = (quantityString: string) => {
        const regex = /^(\d+) Tickets?$/;
        const match = quantityString.match(regex)
        console.log(match);

        if (match && match[1]) {
            return parseInt(match[1], 10);
        } else {
            throw new Error('Invalid ticket string format');
        }
    }


    useEffect(() => {
        const handleClickOutsideQuantityPicker = (e: MouseEvent) => {
            if (quantityPickerRef.current && e.target instanceof Node && !quantityPickerRef.current.contains(e.target)) {
                setActiveQuantityPicker(false);
            }
        };

        const handleClickOutsidePricePicker = (e: MouseEvent) => {
            if (pricePickerRef.current && e.target instanceof Node && !pricePickerRef.current.contains(e.target)) {
                setActivePricePicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideQuantityPicker);
        document.addEventListener('mousedown', handleClickOutsidePricePicker);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideQuantityPicker);
            document.removeEventListener('mousedown', handleClickOutsidePricePicker);
        };
    }, []);

    const toPriceString = (value: number) => {
        const formattedPrice = new Intl.NumberFormat().format(Math.floor(value));
        const decimalPart = (value % 1).toFixed(2).substring(1);
        return `$${formattedPrice}${decimalPart}`;
    };


    useEffect(() => {
        if (selectedQuantity !== '') {
            const quantity = quantityMap.get(selectedQuantity);
            setSelectedListingQuantity(quantity + (quantity == 1 ? " Ticket" : " Tickets"));
        } else {
            setSelectedListingQuantity('');
        }
        setActiveQuantityPicker(false);
    }, [selectedQuantity]);


    const handleSelectedListing = (listing: Listing) => {
        console.log(selectedListingQuantity)
        if (selectedListingQuantity == '') {
            const minSplit = Math.min(...listing.splits)
            console.log(minSplit)
            setSelectedListingQuantity(minSplit + (minSplit == 1 ? " Ticket" : " Tickets"));
        }
        setSelectedListing(listing)
    }

    const handleResetFilters = () => {
        setSelectedQuantity('');
        setSelectedPrices(priceRange);
        setParkingFiltered(false);
        setShowFilters(false);
    }

    const handleBack = () => {
        setSelectedListing(null)
        setSelectedListingQuantity('');
    }


    return (
        selectedListing !== null ?
            <div className='event-listings-container'>
                {(loadingEvent || event == null) ?
                    <div className="loading-spinner"></div>
                    :
                    <>
                        <div className='pad border-bottom'>
                            <div className='card-item'>
                                <div className='row'>
                                    <div className='left'>
                                        {/* <button className='back-button' onClick={handleBack}>⬅</button> */}
                                        <FaChevronLeft className='back-icon' onClick={handleBack} />
                                        <div className={`${isMobile ? "text-s-bold" : "text-m"}`}>Section {selectedListing.section} • Row {selectedListing.row}</div>
                                    </div>
                                    <div className={`${isMobile ? "text-s-bold" : "text-m"}`}>
                                        {toPriceString(selectedListing.price)}&nbsp;
                                    </div>
                                    <div className={`${isMobile ? "text-xs-light" : "text-s-light"}`}>
                                        ea
                                    </div>
                                </div>
                            </div>
                            <div className='card-item'>
                                <div className='row'>
                                    <div className='left'>
                                        <div className='relative' ref={quantityPickerRef}>
                                            <button className='filter-button' onClick={(() => setActiveQuantityPicker(!activeQuantityPicker))}>{selectedListingQuantity !== '' ? selectedListingQuantity : "Tickets"}
                                                <FaChevronDown className="carrot-icon" style={{
                                                    transform: (activeQuantityPicker) ? 'rotate(180deg)' : 'rotate(0deg)',
                                                    transition: '200ms ease-in-out'
                                                }} />
                                            </button>
                                            {activeQuantityPicker &&
                                                <SingleSelectMenu
                                                    options={selectedListing.splits.map(qty => qty + (qty == 1 ? " Ticket" : " Tickets"))}
                                                    selectedOption={selectedListingQuantity}
                                                    setSelectedOption={setSelectedListingQuantity}
                                                    closeMenu={() => setActiveQuantityPicker(false)}
                                                    mustSelect={true}
                                                />
                                            }
                                        </div>
                                        <div className='gap-s' />
                                        <Link
                                            to={`/checkout/${getCheckoutItemUrlParams({ eventId: event.tevoEventId, listingId: selectedListing.listingId as number, selectedQuantity: extractQuantityNumber(selectedListingQuantity), listingSource: selectedListing.listingSource })}`}
                                            className='action-button'
                                        >
                                            Checkout
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-item'>
                            <div className='row'>
                                <div className='left'>
                                    <div className='listing-info-block'>
                                        <div className='item-title'>{event?.name}</div>
                                        <div className='text-xs-light'>{event ? formatTimeZonedDateToFullDate(event?.occursAtLocal) : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='left'>
                                    <div className='listing-info-block'>
                                        <div className='item-title'>{event?.venue.name}</div>
                                        <div className='text-xs-light'>{event?.venue.location}</div>
                                    </div>
                                </div>
                            </div>
                            <hr className='divider'></hr>
                            <div className='row'>
                                <div className='left'>
                                    <div className='listing-info-block'>
                                        <div className='item-title'>100% Buyer Guarantee</div>
                                        <div className='text-xs-light'>The 714tickets Guarantee means tickets will be 100% authentic and delivered on time, each and every time. Price is the same in store as it is online, all without service fees.</div>
                                    </div>
                                </div>
                            </div>
                            <hr className='divider'></hr>
                            <div className='row'>
                                <div className='left'>
                                    <div className='listing-info-block'>
                                        <div className='item-title'>Notes</div>
                                        <div className='text-xs-light'>{selectedListing.publicNotes}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            : // break between selected listing and listings list
            <div className='event-listings-container'>
                <div className='pad border-bottom sticky-listings-header'>
                    <div className='card-item'>
                        {(loadingEvent || event == null) ?
                            <div className="loading-spinner"></div>
                            :
                            <div className='row'>
                                <div className='left'>
                                    <div className='col align-start'>
                                        {isMobile ?
                                            <>
                                                <div className='text-s-bold'>{event.name}</div>
                                                <div className="text-xs-light" >{event.venue.location + ' - ' + event.venue.name}</div>
                                                <div className="text-xs-light">{formatTimeZonedDateToWeekDayAndTime(event.occursAtLocal)}</div>
                                            </>
                                            :
                                            <>
                                                <div className='text-m'>{event.name}</div>
                                                <div className='text-s-light'>{event.venue.name} | {event.venue.location}</div>
                                            </>
                                        }

                                    </div>
                                    <div className='gap' />

                                </div>
                                {isMobile &&
                                    <div style={{ marginRight: -25 }}>
                                        <CalendarWidgetSmall dateTime={event.occursAtLocal} />
                                    </div>}
                                {!isMobile && <CalendarWidgetFull dateTime={event.occursAtLocal} />}

                            </div>
                        }
                    </div>
                    <div className={`${!isMapMobile && 'card-item'}`}>
                        {(!loadingListings && event != null) && isMapMobile && (
                            <>
                                <div className='row'>
                                    <button className={`filter-button`} onClick={(() => setShowFilters(!showFilters))}>{showFilters ? "Hide Filters" : "Filter Listings"}</button>
                                    <div className='gap-s' />
                                    {event.listingSource != ListingSource.CUSTOM &&
                                        <Link to={`/consignment/${getEventUrlParams(event)}`}
                                            className='action-button'
                                        >
                                            Sell Tickets
                                        </Link>}
                                </div>
                                {showFilters &&
                                    <div className='break-s' />
                                }
                            </>

                        )

                        }
                        {!loadingListings && (!isMapMobile || showFilters) &&

                            <div className={`${isMobile ? "col" : "row"} `}>
                                <div className={`${isMobile ? "row" : 'row'} `}>

                                    <div className='relative' ref={quantityPickerRef} >
                                        <button className={`filter-button ${quantityFiltered && "filtered"}`} onClick={(() => setActiveQuantityPicker(!activeQuantityPicker))}>{quantityFiltered ? selectedQuantity : "Quantity"}

                                            <FaChevronDown className={`${quantityFiltered ? "carrot-icon-selected" : "carrot-icon"}`} style={{
                                                transform: (activeQuantityPicker) ? 'rotate(180deg)' : 'rotate(0deg)',
                                                transition: '200ms ease-in-out'
                                            }} />
                                        </button>

                                        {activeQuantityPicker &&
                                            <SingleSelectMenu
                                                options={quantityOptions}
                                                selectedOption={selectedQuantity}
                                                setSelectedOption={setSelectedQuantity}
                                                closeMenu={() => setActiveQuantityPicker(false)}
                                                mustSelect={false}
                                            />
                                        }
                                    </div>
                                    <div className='gap-s' />
                                    <div className='relative' ref={pricePickerRef} >
                                        <button className={`filter-button ${priceFiltered && "filtered"}`} onClick={(() => setActivePricePicker(!activePricePicker))}>{priceFiltered ? "$" + new Intl.NumberFormat().format(selectedPrices[0]) + " - $" + new Intl.NumberFormat().format(selectedPrices[1]) : "Price"}</button>
                                        {activePricePicker &&
                                            <div style={{marginLeft: isMobile ? '-100px' : '0px'}}>
                                            <SliderMenu
                                                rangeOptions={priceRange}
                                                selectedOptions={selectedPrices}
                                                setSelectedOptions={setSelectedPrices}
                                            />
                                            </div>
                                        }
                                    </div>

                                    <div className='gap-s' />
                                    <button className={`filter-button ${parkingFiltered && "filtered"}`} onClick={() => setParkingFiltered(!parkingFiltered)}>Parking</button>
                                    {((quantityFiltered || priceFiltered || parkingFiltered) && !isMobile) &&
                                        <>
                                            <br />
                                            <div className='gap-s' />
                                            <button className='filter-button' onClick={handleResetFilters} style={isMobile ? { width: '100%', margin: '0px 20px' } : {}}>
                                                <div className='col'>
                                                    <FaTimes />
                                                </div>
                                            </button>
                                        </>
                                    }
                                </div>
                                {((quantityFiltered || priceFiltered || parkingFiltered) && isMobile) &&
                                    <>
                                        <div className='break-s' />
                                        <div className='gap-s' />
                                        <button className='filter-button' onClick={handleResetFilters} style={isMobile ? { width: '100%', margin: '0px 20px' } : {}}>
                                            {/* <div className='col'>
                                                <FaTimes />
                                            </div> */}
                                            Clear Filters
                                        </button>
                                    </>
                                }
                            </div>

                        }
                    </div>
                </div>
                <div className='event-listings-list'>
                    {loadingListings || event == null ? (
                        <div className="loading-spinner"></div>
                    ) : (
                        <>
                            <div className='row pad-v'>
                                <div className='left'>
                                    {/* <div className='text-s-bold'>&nbsp;{listings.length} Listings</div> */}
                                    <div className='text-s-bold'>&nbsp;No Fees, One Price</div>
                                </div>
                                {event.listingSource != ListingSource.CUSTOM && !isMapMobile &&
                                    <Link to={`/consignment/${getEventUrlParams(event)}`}
                                        className='action-button'
                                    >
                                        Sell Tickets
                                    </Link>}
                            </div>
                            {listings.map(listing => {

                                const minSplit = Math.min(...listing.splits);
                                const maxSplit = Math.max(...listing.splits);
                                const numTickets = maxSplit == 1 ? "1 Ticket" : (minSplit !== maxSplit ? minSplit + "-" + maxSplit : minSplit) + " Tickets"

                                return (
                                    <div className='listing-row' key={listing.listingId} onClick={() => handleSelectedListing(listing)}>
                                        <div className='left'>
                                            <div className='col align-start'>
                                                <div className='text-xs-bold'>
                                                    Section {listing.section} • Row {listing.row}&nbsp;
                                                </div>
                                                <div className='text-xs-light'>
                                                    {numTickets} | Mobile Delivery
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-xs-bold'>
                                            {toPriceString(listing.price)}&nbsp;
                                        </div>
                                        <div className='text-xs-light'>
                                            ea
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </>
                    )}
                </div>
            </div>
    )
}

export default EventListings;