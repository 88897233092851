import React, { useState, useEffect, useRef, useCallback } from 'react';
import { category } from 'fp-ts';
import Performer from '../../interfaces/Performer';
import { deleteDropdownMenu, fetchAllDropdownMenus, fetchPerformersByQuery, setDropdownMenu } from '../../api/endpoints';
import DropdownMenu from '../../interfaces/DropdownMenu';
import DropdownSubMenu from '../../interfaces/DropdownSubMenu';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';


const AdminDropdownMenus: React.FC = () => {

    const [menus, setMenus] = useState<DropdownMenu[]>([]);
    const [menusToDelete, setMenusToDelete] = useState<DropdownMenu[]>([]);


    const [selectedMenu, setSelectedMenu] = useState(-1);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [loadingDropdowns, setLoadingDropdowns] = useState(false);


    // MOVE TO MODULAR PERFORMER SEARCH
    const [isFocused, setIsFocused] = useState(false);
    const [performerQuery, setPerformerQuery] = useState('');
    const [loadingPerformers, setLoadingPerformers] = useState(false);
    const [performerResults, setPerformerResults] = useState<Performer[] | null>(null);
    const lastSearchedRef = useRef('');
    const searchRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const fetchMenus = async () => {
            setLoadingDropdowns(true);
            const menusData = await fetchAllDropdownMenus();
            setMenus(menusData);
            setLoadingDropdowns(false);
        };

        const handleClickOutsideSearch = (e: MouseEvent) => {
            if (searchRef.current && e.target instanceof Node && !searchRef.current.contains(e.target)) {
                setIsFocused(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideSearch);
        fetchMenus();

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSearch);
        };
    }, []);


    const handlePerformerQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPerformerQuery(e.target.value);
    };


    const fetchPerformerResults = async (query: string) => {
        if (query === '') {
            setPerformerResults(null);
            return;
        }
        setLoadingPerformers(true);
        try {
            lastSearchedRef.current = query;

            const result = await fetchPerformersByQuery(query, true);
            // if(lastSearchedRef.current == result.query) {
            setPerformerResults(result)
            // }

        } catch (error) {
            console.error('Error fetching performers:', error);
        }
        setLoadingPerformers(false);
    }


    const debounceFetchResults = useCallback(
        (() => {
            let timeoutId: NodeJS.Timeout;
            return (searchQuery: string) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    fetchPerformerResults(searchQuery);
                }, 300);
            };
        })(),
        []
    );


    const handleFocus = () => {
        setIsFocused(true);
    };


    useEffect(() => {
        debounceFetchResults(performerQuery);
    }, [performerQuery, debounceFetchResults]);
    // END SEARCH










    // NOTE: higher order function
    const handleMenuNameChange = (menuIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedMenus = [...menus];
        updatedMenus[menuIndex].name = e.target.value;
        setMenus(updatedMenus);
    };

    const handleMenuDescriptionChange = (menuIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedMenus = [...menus];
        updatedMenus[menuIndex].description = e.target.value;
        setMenus(updatedMenus);
    };

    const handleMenuEnabledChange = (menuIndex: number, enabled: boolean) => {
        const updatedMenus = [...menus];
        updatedMenus[menuIndex].enabled = enabled;
        setMenus(updatedMenus);
    }

    const handleMenuSelect = (menuIndex: number) => {
        if (selectedMenu === menuIndex) {
            setSelectedMenu(-1)
        } else {
            setSelectedMenu(menuIndex);
            setSelectedCategory(-1);
        }
    }

    const addMenu = () => {
        const newMenu: DropdownMenu = { name: '', description: '', categories: [], enabled: true, order: menus.length };
        setMenus([...menus, newMenu]);
    };

    const deleteMenu = (menuIndex: number) => {
        setMenusToDelete([...menusToDelete, menus[menuIndex]])

        const updatedMenus = menus.filter((_, index) => index !== menuIndex);
        setMenus(updatedMenus);
        setSelectedMenu(-1)
    };

    const moveMenuUp = (menuIndex: number) => {
        if (menuIndex === 0) return;
        const updatedMenus = [...menus];
        [updatedMenus[menuIndex - 1], updatedMenus[menuIndex]] = [updatedMenus[menuIndex], updatedMenus[menuIndex - 1]];
        setMenus(updatedMenus);
        setSelectedMenu(-1)
    };

    const moveMenuDown = (menuIndex: number) => {
        if (menuIndex === menus.length - 1) return;
        const updatedMenus = [...menus];
        [updatedMenus[menuIndex + 1], updatedMenus[menuIndex]] = [updatedMenus[menuIndex], updatedMenus[menuIndex + 1]];
        setMenus(updatedMenus);
        setSelectedMenu(-1)
    };



    const handleCategoryNameChange = (menuIndex: number, categoryIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedMenus = [...menus];
        updatedMenus[menuIndex].categories[categoryIndex].name = e.target.value;
        setMenus(updatedMenus);
    };

    const handleCategorySelect = (categoryIndex: number) => {
        if (selectedCategory === categoryIndex) {
            setSelectedCategory(-1)
        } else {
            setSelectedCategory(categoryIndex);
        }
    }

    const addCategory = (menuIndex: number) => {
        const newCategory: DropdownSubMenu = { name: '', performers: [] };
        const updatedMenus = [...menus];
        updatedMenus[menuIndex].categories.push(newCategory);
        setMenus(updatedMenus);
    };

    const deleteCategory = (menuIndex: number, categoryIndex: number) => {
        const updatedMenus = [...menus];
        updatedMenus[menuIndex].categories = updatedMenus[menuIndex].categories.filter((_, index) => index !== categoryIndex);
        setMenus(updatedMenus);
        setSelectedCategory(-1);
    };

    const moveCategoryUp = (menuIndex: number, categoryIndex: number) => {
        if (categoryIndex === 0) return;
        const updatedMenus = [...menus];
        [updatedMenus[menuIndex].categories[categoryIndex - 1], updatedMenus[menuIndex].categories[categoryIndex]] = [updatedMenus[menuIndex].categories[categoryIndex], updatedMenus[menuIndex].categories[categoryIndex - 1]];
        setMenus(updatedMenus);
        setSelectedCategory(-1);
    };

    const moveCategoryDown = (menuIndex: number, categoryIndex: number) => {
        if (categoryIndex === menus[menuIndex].categories.length - 1) return;
        const updatedMenus = [...menus];
        [updatedMenus[menuIndex].categories[categoryIndex + 1], updatedMenus[menuIndex].categories[categoryIndex]] = [updatedMenus[menuIndex].categories[categoryIndex], updatedMenus[menuIndex].categories[categoryIndex + 1]];
        setMenus(updatedMenus);
        setSelectedCategory(-1);
    };






    const handleSelectedPerformer = (menuIndex: number, categoryIndex: number, performer: Performer) => {
        setIsFocused(false);

        const updatedMenus = [...menus];
        updatedMenus[menuIndex].categories[categoryIndex].performers.push(performer);
        setMenus(updatedMenus);
    }


    const deletePerformer = (menuIndex: number, categoryIndex: number, performerIndex: number) => {
        const updatedMenus = [...menus];
        updatedMenus[menuIndex].categories[categoryIndex].performers = updatedMenus[menuIndex].categories[categoryIndex].performers.filter((_, index) => index !== performerIndex);
        setMenus(updatedMenus);
    };

    const movePerformerUp = (menuIndex: number, categoryIndex: number, performerIndex: number) => {
        if (performerIndex === 0) return;
        const updatedMenus = [...menus];
        [updatedMenus[menuIndex].categories[categoryIndex].performers[performerIndex - 1], updatedMenus[menuIndex].categories[categoryIndex].performers[performerIndex]] = [updatedMenus[menuIndex].categories[categoryIndex].performers[performerIndex], updatedMenus[menuIndex].categories[categoryIndex].performers[performerIndex - 1]];
        setMenus(updatedMenus);
    };

    const movePerformerDown = (menuIndex: number, categoryIndex: number, performerIndex: number) => {
        if (performerIndex === menus[menuIndex].categories[categoryIndex].performers.length - 1) return;
        const updatedMenus = [...menus];
        [updatedMenus[menuIndex].categories[categoryIndex].performers[performerIndex + 1], updatedMenus[menuIndex].categories[categoryIndex].performers[performerIndex]] = [updatedMenus[menuIndex].categories[categoryIndex].performers[performerIndex], updatedMenus[menuIndex].categories[categoryIndex].performers[performerIndex + 1]];
        setMenus(updatedMenus);
    };



    const handleSubmitChanges = async () => {
        setLoadingDropdowns(true);
        for (let i = 0; i < menusToDelete.length; i++) {
            if (menusToDelete[i].id !== undefined) {
                const deleteDropdownResponseEither = await deleteDropdownMenu(menusToDelete[i].id as number);
                if (isRight(deleteDropdownResponseEither)) {
                    console.log("SUCCESS DELETE: ", deleteDropdownResponseEither.right)
                } else {
                    console.log("FAIL DELETE: ", deleteDropdownResponseEither.left)
                }
            }
        }


        for (let i = 0; i < menus.length; i++) {
            if (menus[i].name == '') {
                continue;
            }
            menus[i].order = i;
            const setDropdownResponseEither = await setDropdownMenu(menus[i]);
            if (isRight(setDropdownResponseEither)) {
                console.log("SUCCESS ADD: ", setDropdownResponseEither.right)
                if (i == menus.length - 1) {
                    setMenus(setDropdownResponseEither.right) // TODO maybe do this every time AND for deletes?
                }
            } else {
                console.log("FAIL ADD: ", setDropdownResponseEither.left)
            }
        }
        setLoadingDropdowns(false);
    }

    return (
        <div className='card card-top' >
            <div className='row'>
                <div className='left'>
                    <div className='text-l'>Dropdown Menus</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            {loadingDropdowns ?
                <div className="loading-spinner" />
                :
                <>
                    <div className='card-item'>
                        <button className='admin-button' onClick={addMenu}>Add Menu</button>
                    </div>
                    {menus.map((menu, menuIndex) => (
                        <div className='card-item'>
                            <div className='card-list gray-bg'>
                                <div className='row'>
                                    <div className='left'>

                                        <div className='text-xs'>Menu:&nbsp;</div>
                                        <input type="text" className='admin-input' value={menu.name} onChange={handleMenuNameChange(menuIndex)} placeholder="Type Menu Name..." />
                                        <div className='gap-s' />
                                        <div className='text-xs'>Description:&nbsp;</div>
                                        <input type="text" className='admin-input' value={menu.description} onChange={handleMenuDescriptionChange(menuIndex)} placeholder="Type Internal Menu Description..." />
                                        <div className='gap-s' />
                                        <div className='text-xs'>Enabled: </div>
                                        <input type="checkbox" checked={menu.enabled} onChange={() => handleMenuEnabledChange(menuIndex, !menu.enabled)} className='admin-checkbox' />
                                    </div>



                                    <button className='admin-button' onClick={() => moveMenuUp(menuIndex)}>⬆</button>
                                    <button className='admin-button' onClick={() => moveMenuDown(menuIndex)}>⬇</button>
                                    <button className='admin-button' onClick={() => handleMenuSelect(menuIndex)}>{selectedMenu === menuIndex ? "Collapse" : "Expand"}</button>
                                    <button className='admin-delete-button' onClick={() => deleteMenu(menuIndex)}>Delete</button>
                                </div>
                                {selectedMenu === menuIndex &&
                                    <div className='card-item'>
                                        <div className='row align-start'>
                                            {/* <div className='gap'/> */}
                                            <div className='admin-column justify-start'>
                                                {/* <div className='text-s-bold'>"{menu.name + "\" Categories"}</div> */}
                                                <div className='card-item'>
                                                    <button className='admin-button' onClick={() => addCategory(menuIndex)}>Add "{menu.name}" Category</button>
                                                </div>
                                                {menu.categories.map((category, categoryIndex) => (
                                                    <div className='row'>
                                                        {/* <div className='left'> */}
                                                        {/* <div className='text-xs-bold'>Category:&nbsp;</div> */}
                                                        <input className='admin-input' type="text" value={category.name} onChange={handleCategoryNameChange(menuIndex, categoryIndex)} placeholder="Type Category Name..." />
                                                        {/* </div> */}

                                                        <button className='admin-button' onClick={() => moveCategoryUp(menuIndex, categoryIndex)}>⬆</button>
                                                        <button className='admin-button' onClick={() => moveCategoryDown(menuIndex, categoryIndex)}>⬇</button>
                                                        <button className='admin-button' onClick={() => handleCategorySelect(categoryIndex)}>{selectedCategory === categoryIndex ? "Collapse" : "Expand"}</button>
                                                        <button className='admin-delete-button' onClick={() => deleteCategory(menuIndex, categoryIndex)}>Delete</button>

                                                    </div>
                                                ))}
                                            </div>
                                            <div className='gap'></div>
                                            <div className='admin-column justify-start'>
                                                {selectedCategory >= 0 ?
                                                    <>
                                                        {/* <div className='text-s-bold'>"{menu.categories[selectedCategory].name + "\" Performers"}</div> */}
                                                        <div ref={searchRef}>
                                                            <div className='card-item'>
                                                                <input
                                                                    type="text"
                                                                    value={performerQuery}
                                                                    onFocus={handleFocus}
                                                                    onChange={handlePerformerQueryChange}
                                                                    placeholder="Search for the name of the performer or team to add"
                                                                    className='admin-input'
                                                                />
                                                            </div>
                                                            {(performerResults !== null && isFocused) &&
                                                                <div className='relative'>
                                                                    <div className='input-search-results'>
                                                                        {performerResults.map(performer => (
                                                                            <div className="menu-item" onClick={() => handleSelectedPerformer(menuIndex, selectedCategory, performer)}>
                                                                                <div className='menu-item-title'>{performer.name}</div>
                                                                            </div>))}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>

                                                        {menu.categories[selectedCategory].performers.map((performer, performerIndex) => (
                                                            <div className='row'>
                                                                <div className='left'>
                                                                    <div className='text-xs gap-xl'>{performer.name}</div>
                                                                </div>
                                                                <button className='admin-button' onClick={() => movePerformerUp(menuIndex, selectedCategory, performerIndex)}>⬆</button>
                                                                <button className='admin-button' onClick={() => movePerformerDown(menuIndex, selectedCategory, performerIndex)}>⬇</button>
                                                                <button className='admin-delete-button' onClick={() => deletePerformer(menuIndex, selectedCategory, performerIndex)}>Delete</button>
                                                            </div>
                                                        ))

                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {/* <div className='text-s-bold'>Performers:</div> */}
                                                        <div className='card-item'>
                                                            <div className='text-xs'>Expand a category to view its performers</div>
                                                        </div>

                                                    </>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </>
            }

        </div>
    )
}

export default AdminDropdownMenus;