import React, { createContext, useContext, useState, useEffect } from 'react';

interface ScreenSizeContextType {
    isMobile: boolean;
    isMapMobile: boolean
}

const ScreenSizeContext = createContext<ScreenSizeContextType>({
    isMobile: false,
    isMapMobile: false,
});

export const useScreenSizeContext = () => useContext(ScreenSizeContext);

interface ScreenSizeProviderProps {
    children: React.ReactNode;
}

export const ScreenSizeProvider: React.FC<ScreenSizeProviderProps> = ({ children }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isMapMobile, setIsMapMobile] = useState(window.innerWidth <= 1000);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        setIsMapMobile(window.innerWidth <= 1000);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <ScreenSizeContext.Provider value={{ isMobile, isMapMobile}}>
            {children}
        </ScreenSizeContext.Provider>
    );
};

export default ScreenSizeProvider;