import React, { useState, useEffect } from 'react';
import './HomeSearch.css';
import SearchBar from '../SearchBar/SearchBar';

const HomeSearch: React.FC = () => {

    return (
        <div className='search-hero-container'>
            <div className='hero-title'>
                THE PRICE YOU SEE <br />IS THE PRICE YOU PAY
            </div>
            <br/>
            {/* <div className='hero-subtitle' style={{paddingTop: "5px", paddingBottom: "20px"}}>
                34 years of service with no service fees.
            </div> */}
           <SearchBar/>
        </div>
    )
}

export default HomeSearch;