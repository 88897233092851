import React, { useState, useEffect } from 'react';
import Navbar from "../components/Navbar/Navbar"
import HomeSearch from '../components/HomeSearch/HomeSearch';
import HomeSuggestions from '../components/HomeSuggestions/HomeSuggestions'
import Banner from '../components/Banner/Banner';
import Footer from '../components/Footer/Footer';


const HomePage: React.FC = () => {
    // TODO, need to figure out how to make this stop flickering - Banner at top of home page
    const [showHeader, setShowHeader] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition < 10) {
                setShowHeader(true);
            } else {
                setShowHeader(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerText = "714Tickets is a resale marketplace, not a box office or venue. Ticket prices may be below or above face value."

    return (
        <div>
            {/* {showHeader && <div className='header'>{headerText}</div>} */}
            <Navbar/>
            <HomeSearch/>
            <HomeSuggestions/>
            <Banner/>
            <Footer/>
        </div>
    )
}

export default HomePage;