import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchDropdownMenu, fetchSuggestionMenu, getEnabledShipping } from '../api/endpoints';
import DropdownMenu from '../interfaces/DropdownMenu';
import SuggestionCategory from '../interfaces/SuggestionCategory';
import ShippingPricing from '../interfaces/ShippingPricing';

interface GlobalContextType {
  dropdown: DropdownMenu[];
  setDropdown: (dropdown: DropdownMenu[]) => void;

  suggestions: SuggestionCategory[];
  setSuggestions: (suggestions: SuggestionCategory[]) => void;

  shippingMethods: ShippingPricing[];
  setShippingMethods: (shippingMethods: ShippingPricing[]) => void;
}

const GlobalContext = createContext<GlobalContextType>({
  dropdown: [],
  setDropdown: () => {},
  suggestions: [],
  setSuggestions: () => {},
  shippingMethods: [],
  setShippingMethods: () => {}
});

export const useGlobalContext = () => useContext(GlobalContext);

interface GlobalProviderProps {
    children: React.ReactNode;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
    const [dropdown, setDropdown] = useState<DropdownMenu[]>([]);
    const [suggestions, setSuggestions] = useState<SuggestionCategory[]>([]);
    const [shippingMethods, setShippingMethods] = useState<ShippingPricing[]>([]);

    useEffect(() => {
        const fetchInfoAndHandleResponse = async () => {
            const dropdownResponse = await fetchDropdownMenu();
            setDropdown(dropdownResponse);
            const suggestionResponse = await fetchSuggestionMenu();
            setSuggestions(suggestionResponse);
            const shippingMethodResponse = await getEnabledShipping();
            setShippingMethods(shippingMethodResponse);
        }

        fetchInfoAndHandleResponse();
    }, []);

    return (
        <GlobalContext.Provider value={{ dropdown, setDropdown, suggestions, setSuggestions, shippingMethods, setShippingMethods}}>
        {children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;