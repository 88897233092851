import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate } from "react-router-dom";
import PerformerHeader from '../components/Heros/PerformerHero';
import PerformerEvents from '../components/Events/PerformerEvents';
import Footer from '../components/Footer/Footer';
import { fetchEventsByPerformer, fetchPerformerById } from '../api/endpoints';
import Event from '../interfaces/Event';
import Navbar from '../components/Navbar/Navbar';
import Performer from '../interfaces/Performer';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { canBeParsedToInt, decodeObject } from '../utils/GlobalUtils';


const PerformerPage: React.FC = () => {
    const { performerName, performerId = '' } = useParams<{ performerName: string, performerId: string }>();
    const [performer, setPerformer] = useState<Performer | null>(null);
    const [loadingPerformer, setLoadingPerformer] = useState<boolean>(true);

    const [events, setEvents] = useState<Event[]>([]);
    const [loadingEvents, setLoadingEvents] = useState<boolean>(true);

    const navigate = useNavigate();

    useEffect(() => {

        const fetchPerformer = async () => {
            setLoadingPerformer(true);
            const performerDataEither = await fetchPerformerById(performerId);
            if (isRight(performerDataEither)) {
                setPerformer(performerDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingPerformer(false);
        }

        const fetchEvents = async () => { 
            setLoadingEvents(true);
            const eventsDataEither = await fetchEventsByPerformer(performerId);
            if (isRight(eventsDataEither)) {
                setEvents(eventsDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingEvents(false);
        };

        if (canBeParsedToInt(performerId)) {
            fetchPerformer();
            fetchEvents();
            
        } else {
            navigate(`/not-found`);
        }
    }, [])
    
    return (
        <div>
            <Navbar showSearch={true}/>
            <PerformerHeader performer={performer} loadingPerformer={loadingPerformer}/>
            <PerformerEvents performer={performer} loadingPerformer={loadingPerformer} events={events} loadingEvents={loadingEvents} />
            <Footer/>
        </div>
    )
}

export default PerformerPage;