import axios from './axios'
import { useAuthenticationContext } from '../providers/AuthenticationProvider';
import { useContext, useEffect } from 'react'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray';


interface AuthenticationProviderProps {
    children: React.ReactNode;
}

const pathSet = new Set(['1'])

const AxiosAuthenticationInterceptor: React.FC<AuthenticationProviderProps> = ({ children }) => {
    const { accessToken } = useAuthenticationContext();


    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use(
            (config) => {
                console.log("URL: ", config.url)
                console.log("ATTACHING ACCESS TOKEN: ", accessToken)
                if (accessToken) {
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                return config;
            },
            (error) => {
                console.log("ERRO URL: ", error.url)
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.request.eject(requestInterceptor);
        };
    }, [accessToken]);

    return <>{children}</>;
}

export default AxiosAuthenticationInterceptor;