import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { pass } from 'fp-ts/lib/Writer';
import { tryLogIn } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { useAuthenticationContext } from '../../providers/AuthenticationProvider';
import { FaEye, FaEyeSlash } from 'react-icons/fa';



interface LogInFormCardProps {
    onClickSignUp: () => void;
}

const LogInFormCard: React.FC<LogInFormCardProps> = ({onClickSignUp}) => {
    const {isLoggedIn, isAuthLoading, setLogin} = useAuthenticationContext();


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isRememberMe, setIsRememberMe] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState(false);


    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // if (email == '' || password == '') {
        //     setError(true);
        //     return
        // }

        setIsLoading(true);
        const loginResponseEither = await tryLogIn(email, password, isRememberMe);
        if (isRight(loginResponseEither)) {
            const loginResponse = loginResponseEither.right;
            setLogin(loginResponse);
            setError(false);
        } else {
            // TODO display error message loginResponse.message
            const loginResponse = loginResponseEither.left;
            console.log(loginResponse.message);
            setError(true);
        }
        setIsLoading(false);
    }
    

    return (
        <form className='card' onSubmit={handleLogin}>
            <div className='text-l'>Log In</div>
            <div className='card-item'>
                <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    className='input'
                    required
                />
            </div>
            <div className='card-item'>
                <div className='row relative'>
                    <input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Password"
                        className='input'
                        required
                    />
                    <div className='absolute hide-password' onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ?
                            <FaEyeSlash/>
                            :
                            <FaEye/>
                        }
                    </div>
                    
                </div>
                
            </div>
            <div className='card-item'>
                <div className='row'>
                    <div className='left'>
                        <input
                            type="checkbox"
                            checked={isRememberMe}
                            onChange={() => setIsRememberMe(!isRememberMe)}
                            className='checkbox'
                        /> 
                        <div className='text-xs'>Remember Me</div>
                    </div>
                    <Link to="/forgot-password" className='text-xs-link'>
                        Forgot Password?
                    </Link>
                </div>
            </div>
           
            <div className='card-item'>
                <button className='action-button-full' type="submit">{isLoading ? "Loading" : "Log In"}</button>
            </div>
            {error &&
                <>
                    <div className='card-list'>
                        <div className={`text-xs-error`}>
                            Please enter valid log-in information
                        </div>
                    </div>
                    
                </>
            }
            <div className='card-item'>
                <div className='row'>
                    <div className='left'>
                        <div className='text-xs'>Don't have an account?&nbsp;</div>
                        <div className='text-xs-link' onClick={onClickSignUp}>Sign Up</div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default LogInFormCard;