import React, { useState, useEffect } from 'react';

interface AddressFormCardProps {
    mobilePhone: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    zipCode: string;
    state: string;
    saveBillingInfo: boolean;
    setMobilePhone: React.Dispatch<React.SetStateAction<string>>;
    setAddressLine1: React.Dispatch<React.SetStateAction<string>>;
    setAddressLine2: React.Dispatch<React.SetStateAction<string>>;
    setCity: React.Dispatch<React.SetStateAction<string>>;
    setCountry: React.Dispatch<React.SetStateAction<string>>;
    setZipCode: React.Dispatch<React.SetStateAction<string>>;
    setState: React.Dispatch<React.SetStateAction<string>>;
    setSaveBillingInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddressFormCard: React.FC<AddressFormCardProps> = ({ mobilePhone, addressLine1, addressLine2, city, country, zipCode, state, saveBillingInfo, setMobilePhone, setAddressLine1, setAddressLine2, setCity, setCountry, setZipCode, setState, setSaveBillingInfo }) => {

    const handleMobilePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        // Ensure only numbers are input
        if (/^\d*$/.test(value)) {
            setMobilePhone(value);
        }
    };

    const handleAddressLine1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddressLine1(e.target.value);
    };

    const handleAddressLine2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddressLine2(e.target.value);
    };

    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCity(e.target.value);
    };

    // const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setCountry(e.target.value);
    // };



    const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setZipCode(e.target.value);
    };

    const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCountry(e.target.value);
    };

    const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setState(e.target.value);
    };

    return (
        <div className='card'>
            <div className='text-l'>Billing Address</div>
            <div className='card-item'>
                <div className='row'>
                    <input
                        type="text"
                        value={addressLine1}
                        onChange={handleAddressLine1Change}
                        placeholder="Address Line 1"
                        className='input'
                        required
                    />
                    <div className='gap-m' />
                    <input
                        type="text"
                        value={addressLine2}
                        onChange={handleAddressLine2Change}
                        placeholder="Address Line 2"
                        className='input'
                    />
                </div>
            </div>
            <div className='card-item'>
                <div className='row'>
                    <input
                        type="text"
                        value={city}
                        onChange={handleCityChange}
                        placeholder="City"
                        className='input'
                        required
                    />

                    <div className='gap-m' />

                    <input
                        type="number"
                        value={zipCode}
                        onChange={handleZipCodeChange}
                        placeholder="Zip Code"
                        className='input'
                        required
                    />
                </div>
            </div>
            <div className='card-item'>
                <div className='row'>
                    <select
                        value={country}
                        onChange={handleCountryChange}
                        className='input'
                        required
                    >
                        <option value="" disabled>Select Country</option>
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                    </select>
                    <div className='gap-m' />
                    <select
                        value={state}
                        onChange={handleStateChange}
                        className='input'
                        required
                    >
                        <option value="" disabled>Select State/Province</option>
                        {country === "US" && (
                            <>
                                <option value="AL">Alabama</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>
                                <option value="HI">Hawaii</option>
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>
                            </>
                        )}
                        {country === "CA" && (
                            <>
                                <option value="AB">Alberta</option>
                                <option value="BC">British Columbia</option>
                                <option value="MB">Manitoba</option>
                                <option value="NB">New Brunswick</option>
                                <option value="NL">Newfoundland and Labrador</option>
                                <option value="NT">Northwest Territories</option>
                                <option value="NS">Nova Scotia</option>
                                <option value="NU">Nunavut</option>
                                <option value="ON">Ontario</option>
                                <option value="PE">Prince Edward Island</option>
                                <option value="QC">Quebec</option>
                                <option value="SK">Saskatchewan</option>
                                <option value="YT">Yukon</option>
                            </>
                        )}
                    </select>

                </div>
            </div>
            <div className='card-item'>
                <div className='row'>
                    <input
                        type="text"
                        value={mobilePhone}
                        onChange={handleMobilePhoneChange}
                        placeholder="Mobile Phone"
                        className='input'
                        required
                        pattern="\d*" 
                    />
                </div>
            </div>
            <div className='card-item'>
                <div className='row'>
                    <input
                        type="checkbox"
                        checked={saveBillingInfo}
                        onChange={() => setSaveBillingInfo(!saveBillingInfo)}
                        className='checkbox'
                    />
                    <div className='text-xs'>Save info to my account for future orders and consignments</div>
                </div>
            </div>
        </div>
    )
}

export default AddressFormCard;