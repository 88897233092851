import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import ForgotPassword from '../components/ForgotPassword/ForgotPassword'
import Footer from '../components/Footer/Footer';



const ResetPasswordPage: React.FC = () => {

    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true}/>
            <ForgotPassword/>
            <Footer/>
        </div>
    )
}

export default ResetPasswordPage;