import React, { useState, useEffect, useRef, useCallback } from 'react';
import SuggestionCategory from '../../interfaces/SuggestionCategory';
import Performer from '../../interfaces/Performer';
import { deleteSuggestionMenu, fetchPerformersByQuery, fetchSuggestionMenu, setSuggestionMenu } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { useWriteBlobContext } from '../../providers/WriteBlobProvider';
import { useReadBlobContext } from '../../providers/ReadBlobProvider';
import Modal from 'react-modal';



const AdminHomeBanner: React.FC = () => {
    const { imageWriteBlobService } = useWriteBlobContext();
    const { imageReadBlobService } = useReadBlobContext();


    const [openDialog, setOpenDialog] = useState<boolean>(false);


    const [loadedImage, setLoadedImage] = useState<String | null>(null);
    const [imageToUpload, setImageToUpload] = useState<File | null>(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        const imagePath = imageReadBlobService?.getUrlIfPathExists("home/img.jpg")
        setLoadedImage(imagePath as String);
    }, []);





    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            setImageToUpload(files[0]);
        }

    };


    const handleSubmitChanges = async () => {
        // TODO handle deletes
        // if (imageWriteBlobService) {
        //     for (const [performerSlug, imageFile] of imagesToUpload) {
        //         imageWriteBlobService.uploadFile("suggestions/" + performerSlug + "/img.jpg", imageFile)
        //     }
        // }

        // for (let i = 0; i < categoriesToDelete.length; i++) {
        //     if (categoriesToDelete[i].id !== undefined) {
        //         const deleteCategoryResponseEither = await deleteSuggestionMenu(categoriesToDelete[i].id as number);
        //         if (isRight(deleteCategoryResponseEither)) {
        //             console.log("SUCCESS DELETE: ", deleteCategoryResponseEither.right)
        //         } else {
        //             console.log("FAIL DELETE: ", deleteCategoryResponseEither.left)
        //         }
        //     }
        // }


        // for (let i = 0; i < categories.length; i++) {
        //     if (categories[i].name == '' || categories[i].performers.length == 0) {
        //         continue;
        //     }
        //     categories[i].order = i;
        //     const setCategoryResponseEither = await setSuggestionMenu(categories[i]);
        //     if (isRight(setCategoryResponseEither)) {
        //         console.log("SUCCESS ADD: ", setCategoryResponseEither.right)
        //         if (i == categories.length - 1) {
        //             setCategories(setCategoryResponseEither.right) // TODO maybe do this every time AND for deletes?
        //         }
        //     } else {
        //         console.log("FAIL ADD: ", setCategoryResponseEither.left)
        //     }
        // }
    }

    return (
        <div className='card card-top' >
            <div className='row'>
                <div className='left'>
                    <div className='text-l'>Home Banner</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            <div className='row'>
                <input type="file" className='no-display' id={`file-input`} onChange={handleFileChange} />
                <label htmlFor={`file-input`} className='admin-button'>Upload Image</label>
                {(imageToUpload || loadedImage) &&
                    <>
                        {imageToUpload ?
                            <button className='admin-button' onClick={handleOpenDialog}>View New Image</button>
                            :
                            <button className='admin-button' onClick={handleOpenDialog}>View Existing Image</button>
                        }
                    </>
                }
            </div>
            <Modal isOpen={openDialog} onRequestClose={handleCloseDialog} style={{
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'auto',
                    maxWidth: '1000px',
                    margin: 'auto',
                    top: '125px',
                    bottom: '125px',
                    padding: '40px',
                    borderRadius: '20px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                }
            }}
                appElement={document.getElementById('root') || undefined}
            >
                {imageToUpload ?
                    <img
                        src={URL.createObjectURL(imageToUpload as File)}
                        alt="Uploaded"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                    :
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${loadedImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}
                    />
                }
            </Modal>
        </div>
    )
}

export default AdminHomeBanner;