import React, { useState, useEffect } from 'react';
import ShippingPricing from '../../interfaces/ShippingPricing';
import { addShippingMethod, getAllShipping, getEnabledShipping, removeShippingMethod } from '../../api/endpoints';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';


const AdminDeliveryMethods: React.FC = () => {

    const [loadingDeliveryMethods, setLoadingDeliveryMethods] = useState(false);
    const [shippingMethods, setShippingMethods] = useState<ShippingPricing[]>([]);
    const [methodsToDelete, setMethodsToDelete] = useState<ShippingPricing[]>([]);


    const fetchDeliveryMethods = async () => {
        setLoadingDeliveryMethods(true);
        const shippingData = await getAllShipping();
        console.log(shippingData)
        setShippingMethods(shippingData);
        setLoadingDeliveryMethods(false);
    };


    useEffect(() => {

        fetchDeliveryMethods();
    }, []);

    const addMethod = () => {
        const newMethod: ShippingPricing = { name: '', cost: 0, applyPerTicket: false, enabled: true, order: shippingMethods.length };
        setShippingMethods([...shippingMethods, newMethod]);
    };

    const handleMethodNameChange = (methodIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedMethods = [...shippingMethods];
        updatedMethods[methodIndex].name = e.target.value;
        setShippingMethods(updatedMethods);
    };

    const handleMethodCostChange = (methodIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedMethods = [...shippingMethods];
        updatedMethods[methodIndex].cost = parseFloat(e.target.value);
        setShippingMethods(updatedMethods);
    };

    const deleteMethod = (methodIndex: number) => {
        setMethodsToDelete([...methodsToDelete, shippingMethods[methodIndex]])

        const updatedMethods = shippingMethods.filter((_, index) => index !== methodIndex);
        setShippingMethods(updatedMethods);
    };

    const handleMethodEnabledChange = (methodIndex: number, enabled: boolean) => {
        const updatedMethods = [...shippingMethods];
        updatedMethods[methodIndex].enabled = enabled;
        setShippingMethods(updatedMethods);
    }

    const handleMethodApplyChange = (methodIndex: number, applyPerTicket: boolean) => {
        const updatedMethods = [...shippingMethods];
        updatedMethods[methodIndex].applyPerTicket = applyPerTicket;
        setShippingMethods(updatedMethods);
    }

    const moveMethodUp = (methodIndex: number) => {
        if (methodIndex === 0) return;
        const updatedMethods = [...shippingMethods];
        [updatedMethods[methodIndex - 1], updatedMethods[methodIndex]] = [updatedMethods[methodIndex], updatedMethods[methodIndex - 1]];
        setShippingMethods(updatedMethods);
    };

    const moveMethodDown = (methodIndex: number) => {
        if (methodIndex === shippingMethods.length - 1) return;
        const updatedMethods = [...shippingMethods];
        [updatedMethods[methodIndex + 1], updatedMethods[methodIndex]] = [updatedMethods[methodIndex], updatedMethods[methodIndex + 1]];
        setShippingMethods(updatedMethods);
    };

    const handleSubmitChanges = async () => {
        setLoadingDeliveryMethods(true);
        for (let i = 0; i < methodsToDelete.length; i++) {
            if (methodsToDelete[i].id !== undefined) {
                const deleteMethodResponseEither = await removeShippingMethod(methodsToDelete[i].id as number);
                if (isRight(deleteMethodResponseEither)) {
                    console.log("SUCCESS DELETE: ", deleteMethodResponseEither.right)
                } else {
                    console.log("FAIL DELETE: ", deleteMethodResponseEither.left)
                }
            }
        }

        for (let i = 0; i < shippingMethods.length; i++) {
            if (shippingMethods[i].name == '' || shippingMethods[i].cost == 0) {
                continue;
            }
            shippingMethods[i].order = i;
            const setMethodResponseEither = await addShippingMethod(shippingMethods[i]);
            if (isRight(setMethodResponseEither)) {
                console.log("SUCCESS ADD: ", setMethodResponseEither.right)
                // if (i == shippingMethods.length - 1) {
                //     setShippingMethods(setMethodResponseEither.right) // TODO maybe do this every time AND for deletes?
                // }
            } else {
                console.log("FAIL ADD: ", setMethodResponseEither.left)
            }
        }

        fetchDeliveryMethods();
    }

    return (
        <div className='card card-top' >
            <div className='row'>
                <div className='left'>
                    <div className='text-l'>Delivery Methods</div>
                </div>
                <button className='admin-action-button' onClick={handleSubmitChanges}>Save Changes</button>
            </div>
            {loadingDeliveryMethods ?
                <div className="loading-spinner" />
                :
                <>
                    <div className='card-item'>
                        <button className='admin-button' onClick={addMethod}>Add Delivery Method</button>
                    </div>
                    <div className='card-item'>
                        {shippingMethods.map((method, methodIndex) => (
                            <div className='card-item'>
                                <div className='card-list gray-bg'>
                                    <div className='row'>
                                        <div className='left'>
                                            <div className='text-xs'>Name:&nbsp;</div>
                                            <input type="text" className='admin-input' value={method.name} onChange={handleMethodNameChange(methodIndex)} placeholder="Type Delivery Method Name..." />
                                            <div className='gap-s' />
                                            <div className='text-xs'>Price:&nbsp;</div>
                                            <input type="number" className='admin-input' min="0" step="0.01" value={method.cost} onChange={handleMethodCostChange(methodIndex)} placeholder="Type Cost..." />

                                            <div className='gap-s' />
                                            <div className='text-xs'>Enabled: </div>
                                            <input type="checkbox" checked={method.enabled} onChange={() => handleMethodEnabledChange(methodIndex, !method.enabled)} className='admin-checkbox' />
                                            <div className='gap-s' />
                                            <div className='text-xs'>Apply Per Ticket: </div>
                                            <input type="checkbox" checked={method.applyPerTicket} onChange={() => handleMethodApplyChange(methodIndex, !method.applyPerTicket)} className='admin-checkbox' />


                                        </div>
                                        <button className='admin-button' onClick={() => moveMethodUp(methodIndex)}>⬆</button>
                                        <button className='admin-button' onClick={() => moveMethodDown(methodIndex)}>⬇</button>
                                        <button className='admin-delete-button' onClick={() => deleteMethod(methodIndex)}>Delete</button>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div>
    )
}

export default AdminDeliveryMethods;