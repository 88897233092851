import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Navbar from '../components/Navbar/Navbar';
import ConsignmentFormCard from '../components/Consignment/ConsignmentFormCard';
import Footer from '../components/Footer/Footer';
import { canBeParsedToInt, decodeObject } from '../utils/GlobalUtils';
import Event from '../interfaces/Event';
import { useAuthenticationContext } from '../providers/AuthenticationProvider';
import LogInFormCard from '../components/LogIn/LogInFormCard';
import UserDto from '../interfaces/UserDto';
import MarkdownCard from '../components/MarkdownCard/MarkdownCard';
import { Either, left, right, isLeft, isRight } from 'fp-ts/lib/Either';
import { fetchEventById } from '../api/endpoints';
import ListingSource from '../interfaces/ListingSource';


const ConsignmentPage: React.FC = () => {
    const { eventName, isCustom = '', eventId = '' } = useParams<{ eventName: string, isCustom: string, eventId: string }>();
    const [event, setEvent] = useState<Event | null>(null)
    const [loadingEvent, setLoadingEvent] = useState<boolean>(false);

    const { isLoggedIn, isAuthLoading} = useAuthenticationContext();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        const fetchEvent = async () => {
            setLoadingEvent(true);
            const eventDataEither = await fetchEventById(parseInt(eventId), ListingSource.TEVO);
            if (isRight(eventDataEither)) {
                setEvent(eventDataEither.right);
            } else {
                navigate(`/not-found`);
            }
            setLoadingEvent(false);
        }

        if (canBeParsedToInt(eventId)) {
            fetchEvent();
        } else if (eventId !== '') {
            navigate(`/not-found`);
        }
    }, []);

    useEffect(() => {
        if (!isLoggedIn && !location.pathname.includes('/login')) {
            navigate(location.pathname + '/login',  { replace: true })
        }
        if (isLoggedIn && location.pathname.includes('/login')) {
            navigate((location.pathname).replace('/login', ''),  { replace: true })
        }

    }, [isLoggedIn])

    return (
        <div>
            <Navbar showSearch={true} alwaysSolid={true} />
            <div className='container-info'>
                <div className='content-form'>
                    {isAuthLoading || loadingEvent? <div className="loading-spinner"></div>
                        :
                        !isLoggedIn ?
                            <div className='card-top'>
                                <LogInFormCard onClickSignUp={() => navigate('/signup')} />
                                <MarkdownCard filePath="/markdown/consignment.md" />
                            </div>
                            :

                            <>
                                <ConsignmentFormCard event={event}/>
                                <MarkdownCard filePath="/markdown/consignment.md" />
                            </>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ConsignmentPage;